import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import DashboardIcon from '@mui/icons-material/Dashboard';
import logo from '../images/logo.png';
import clientLogo from '../Pages/bg/client.png'
//import logo1 from '../images/medysoft_175x35_1.png';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, Chip, Stack } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {jwtDecode} from 'jwt-decode';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import PolicyIcon from '@mui/icons-material/Policy';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import BadgeIcon from '@mui/icons-material/Badge';
import SchemaIcon from '@mui/icons-material/Schema';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import CategoryIcon from '@mui/icons-material/Category';
import GavelIcon from '@mui/icons-material/Gavel';
import LanIcon from '@mui/icons-material/Lan';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  display: 'flex',
  justifyContent: 'space-between', // This will align children items on the extremes
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SideBar() {
  const [menuItems, setMenuItems] = useState([]);
  const [userName, setUserName] = useState();
  const [bName, setBName] = useState();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [branchOpen, setBranchOpen] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); // State for the selected item
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setSelectedItem(null);
    setSelected(true);
    navigate("/home");
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleBranchClick = (index) => {
    const updatedBranchOpen = [...branchOpen];
    updatedBranchOpen[index] = !updatedBranchOpen[index];
    setBranchOpen(updatedBranchOpen);
  };

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleItemClick = (path) => {
    setSelected(false);
    setSelectedItem(path);
    navigate(path);
  };
  

  /* on page load */
  useEffect(() => {
    const token = localStorage.getItem('token');

    if(token){
      const info = jwtDecode(token);
      setBName(info.branchName);
      setUserName(info.empName +" "+ `(${info.roleName})`);
      getMenuData(info.accessList);
  
      // Initialize branchOpen state with false values for each activity item
      const initialBranchState = Array(info.accessList.length).fill(false);
      setBranchOpen(initialBranchState);
    }
  }, []);


  const getMenuData = (accessList) => {
    const formattedMenuItems = [];
    for (const [activityType, menus] of Object.entries(accessList)) {
      const activity = {
        text: activityType,
        icon: getIconForActivity(activityType),
        children: menus.map(menu => ({
          text: menu.menuName,
          icon: getIconForMenu(menu.menuName),
          path: menu.route
        }))
      };
      formattedMenuItems.push(activity);
    }
    setMenuItems(formattedMenuItems);
  }

  const getIconForMenu = (menuName) => {
    try {
      switch (menuName) {
        case 'Client Managment':
          return <GroupAddIcon sx={{ color: 'black' }} />;
        case 'File Managment':
          return <FileOpenIcon sx={{ color: 'black' }} />;
        case 'Task Managment':
          return <AddTaskIcon sx={{ color: 'black' }} />;
        case 'Work Managment':
          return <AddHomeWorkIcon sx={{ color: 'black' }} />;
        case 'Role':
          return <AccessibilityIcon sx={{ color: 'black' }} />;
        case 'Role Menu Map':
          return <AcUnitIcon sx={{ color: 'black' }} />;
        case 'Section':
          return <SchemaIcon sx={{ color: 'black' }} />;
        case 'Employee':
          return <BadgeIcon sx={{ color: 'black' }} />;
        case 'User Profile':
          return <ManageAccountsIcon sx={{ color: 'black' }} />;
        case 'DWR':
          return <SummarizeIcon sx={{ color: 'black' }} />;
        case 'Rack':
          return <LocationSearchingIcon sx={{ color: 'black' }} />;
        case 'Fee Category':
          return <CategoryIcon sx={{ color: 'black' }} />;
        case 'Court':
          return <GavelIcon sx={{ color: 'black' }} />;
        case 'Department':
          return <LanIcon sx={{ color: 'black' }} />;
        default:
          return null;
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getIconForActivity = (activityType) => {
    switch (activityType) {
      case 'Legal Activity':
        return <PolicyIcon sx={{ color: 'black' }} />;
      case 'HR Activity':
        return <ManageAccountsIcon sx={{ color: 'black' }} />;
      case 'Accounts Activity':
        return <ManageAccountsIcon sx={{ color: 'black' }} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: 'black' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <img src={clientLogo} alt="RSA" style={{ width: '6vw', height: 'auto'}} />
            <Chip sx={{ backgroundColor: 'white', color: 'black' }} avatar={<Avatar sx={{ backgroundColor: 'black' }}></Avatar>} label={userName} />
          </Stack>

          <IconButton onClick={logout} color='error'>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      
      <Drawer variant="permanent" open={open} >
        <DrawerHeader sx={{ backgroundColor: 'black' }}>
          <img src={logo} alt="Step Rise Technology" width={169} />
          <IconButton onClick={handleDrawerToggle} sx={{ color: 'white' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Tooltip title="Dashboard" placement="right" arrow disableHoverListener={open}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: 'center',
                  px: 2.5,
                  backgroundColor: selected ? 'black' : 'whitesmoke',
                  color: selected ? 'white' : 'inherit',
                  '&:hover': {
                    backgroundColor: selected ? 'black' : 'whitesmoke', 
                    color: selected ? 'white' : 'inherit',
                  },
                }}
                onClick={handleClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 2.5,
                    justifyContent: 'center',
                  }}
                >
                  <DashboardIcon sx={{ color: selected ? '#fff' : 'black' }} /> {/* Change icon color */}
                </ListItemIcon>

                <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
        
        <List>
          {menuItems.map((item, index) => (
            <div key={index} >
              <ListItem disablePadding sx={{ display: 'block', backgroundColor: 'whitesmoke', border: '1px solid white' }} onClick={() => item.path ? handleItemClick(item.path) : handleBranchClick(index)}>
                
                <Tooltip title={item.text} placement="right" arrow disableHoverListener={open}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                    {item.children ? (branchOpen[index] ? <ExpandLess sx={{ color: 'black' }} /> : <ExpandMore sx={{ color: 'black' }} />) : null}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              {item.children && (
                <Collapse in={branchOpen[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((child, childIndex) => (
                      <ListItem disablePadding sx={{ display: 'block' }} key={childIndex} onClick={() => handleItemClick(child.path)}>
                        <Tooltip title={child.text} placement="right" arrow disableHoverListener={open}>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              pl: 4,
                              backgroundColor: selectedItem === child.path ? 'black' : 'inherit',  // Highlight selected item
                              color:  selectedItem === child.path ? 'white' : 'inherit',
                              '&:hover': {
                                backgroundColor: selectedItem === child.path ? 'black' : 'inherit',  // Change background color on hover
                                color: selectedItem === child.path ? 'white' : 'inherit',  // Change text color on hover
                              },
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                                color:  selectedItem === child.path ? 'white !important' : 'inherit',
                                '& .MuiSvgIcon-root': {
                                  color: selectedItem === child.path ? 'white' : 'black',
                                },
                              }}
                            >
                              {child.icon}
                            </ListItemIcon>

                            <ListItemText primary={child.text} 
                              sx={{ 
                                opacity: open ? 1 : 0 ,
                                color: selectedItem === child.path ? 'white' : 'inherit' 
                              }} 
                            />
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
