
  
  export const isEmpty = (value) => {
    if (typeof value !== 'string') {
      return true;
    }
    return value.trim() === '';
  };

  export const isMinLength = (value, minLength) => {
    return value.trim().length < minLength;
  };
  
  export const isValidPrimaryMobile = (value) => {
    const regex = /^(?:(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}|(?:\+?91[-\s]?)?[2-9]\d{2}[-\s]?\d{6})$/;
    return regex.test(value);
  };
  
  export const isValidEmail = (email) => {
    // A more comprehensive regex for email validation
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) return false;
    
    // Split the email at '@' and get the domain part
    const domain = email.split('@')[1];
    
    // Ensure the domain does not have more than two dots
    const dotCount = (domain.match(/\./g) || []).length;
    
    return dotCount <= 2;
  };
  
  export const isValidDate = (value) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    return dateRegex.test(value);
  };
  
  // Function to check if a date is in the future
  export const isFutureDate = (date) => {
    return new Date(date) > new Date();
  };
  
  // Function to check if a mobile number has 10 digits
  export const isValidMobileNumber = (mobileNumber) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobileNumber);
  };
  
  
  // Function to check if a value meets minimum length requirement
  export const hasMinLength = (value, minLength) => {
    return value.length >= minLength;
  };

  /** GSTIN FORMAT VALIDATION */
  export const isValidGSTIN = (gstin) => {
    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9A-Z]{1}$/;
    return regex.test(gstin);
  };
  