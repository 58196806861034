import React, {useCallback, useEffect, useRef, useState} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {jwtDecode} from 'jwt-decode';
import { AppBar, Button, Card, CardContent, Container, Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import axios from 'axios';
import ResponsiveTable from '../Components/ResponsiveTable';
import StepriseAutocompleteBox from '../Components/StepriseAutocompleteBox';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import StepriseTextField from '../Components/StepriseTextField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';
import ExtensionIcon from '@mui/icons-material/Extension';
import DraftsIcon from '@mui/icons-material/Drafts';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomSnackbar from '../Components/CustomSnackbar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import ProcessingDialog from '../Components/ProcessingDialog';
import CloseIcon from '@mui/icons-material/Close';
import exportToExcel from "../utils/exportToExcel";

dayjs.extend(customParseFormat);
dayjs.extend(duration);

const styles = {
  tableContainer: {
    overflowX: 'auto',
    maxWidth: '100%',
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  actionCell: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide 
  direction="left" 
  ref={ref}
  timeout={{
      enter: 12315000,
      exit: 12315000,
    }}
    easing={{
      enter: 'ease-in-out',
      exit: 'ease-in-out',
    }}
   {...props} />;
});

const initialColumnVisibility = {
  'taskMst.taskName': true,
  'fileNo': true,
  'fileName': true,
  'isBillable': false,
  'court.courtShortName': true,
  'courtNo': true,
  'itemNo': false,
  'startDate': false,
  'startTime': false,
  'endDate': false,
  'endTime': false,
  'advocate.fullName': false,
  'intern.fullName': false,
  'clerk.fullName': false,
  'counsel': false,
  'otherAdvocate.fullName': false,
  'status': true
};

export default function WorkManagment() {
  const [headers, setHeaders] = useState([]);
  const [visibilityState, setVisibilityState] = useState({});
  const [dwrSummary, setDwrSummary] = useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const [statusCount, setStatusCount] = useState([]);
  const [userDtls, setUserDtls] = useState([]);
  const [taskTbl, setTaskTbl] = useState([]);
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);

  const [fileInputValue, setFileInputValue] = useState('');
  const [files, setFiles] = useState([]);
  const [filterAdvocInputValue, setFilterAdvocInputValue] = useState('');
  const [filterAdvocs, setFilterAdvocs] = useState([]);
  const [taskMstInputValue, setTaskMstInputValue] = useState('');
  const [taskmsts, setTaskmsts] = useState([]);

  const [filterData, setFilterData] = useState({
    status: { value:'Inprogress', error:false, errorMessage: '' },
    task: { value:null, error:false, errorMessage: '' },
    file: { value:null, error:false, errorMessage: '' },
    advocate: { value:null, error:false, errorMessage: '' }
  });

  const [workData, setWorkData] = useState({
    _id: { value: ''},
    workId: {value:'', error:false, errorMessage: '' },
    fileNo: {value:'', error:false, errorMessage: '' },
    fileName: {value:'', error:false, errorMessage: '' },
    taskName: {value:'', error:false, errorMessage: '' },
    startDate: { value:null, error:false, errorMessage: '' },
    startTime: { value:'', error:false, errorMessage: '' },
    endDate: { value:null, error:false, errorMessage: '' },
    endTime: { value:null, error:false, errorMessage: '' },
    taskManagmentId: { value: null, error:false, errorMessage: ''},
    workDoneTimeFrame: { value: null, error:false, errorMessage: ''},
    workStartDate: { value:null, error:false, errorMessage: '' },
    workStartTime:{ value: null, error:false, errorMessage: '' },
    workEndDate: { value: null, error:false, errorMessage: '' },
    workEndTime:{ value: null, error:false, errorMessage: '' },
    prevHours:{ value: '', error:false, errorMessage: '' },
    workedHours: { value: '', error:false, errorMessage: '' },
    billedHours: {value: '', error:false, errorMessage: ''},
    totalHours:{ value: '', error:false, errorMessage: '' },
    ldh:{ value: null, error:false, errorMessage: '' },
    ndh:{ value: null, error:false, errorMessage: '' },
    workStatus:{ value: 'Inprogress', error:false, errorMessage: '' },
    remarks: { value: '', error:false, errorMessage: '' },
    workDetails: { value: '', error:false, errorMessage: '' },
    workedBy:{ value: null, error:false },
    workedDate:{ value: null, error:false },
    lastWorkModifiedBy:{ value: null, error:false },
    lastWorkModifiedDate:{ value:null, error:false },
  });

  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  /* header names for the table columns */ 
  const taskTblHeader = [
    { accessorKey: 'taskMst.taskName', name: 'Task Name', isVisible: true },
    { accessorKey: 'fileNo', name: 'File No', isVisible: true },
    { accessorKey: 'fileName', name: 'File Name', isVisible: true },
    { accessorKey: 'isBillable', name: 'isBillable', isVisible: false },
    { accessorKey: 'court.courtShortName', name: 'Court', isVisible: true },
    { accessorKey: 'courtNo', name: 'Court No', isVisible: true },
    { accessorKey: 'itemNo', name: 'Item No', isVisible: false},
    { accessorKey: 'startDate', name: 'Start Date', isVisible: false},
    { accessorKey: 'startTime', name: 'Start Time', isVisible: false},
    { accessorKey: 'endDate', name: 'End Date', isVisible: false},
    { accessorKey: 'endTime', name: 'End Time', isVisible: false},
    { accessorKey: 'advocate.fullName', name: 'Advocate', isVisible: false},
    { accessorKey: 'intern.fullName', name: 'Intern', isVisible: false},
    { accessorKey: 'clerk.fullName', name: 'Clerk', isVisible: false},
    { accessorKey: 'counsel', name: 'Counsel', isVisible: false},
    { accessorKey: 'otherAdvocate.fullName', name: 'Other Advocate', isVisible: false},
    { accessorKey: 'status', name: 'Status', isVisible: true },
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const dcrptData = jwtDecode(token);
      setUserDtls(dcrptData);
    }
  }, []);

  useEffect(() => {
    if (userDtls.user_ID) {
      let roleName = userDtls.roleName;

      if(roleName === 'Advocate'){
        filterData.status.value = 'Inprogress';
        fetchTaskStatusCount();
        fetchAssignedTask('Inprogress');
      }else{
        fetchTaskStatusCount();
        fetchAssignedTask('Draft');
      }

      /*const currentDate = dayjs();
      const endDate = currentDate
      const endTime = currentDate.add(1, 'hour');

      setWorkData(prevState => ({
        ...prevState,
        workStartDate: { value: currentDate }
      }));*/
    }
  }, [userDtls]);
 
  /* input handle change */
  const handleChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setWorkData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error, errorMessage}
    }));
  };

  /* filter input change */
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setFilterData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error, errorMessage}
    }))
  }

  /* handle ReactQuill change */
  const handleQuillChange = (value) => {
    setWorkData((prevData) => ({
      ...prevData,
      workDetails: { ...prevData.workDetails, value, error: false, errorMessage: '' },
    }));
  };

  /* filetr search criteria */
  const handleFilterSearch = (type) => {
    fetchAssignedTask(type);
  }

  /* fetch court call when type court name */
  const fetchFiles= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/file/getfileskbyprifix/${value}`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching  file data:', error);
    }
  };

  /* fetch all task mst*/
  const fetchTaskMstByPrefix = async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/task/gettasksbyprifix/${value}`);
      setTaskmsts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchAssignedTask = async (type) => {
    if(type === 'Draft'){
      filterData.status.value = 'Draft'
    }else if(type === 'Inprogress'){
      filterData.status.value = 'Inprogress'
    }else if(type === 'Elapsed'){
      filterData.status.value = 'Elapsed'
    }else if(type === 'Extended'){
      filterData.status.value = 'Extended'
    }else if(type === 'Completed'){
      filterData.status.value = 'Completed'
    }else if(type === 'Incomplete'){
      filterData.status.value = 'Incomplete'
    }

    setProcessing(true);
    try {
      let status = filterData.status.value || '';
      let task = filterData.task.value || null;
      let file = filterData.file.value || null;
      let advocate = filterData.advocate.value || null;
      let loginId = userDtls.user_ID;
      let roleName = userDtls.roleName;

      const response = await axios.get(`${BASE_API_URL}/api/taskmanagment/getalltask/${status}/${task}/${file}/${advocate}/${roleName}/${loginId}`);

      setTaskTbl(response.data);
    } catch (error) {
      setProcessing(false)
      console.error('Error fetching data:', error);
    }finally{
      setProcessing(false);
    }
  }

  /* get status count of task */
  const fetchTaskStatusCount = async () => {
    let roleName = userDtls.roleName;
    let loginId = userDtls.user_ID;
    try {
      const response = await axios.get(`${BASE_API_URL}/api/taskmanagment/getstatuscount/${roleName}/${loginId}`);
      setStatusCount(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  /* fetch employee call when type product */
  const fetchEmp = async (value, roleType) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/emp/getempbyroleandprefix/${value}/${roleType}`);

    if(roleType === 'FADV'){
        setFilterAdvocs(response.data);
      }
    } catch (error) {
      console.error('Error fetching  Emp data:', error);
    }
  };

  const handleTaskMstInputChange = (event, value) => {
    setTaskMstInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      task: {
        ...prevData.task,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 2) {
      fetchTaskMstByPrefix(value);
    } else {
      setTaskmsts([]);
    }
  };

  /* Requied for Master Task autocomplete */
  const handleTaskMstAutocompleteChange = (event, selectedItem) => {
    const selectedData = taskmsts.find(item => item.taskName === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          task: {
            ...prevData.task,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected task data not found');
    }
  };


  /* Requied for file autocomplete */
  const handleFileInputChange = (event, value) => {
    setFileInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      file: {
        ...prevData.file,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 3) {
      fetchFiles(value);
    } else {
      setFiles([]);
    }
  };

  /* Requied for file autocomplete */
  const handleFileAutocompleteChange = (event, selectedItem) => {
    const selectedData = files.find(item => item.fileNo === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          file: {
            ...prevData.file,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected file data not found');
    }
  };

  /* Requied for filter advocate autocomplete */
  const handleFilterAdvocInputChange = (event, value) => {
    setFilterAdvocInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      advocate: {
        ...prevData.advocate,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'FADV');
    } else {
      setFilterAdvocs([]);
    }
  };

  /* Requied for filter advocate autocomplete */
  const handleFilterAdvocAutocompleteChange = (event, selectedItem) => {
    const selectedData = filterAdvocs.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          advocate: {
            ...prevData.advocate,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected filter advocate data not found');
    }
  };

  /* column to be export */
  const exportedTaskColumns = {
    taskMst: 'Task Name',
    fileNo: 'File No',
    fileName: 'File Name',
    advocate: 'Advocate',
    intern: 'Intern',
    clerk: 'Clerk',
    counsel: 'Counsel',
    otherAdvocate: 'Other Advocate',
    court: 'Court',
    courtNo: 'Court No',
    itemNo: 'Item No',
    isBillable: 'isBillable',
    startDate: 'Start Date',
    startTime: 'Start Time',
    endDate: 'End Date',
    endTime: 'End Time',
    status: 'Status'
  };

  /* export all data */
  const handleExportAllData = () => {
    setProcessing(true);
    try {
      let data = taskTbl;
      exportToExcel(data, 'Work.xlsx', exportedTaskColumns, 'TASK');
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      console.error(error);
    }finally{
      setProcessing(false);
    }
  };

  /* export paage data only */
  const handleExportPageData = (rows) => {
    setProcessing(true);
    try {
      const data = rows.map((row) => row.original);
      exportToExcel(data, 'Task.xlsx', exportedTaskColumns, 'TASK');

      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      console.error(error);
    }finally{
      setProcessing(false);
    }
  }

  const handleEdit = (row) => {
    setProcessing(true);
    try {
      setWorkData((prevData) => {
        let startDate = null;
        let startTime = null;
        let endDate = null;
        let endTime = null;
        let workStartDate = null;
        let workStartTime = null;
        let workEndDate = null;
        let workEndTime = null;
        let ldh = null;
        let ndh = null;
        let prevHours = 0;
        let workedHours = 0;
        let billedHours = 0;
        let totalHours = 0;

        const currentDate = dayjs();
        const tempWorkEndTime = currentDate.add(1, 'hour');


        if (row.original.startDate) {
          startDate = dayjs(row.original.startDate).isValid() ? dayjs(row.original.startDate) : null;
        }
        if (row.original.startTime) {
          let tempStartTime = dayjs.utc(row.original.startTime).tz('Asia/Kolkata');
          startTime = tempStartTime.subtract(5, 'hour').subtract(30, 'minute');
        }
        if (row.original.endDate) {
          endDate = dayjs(row.original.endDate).isValid() ? dayjs(row.original.endDate) : null;
        }
        if (row.original.endTime) {
          let tempEndTime = dayjs.utc(row.original.endTime).tz('Asia/Kolkata');
          endTime = tempEndTime.subtract(5, 'hour').subtract(30, 'minute');
        }

        if (row.original.workStartDate) {
          let tempWorkStartDate = dayjs.utc(row.original.workStartDate).tz('Asia/Kolkata');
          workStartDate = tempWorkStartDate.subtract(5, 'hour').subtract(30, 'minute');
          //workStartDate = dayjs.utc(row.original.workStartDate).tz('Asia/Kolkata');
        }else{
          workStartDate = dayjs.utc(currentDate).tz('Asia/Kolkata');
        }
        if (row.original.workStartTime) {
          workStartTime = dayjs.utc(row.original.workStartTime).tz('Asia/Kolkata').subtract(5, 'hour').subtract(30, 'minute');
        }else{
          workStartTime = dayjs.utc(currentDate).tz('Asia/Kolkata');
        }
        if (row.original.workEndDate) {
          let tempWorkEndDate = dayjs.utc(row.original.workEndDate).tz('Asia/Kolkata');
          workEndDate = tempWorkEndDate.subtract(5, 'hour').subtract(30, 'minute');
          //workEndDate = dayjs(row.original.workEndDate).isValid() ? dayjs(row.original.workEndDate) : null;
        }else{
          workEndDate = dayjs.utc(currentDate).tz('Asia/Kolkata');
        }
        if (row.original.workEndTime) {
          workEndTime = dayjs.utc(row.original.workEndTime).tz('Asia/Kolkata').subtract(5, 'hour').subtract(30, 'minute');
        }else{
          workEndTime = dayjs.utc(tempWorkEndTime).tz('Asia/Kolkata');
        }
        if (row.original.ldh) {
          ldh = dayjs(row.original.ldh).isValid() ? dayjs(row.original.ldh) : null;
        }
        if (row.original.ndh) {
          ndh = dayjs(row.original.ndh).isValid() ? dayjs(row.original.ndh) : null;
        }
        

        if (Number(row.original.prevHours) > 0) {
          prevHours = row.original.prevHours;
        }else{
          prevHours = 0;
        }
        if (row.original.workedHours != null || row.original.workedHours != '') {
          workedHours = row.original.workedHours;
        }else{
          workedHours = 1;
        }
        if (Number(row.original.billedHours) > 0) {
          billedHours = row.original.billedHours;
        }else{
          billedHours = 1;
        }
        if (Number(row.original.totalHours) > 0) {
          totalHours = row.original.totalHours;
        }else{
          totalHours = prevHours + billedHours;
        }

        const updatedData = {
          ...prevData,
          _id: {
            value: row.original._id,
            error: false,
            errorMessage: ''
          },
          fileNo: {
            value: row.original.fileNo,
            error: false,
            errorMessage: ''
          },
          fileName: {
            value: row.original.fileName,
            error: false,
            errorMessage: ''
          },
          taskName: {
            value: row.original.taskMst.taskName,
            error: false,
            errorMessage: ''
          },
          startDate: {
            value: startDate,
            error: false,
            errorMessage: ''
          },
          startTime: {
            value: startTime,
            error: false,
            errorMessage: ''
          },
          endDate: {
            value: endDate,
            error: false,
            errorMessage: ''
          },
          endTime: {
            value: endTime,
            error: false,
            errorMessage: ''
          },
          isBillable: {
            value: row.original.isBillable,
            error: false,
            errorMessage: ''
          },
          workStartDate: {
            value: workStartDate,
            error: false,
            errorMessage: ''
          },
          workStartTime: {
            value: workStartTime,
            error: false,
            errorMessage: ''
          },
          workEndDate: {
            value: workEndDate,
            error: false,
            errorMessage: ''
          },
          workEndTime: {
            value: workEndTime,
            error: false,
            errorMessage: ''
          },
          prevHours: {
            value: prevHours,
            error: false,
            errorMessage: ''
          },
          workedHours: {
            value: workedHours,
            error: false,
            errorMessage: ''
          },
          billedHours: {
            value: billedHours,
            error: false,
            errorMessage: ''
          },
          totalHours: {
            value: totalHours,
            error: false,
            errorMessage: ''
          },
          ldh: {
            value: ldh,
            error: false,
            errorMessage: ''
          },
          ndh: {
            value: ndh,
            error: false,
            errorMessage: ''
          },
          workStatus: {
            value: row.original.workStatus,
            error: false,
            errorMessage: ''
          },
          remarks: {
            value: row.original.remarks || '',
            error: false,
            errorMessage: ''
          },
          workDetails: {
            value: row.original.workDetails || '',
            error: false,
            errorMessage: ''
          },
          isActive: {
            value: row.original.isActive,
            error: false,
            errorMessage: ''
          }
        };

        return updatedData;
      });

      
      setIsSubmitBtn(false);
      setProcessing(false);
    } catch (error) {
      console.error("error while calling handleEdit", error);
    }
  }

  /** date change handler */
  const handleDateChange = (name, value) => {
    let error = false;
    let errorMessage = '';
  
    if (name === 'workStartDate' || name === 'workEndDate' || name === 'workStartTime' || name === 'workEndTime' || name === 'ldh' || name === 'ndh') {
      if (!value) {
        error = true;
        errorMessage = 'You must enter a valid date';
      } else if (isFutureDate(value)) {
        error = true;
        errorMessage = 'Date cannot be a future date';
      }
    }

    /* update workData state */
    setWorkData((prevData) => {
      const updatedData = { ...prevData, [name]: { value, error, errorMessage } };
  
      const { workStartDate, workStartTime, workEndDate, workEndTime, prevHours } = updatedData;
  
      // If all required fields are present, perform the calculations
      if (workStartDate.value && workStartTime.value && workEndDate.value && workEndTime.value) {
        const startDateTime = dayjs(`${workStartDate.value.format('YYYY-MM-DD')} ${workStartTime.value.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
        const endDateTime = dayjs(`${workEndDate.value.format('YYYY-MM-DD')} ${workEndTime.value.format('HH:mm')}`, 'YYYY-MM-DD HH:mm');
  
        if (endDateTime.isBefore(startDateTime)) {
          updatedData.workEndDate.error = true;
          updatedData.workEndDate.errorMessage = 'End date and time must be after start date and time';
          updatedData.workEndTime.error = true;
          updatedData.workEndTime.errorMessage = 'End date and time must be after start date and time';
          updatedData.workedHours = { value: '0', error: false, errorMessage: '' };
          updatedData.billedHours = { value: '0', error: false, errorMessage: '' };
          updatedData.totalHours = { value: '0', error: false, errorMessage: '' };
        } else {
          const workedDuration = dayjs.duration(endDateTime.diff(startDateTime));
          const workedHours = workedDuration.asHours();
          const billedHours = Math.ceil(workedHours);
          const totalHours = billedHours + parseFloat(prevHours.value || 0);
  
          updatedData.workedHours = { value: workedDuration.format('HH:mm'), error: false, errorMessage: '' };
          updatedData.billedHours = { value: billedHours.toString(), error: false, errorMessage: '' }; // Round up billed hours
          updatedData.totalHours = { value: totalHours.toFixed(0), error: false, errorMessage: '' };
        }
      } else {
        updatedData.workedHours = { value: '0', error: false, errorMessage: '' };
        updatedData.billedHours = { value: '0', error: false, errorMessage: '' };
        updatedData.totalHours = { value: '0', error: false, errorMessage: '' };
      }
  
      return updatedData;
    });
  };

  /* validating future date */
  const isFutureDate = (date) => {
    return new Date(date) > new Date();
  };

  /* on click close msg */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /* clear the screen */
  const handleReload = () => {
    setWorkData({
      _id: { value: ''},
      workId: {value:'', error:false, errorMessage: '' },
      fileNo: {value:'', error:false, errorMessage: '' },
      fileName: {value:'', error:false, errorMessage: '' },
      taskName: {value:'', error:false, errorMessage: '' },
      startDate: { value:null, error:false, errorMessage: '' },
      startTime: { value:'', error:false, errorMessage: '' },
      endDate: { value:null, error:false, errorMessage: '' },
      endTime: { value:null, error:false, errorMessage: '' },
      taskManagmentId: { value: null, error:false, errorMessage: ''},
      workDoneTimeFrame: { value: null, error:false, errorMessage: ''},
      workStartDate: { value:null, error:false, errorMessage: '' },
      workStartTime:{ value: null, error:false, errorMessage: '' },
      workEndDate: { value: null, error:false, errorMessage: '' },
      workEndTime:{ value: null, error:false, errorMessage: '' },
      prevHours:{ value: '', error:false, errorMessage: '' },
      workedHours: { value: '', error:false, errorMessage: '' },
      billedHours: {value: '', error:false, errorMessage: ''},
      totalHours:{ value: '', error:false, errorMessage: '' },
      ldh:{ value: null, error:false, errorMessage: '' },
      ndh:{ value: null, error:false, errorMessage: '' },
      workStatus:{ value: 'Completed', error:false, errorMessage: '' },
      remarks: { value: '', error:false, errorMessage: '' },
      workDetails: { value: '', error:false, errorMessage: '' },
      workedBy:{ value: null, error:false },
      workedDate:{ value: null, error:false },
      lastWorkModifiedBy:{ value: null, error:false },
      lastWorkModifiedDate:{ value:null, error:false },
    });

    fetchAssignedTask('Inprogress');
  }

  /* on click submit button */
  const handleSubmit = async(e) => {
    e.preventDefault();
    let responseSeverity = '';
    let responseMessage = '';
    setProcessing(true);
    let status = ''
    const buttonName = e.target.getAttribute('data-button-name');

    if(buttonName === 'save' || buttonName === 'add'){
      status = 'Inprogress';
    }else if(buttonName === 'extend'){
      status = 'Extended';
    }else if(buttonName === 'submit'){
      status = 'Completed';
    }

    const loginId = userDtls.user_ID;

    let finalDataToSend = {
      _id: workData._id.value,
      workDoneTimeFrame: workData.workDoneTimeFrame.value,
      workStartDate: workData.workStartDate.value,
      workStartTime:workData.workStartTime.value,
      workEndDate: workData.workEndDate.value,
      workEndTime:workData.workEndTime.value,
      prevHours:workData.prevHours.value,
      workedHours: workData.workedHours.value,
      billedHours: workData.billedHours.value,
      totalHours:workData.totalHours.value,
      ldh:workData.ldh.value,
      ndh:workData.ndh.value,
      status: status,
      workStatus: status,
      remarks: workData.remarks.value,
      workDetails: workData.workDetails.value,
      workedBy: loginId,
      workedDate: null,
      lastWorkModifiedBy: loginId,
      lastWorkModifiedDate: null
    }

    try {
      const response = await axios.post(`${BASE_API_URL}/api/taskmanagment/workrecords`, {finalDataToSend});
      let data = response.data;
      let msg = data.message;

      if(data.status === 'success'){
        responseSeverity = 'success';
        responseMessage = `${msg}` ;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);

        handleReload();
      }else{
        responseSeverity = 'error';
        responseMessage = 'Error while creating file' ;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);
        return;
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while creating file, please contact admin';

      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setProcessing(false);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }

  const handleDialogClose = async () => {
    try {
      setDialogOpen(false);
    } catch (error) {
      console.error('Error while calling handleDialogClose :', error)
    }finally{
      setProcessing(false);
    }
  }

  /* open dialog box */
  const viewDWR = async(req, res) => {
    try {
      setProcessing(true);
      setDialogOpen(true);
      const response = await axios.get(`${BASE_API_URL}/api/taskmanagment/getdwrsummary`);
      setDwrSummary(response.data);

    } catch (error) {
      console.error('Error while calling handleClickOpen :', error)
    }finally{
      setProcessing(false);
    }
  };

  const handleHeadersChange = useCallback((newHeaders) => {
    setHeaders(newHeaders);
  }, []);

  return (
    <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
        <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}> 
          <CardContent>
          <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StepriseTextField
                    disabled
                    label="File No"
                    name="fileNo"
                    size='small'
                    value={workData.fileNo.value}
                    onChange={handleChange}
                    error={workData.fileNo.error}
                    helperText={workData.fileNo.error && workData.fileNo.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StepriseTextField
                    disabled
                    label="File Name"
                    name="fileName"
                    size='small'
                    value={workData.fileName.value}
                    onChange={handleChange}
                    error={workData.fileName.error}
                    helperText={workData.fileName.error && workData.fileName.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StepriseTextField
                    disabled
                    label="Task Name"
                    name="taskName"
                    size='small'
                    value={workData.taskName.value}
                    onChange={handleChange}
                    error={workData.taskName.error}
                    helperText={workData.taskName.error && workData.taskName.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839 !important'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839 !important', 
                          '&.Mui-focused': {
                            color: '#353839 !important',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      disabled
                      label="Start Date"
                      name="startDate"
                      fullWidth
                      required
                      value={workData.startDate.value}
                      onChange={(newValue) => handleDateChange('startDate', newValue)}
                      error={workData.startDate.error}
                      helperText={workData.startDate.error && workData.startDate.errorMessage}
                      shouldDisableDate={(date) => isFutureDate(date)}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <StepriseTextField
                    disabled
                    label="Start Time"
                    name="startTime"
                    size='small'
                    value={dayjs(workData.startTime.value).format('HH:mm')}
                    onChange={handleChange}
                    error={workData.startTime.error}
                    helperText={workData.startTime.error && workData.startTime.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839 !important', 
                          '&.Mui-focused': {
                            color: '#353839 !important',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      disabled
                      label="End Date"
                      name="endDate"
                      fullWidth
                      required
                      value={workData.endDate.value}
                      onChange={(newValue) => handleDateChange('endDate', newValue)}
                      error={workData.endDate.error}
                      helperText={workData.endDate.error && workData.endDate.errorMessage}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <StepriseTextField
                    disabled
                    label="End Time"
                    name="endTime"
                    size='small'
                    value={dayjs(workData.endTime.value).format('HH:mm')}
                    onChange={handleChange}
                    error={workData.endTime.error}
                    helperText={workData.endTime.error && workData.endTime.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839', 
                          '&.Mui-focused': {
                            color: '#353839',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      label="Work Start Date"
                      name="workStartDate"
                      fullWidth
                      required
                      value={workData.workStartDate.value}
                      onChange={(newValue) => handleDateChange('workStartDate', newValue)}
                      error={workData.workStartDate.error}
                      helperText={workData.workStartDate.error && workData.workStartDate.errorMessage}
                      shouldDisableDate={(date) => isFutureDate(date)}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Work Start Time"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input':{
                          height: '0.5375em !important'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                      }}
                      value={workData.workStartTime.value}
                      onChange={(newValue) => handleDateChange('workStartTime', newValue)}
                      error={workData.workStartTime.error}
                      helperText={workData.workStartTime.error && workData.workStartTime.errorMessage}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839', 
                          '&.Mui-focused': {
                            color: '#353839',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      label="Work End Date"
                      name="workEndDate"
                      fullWidth
                      required
                      value={workData.workEndDate.value}
                      onChange={(newValue) => handleDateChange('workEndDate', newValue)}
                      error={workData.workEndDate.error}
                      helperText={workData.workEndDate.error && workData.workEndDate.errorMessage}
                      //shouldDisableDate={(date) => isFutureDate(date)}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Work End Time"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input':{
                          height: '0.5375em !important'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                      }}
                      value={workData.workEndTime.value}
                      onChange={(newValue) => handleDateChange('workEndTime', newValue)}
                      error={workData.workEndTime.error}
                      helperText={workData.workEndTime.error && workData.workEndTime.errorMessage}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={1}>
                  <StepriseTextField
                    disabled
                    label="Prev Hrs"
                    name="prevHours"
                    size='small'
                    value={workData.prevHours.value}
                    onChange={handleChange}
                    error={workData.prevHours.error}
                    helperText={workData.prevHours.error && workData.prevHours.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={1}>
                  <StepriseTextField
                    disabled
                    label="WH"
                    name="workedHours"
                    size='small'
                    value={workData.workedHours.value}
                    onChange={handleChange}
                    error={workData.workedHours.error}
                    helperText={workData.workedHours.error && workData.workedHours.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={1}>
                  <StepriseTextField
                    disabled
                    label="BH"
                    name="billedHours"
                    size='small'
                    value={workData.billedHours.value}
                    onChange={handleChange}
                    error={workData.billedHours.error}
                    helperText={workData.billedHours.error && workData.billedHours.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={1}>
                  <StepriseTextField
                    disabled
                    label="Total"
                    name="totalHours"
                    size='small'
                    value={workData.totalHours.value}
                    onChange={handleChange}
                    error={workData.totalHours.error}
                    helperText={workData.totalHours.error && workData.totalHours.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839', 
                          '&.Mui-focused': {
                            color: '#353839',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      label="LDH"
                      name="ldh"
                      fullWidth
                      required
                      value={workData.ldh.value}
                      onChange={(newValue) => handleDateChange('ldh', newValue)}
                      error={workData.ldh.error}
                      helperText={workData.ldh.error && workData.ldh.errorMessage}
                      shouldDisableDate={(date) => isFutureDate(date)}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839 !important'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839', 
                          '&.Mui-focused': {
                            color: '#353839',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      label="NDH"
                      name="ndh"
                      fullWidth
                      required
                      value={workData.ndh.value}
                      onChange={(newValue) => handleDateChange('ndh', newValue)}
                      error={workData.ndh.error}
                      helperText={workData.ndh.error && workData.ndh.errorMessage}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <FormControl fullWidth 
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}>
                    <InputLabel>Task Status</InputLabel>
                      <Select size='small' label="Work Status" name='workStatus'
                        value={workData.workStatus.value}
                        onChange={handleChange}
                        
                      >
                        <MenuItem value='Inprogress'>Inprogress</MenuItem>
                        <MenuItem value='Completed'>Completed</MenuItem>
                        <MenuItem value='Incomplete'>Incomplete</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <StepriseTextField
                    label="Remarks"
                    name="remarks"
                    size='small'
                    value={workData.remarks.value}
                    onChange={handleChange}
                    error={workData.remarks.error}
                    helperText={workData.remarks.error && workData.remarks.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{mb:2}}>
                  <ReactQuill
                    value={workData.workDetails.value}
                    onChange={handleQuillChange}
                    placeholder="Enter Work Details"
                    theme="snow"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  {isSubmitBtn ? (
                    <Button onClick={handleSubmit} data-button-name="add" fullWidth variant="contained" startIcon={<DraftsIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Save
                    </Button>
                    ) : (
                    <Button onClick={handleSubmit} data-button-name="save" variant="contained" fullWidth startIcon={<EditNoteIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Save
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Button variant="contained" onClick={handleSubmit} data-button-name="extend" fullWidth  startIcon={<ExtensionIcon />}
                    sx={{
                      backgroundColor: 'black', 
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    Extend
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Button variant="contained" onClick={handleSubmit} data-button-name="submit" fullWidth type="button" startIcon={<SaveIcon />}
                    sx={{
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Button variant="contained" onClick={handleReload} fullWidth type="button" startIcon={<RefreshIcon />}
                    sx={{
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    Reload
                  </Button>
                </Grid>
              </Grid>
            </form>
            <ProcessingDialog open={processing}/>
          </CardContent>
        </Card>

        <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <FormControl fullWidth 
                  sx={{
                    mt: 0,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}>
                  <InputLabel>Task Status</InputLabel>
                    <Select size='small' label="Task Status" name='status'
                      value={filterData.status.value}
                      onChange={handleFilterChange}
                      
                    >
                      <MenuItem value='Inprogress'>Inprogress</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="task"
                  inputValue={taskMstInputValue}
                  onInputChange={handleTaskMstInputChange}
                  onChange={handleTaskMstAutocompleteChange}
                  options={taskmsts.map((item) => item.taskName)}
                  label="Task Name"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="file"
                  inputValue={fileInputValue}
                  onInputChange={handleFileInputChange}
                  onChange={handleFileAutocompleteChange}
                  options={files.map((item) => item.fileNo)}
                  label="File No"
                />
              </Grid>

              {/*
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="advocate"
                    inputValue={filterAdvocInputValue}
                    onInputChange={handleFilterAdvocInputChange}
                    onChange={handleFilterAdvocAutocompleteChange}
                    options={filterAdvocs.map((item) => item.fullName)}
                    label="Assign Advocate"
                  />
                </Grid>
              */}

              <Grid item xs={12} sm={12} md={12} lg={1}>
                <Button variant="contained" size='large' onClick={() => handleFilterSearch('NO')} fullWidth type="button"
                  startIcon={<SearchIcon />}
                  sx={{
                    backgroundColor: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={1}>
                <Button variant="contained" size='medium' onClick={viewDWR} fullWidth type="button"
                  sx={{
                    backgroundColor: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  DWR
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={3} sx={{mb:2}}>
          {(userDtls.roleName === 'Admin' || userDtls.roleName === 'Super Admin') ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ResponsiveTable sx={{borderTop: '5px solid black'}}
                exportAllData={handleExportAllData}
                handleExportSelectedRows={handleExportPageData}
                handleExportRows={handleExportPageData}
                data={taskTbl}
                headerNames={taskTblHeader}
                editAction={false}
                copyAction={false}
                deleteAction={false}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={false}
                copyIcon={false}
                deleteIcon={false}
                isActive={false}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={false}
                showEditIcon={false}
                showToggleIcon={false}
                showDeleteIcon={false} 
                showActionsColumn={false}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/*<ResponsiveTable sx={{borderTop: '5px solid black'}}
                exportAllData={handleExportAllData}
                exportPageRows={handleExportPageData}
                exportSelectedRows={handleExportSelectedRowsData}
                data={taskTbl}
                headerNames={taskTblHeader}
                editAction={handleEdit}
                copyAction={false}
                deleteAction={false}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={true}
                copyIcon={false}
                deleteIcon={false}
                isActive={false}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={false}
                showEditIcon = {false}
                showToggleIcon={false}
                showDeleteIcon={false} 
                showActionsColumn={true} 
              />*/}

              <ResponsiveTable sx={{borderTop: '5px solid black'}}
                exportAllData={handleExportAllData}
                handleExportSelectedRows={handleExportPageData}
                handleExportRows={handleExportPageData}
                data={taskTbl}
                headerNames={taskTblHeader}
                editAction={handleEdit}
                copyAction={false}
                deleteAction={false}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={EditNoteIcon}
                copyIcon={false}
                deleteIcon={false}
                isActive={false}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={false}
                showEditIcon={true}
                showToggleIcon={false}
                showDeleteIcon={false}
                showActionsColumn={true}
                onHeadersChange={handleHeadersChange}
              />
            </Grid>
          )}
        </Grid>

        <Dialog disableBackdropClick={true} fullScreen open={dialogOpen} onClose={handleDialogClose} TransitionComponent={Transition} keepMounted >
          <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close" >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 1, sm: 2, md: 2, lg:2},  width: '100%' }}>
              <Card sx={{borderTop: '5px solid black', width: '100%', mb:3}}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TableContainer sx={styles.tableContainer}>
                        <Table aria-label="responsive table">
                          <TableHead sx={{ backgroundColor: 'wheat' }}>
                            <TableRow>
                              <TableCell style={{ ...styles.tableCell }}>SL</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>File no</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>File Name</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>Task Name</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>Strat Date</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>Strat Time</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>End Date</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>End Time</TableCell>
                              {/* Add more headers as needed */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {dwrSummary.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ ...styles.tableCell }}>{index+1}</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>{item.fileNo}</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>{item.fileName}</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>{item.taskMst.taskName}</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>{item.workStartDate}</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>{item.workStartTime}</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>{item.workEndDate}</TableCell>
                              <TableCell style={{ ...styles.tableCell }}>{item.workEndTime}</TableCell>
                            </TableRow>
                          ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Container>
        </Dialog>
      </Box>
    </Container>
  )
}
