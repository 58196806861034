import React, { useMemo, useEffect, forwardRef, useImperativeHandle, useState} from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button, Grid, IconButton, Switch, Tooltip } from '@mui/material';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const preprocessData = (data) => {
    return data.map(row => {
        const processedRow = {};
        Object.keys(row).forEach(key => {
            const value = row[key];
            if (typeof value === 'object' && value !== null) {
                processedRow[key] = JSON.stringify(value);
            } else {
                processedRow[key] = value;
            }
        });
        return processedRow;
    });
};


const ResponsiveTable = ({
    exportAllData,
    exportPageRows,
    exportSelectedRows,
    handleExportSelectedRows,
    handleExportRows,
    data,
    headerNames,
    editAction,
    copyAction,
    deleteAction,
    editTooltipTitle,
    copyTooltipTitle,
    deleteTooltipTitle,
    editIcon: EditIconComponent,
    copyIcon: CopyIconComponent,
    deleteIcon: DeleteIconComponent,
    isActive,
    isActiveTooltipTitle,
    additionalActions = [],
    initialState = { columnVisibility: { address: false } },
    showCopyIcon = true,
    showEditIcon = true,
    showToggleIcon = true,
    showDeleteIcon = true,
    showActionsColumn = true,
    onHeadersChange,
    onVisibleHeadersChange, // Callback for visible headers
}) => {
    const [visibleHeaders, setVisibleHeaders] = useState([]);

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
    });

    /*const handleExportRows = (rows) => {
        console.log(rows);
        const rowData = rows.map((row) => row.original);
        const processedData = preprocessData(rowData);
        const csv = generateCsv(csvConfig)(processedData);
        download(csvConfig)(csv);
    };*/

    const columns = useMemo(
        () => [
            ...headerNames.map((header) => ({
                accessorKey: header.accessorKey,
                header: header.name,
                size: header.size,
            })),
            ...(showActionsColumn
                ? [
                    {
                        accessorKey: 'actions',
                        header: 'Actions',
                        size: 10,
                        Cell: ({ row }) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.2rem',
                                    position: 'sticky',
                                    right: 0,
                                    zIndex: 1,
                                    padding: '0.2rem',
                                    justifyContent: 'flex-center',
                                }}
                            >
                                {showEditIcon && (
                                    <Tooltip title={editTooltipTitle}>
                                        <IconButton onClick={() => editAction(row)} sx={{ color: 'black' }}>
                                            <EditIconComponent sx={{ fontSize: 30 }} />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {showCopyIcon && (
                                    <Tooltip title={copyTooltipTitle}>
                                        <IconButton onClick={() => copyAction(row)} sx={{ color: 'black' }}>
                                            <CopyIconComponent sx={{ fontSize: 20 }} />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {showDeleteIcon && (
                                    <Tooltip title={deleteTooltipTitle}>
                                        <IconButton onClick={() => deleteAction(row)} color="error">
                                            <DeleteIconComponent />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {showToggleIcon && (
                                    <Tooltip title={isActiveTooltipTitle}>
                                        <IconButton onClick={() => isActive(row)}>
                                            <Switch
                                                size="small"
                                                {...label}
                                                checked={row.original.isActive === 'Y'}
                                                color={row.original.isActive === 'Y' ? 'success' : 'error'}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {additionalActions.map((action, index) => (
                                    <Tooltip key={index} title={action.tooltipTitle}>
                                        <IconButton onClick={() => action.onClick(row)} color={action.color || 'default'}>
                                            <action.iconComponent sx={{ fontSize: 40 }} />
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </Box>
                        ),
                    },
                ]
                : []),
        ],
        [headerNames, editAction, isActive, editTooltipTitle, isActiveTooltipTitle, additionalActions, showActionsColumn, showCopyIcon, showDeleteIcon, showEditIcon, showToggleIcon],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: true,
        initialState,
        muiTableBodyCellProps: {
            sx: {
                padding: '0px 5px',
                lineHeight: '3',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                padding: '0px 4px',
                lineHeight: '2',
            },
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Button
                        sx={{ color: 'black !important' }}
                        onClick={exportAllData}
                        startIcon={<FileDownloadIcon />}
                        fullWidth
                    >
                        Export All Data
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button
                        sx={{ color: 'black !important' }}
                        disabled={table.getRowModel().rows.length === 0}
                        onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                    >
                        Export Page Rows
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button
                        sx={{ color: 'black !important' }}
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => handleExportSelectedRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        fullWidth
                    >
                        Export Selected Rows
                    </Button>
                </Grid>
            </Grid>
        ),
    });

    useEffect(() => {
        // Extract and update visible headers
        const headers = table.getHeaderGroups()?.flatMap(headerGroup =>
            headerGroup.headers
                .filter(header => header.column.getIsVisible()) // Check if the column is visible
                .map(header => header.column.columnDef.header)
        ) || [];
        
        // Update local state and trigger callback
        setVisibleHeaders(headers);
        if (onHeadersChange) {
            onHeadersChange(headers);
        }
    }, [table, onHeadersChange]);

    return <MaterialReactTable table={table} />;
};

export default ResponsiveTable;
