import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import ResponsiveTable from '../Components/ResponsiveTable';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ProcessingDialog from '../Components/ProcessingDialog';
import CustomSnackbar from '../Components/CustomSnackbar';
import StepriseTextField from '../Components/StepriseTextField';
import SearchIcon from '@mui/icons-material/Search';
import exportToExcel from "../utils/exportToExcel";

export default function Department() {
    const [userDtls, setUserDtls] = useState([]);
    const[isSubmitBtn, setIsSubmitBtn] = useState(true);
    const [open, setOpen] = useState(false);
    const [responseSeverity, setResponseSeverity] = React.useState('');
    const [responseMessage, setResponseMessage] = React.useState('');
    const [tableData, setTableData] = useState([]);
    const [processing, setProcessing] = useState(false);
    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

    const [formData, setFormData] = useState({
        _id:{ value: null },
        departmentName: { value: '', error: false, errorMessage: '' },
        isActive: { value: 'Y' },
        createdBy: { value: null, error: false, errorMessage: '' },
        createdDate: { value: null, error: false, errorMessage: '' },
        lastModifiedBy: { value: null, error: false, errorMessage: '' },
        lastModifiedDate: { value: null, error: false, errorMessage: '' }
    });

    const [filterData, setFilterData] = useState({
      departmentName: { value: '', error: false, errorMessage: '' }
    });

    /* header names for the table columns */ 
    const deptHeaders = [
      { accessorKey: 'departmentName', name: 'Department Name'},
    ];

    /* page load */
    useEffect(() => {
        const token = localStorage.getItem('token');
        const dcrptData = jwtDecode(token);
        setUserDtls(dcrptData);

        fetchAllDepartments();
    }, []);

    /* fetch all racks */
    const fetchAllDepartments = async () => {
        setProcessing(true);
        let responseSeverity = '';
        let responseMessage = '';
        let departmentName = filterData.departmentName.value ? filterData.departmentName.value : "NA";
        try {
            const response = await axios.get(`${BASE_API_URL}/api/department/getalldepartments/${departmentName}`);
            setTableData(response.data);
            setProcessing(false);
        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Something Went Wrong while fetching fee category';

            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
        }
    }

    /* input handle change */
    const handleChange = (event) => {
        const { name, value } = event.target;
        let error = false;
        let errorMessage = '';
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: { ...prevData[name], value, error, errorMessage}
        }));
    };
    
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        let error = false;
        let errorMessage = '';
    
        setFilterData((prevData) => ({
            ...prevData,
            [name]: { ...prevData[name], value, error, errorMessage}
        }));
    };

    /* on click submit button */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        let responseSeverity = '';
        let responseMessage = '';

        let valid = true;

        if (!formData.departmentName.value) {
          setFormData((prevData) => ({
            ...prevData,
            departmentName: { ...prevData.departmentName, error: true },
          }));
          valid = false;
        } else {
          setFormData((prevData) => ({
            ...prevData,
            departmentName: { ...prevData.departmentName, error: false },
          }));
        }

        if (!valid) {
            responseSeverity = 'error';
            responseMessage = 'Please select rack no';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            return;
        } else {
            const finalFormData = {
                _id: formData._id.value,
                departmentName: formData.departmentName.value,
                isActive: formData.isActive,
                createdBy: userDtls.user_ID,
                lastModifiedBy: userDtls.user_ID
            };

            try {
                const response = await axios.post(`${BASE_API_URL}/api/department/create`, finalFormData);
                let data = response.data;
                let msg = data.message;

                if(data.status === 'success'){
                    responseSeverity = 'success';
                    responseMessage = `${msg}`;

                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setProcessing(false);
                    setOpen(true);

                    handleReload();
                }else if(data.status === 'duplicate'){
                    responseSeverity = 'error';
                    responseMessage = `${msg}`;

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                }else if(data.status === 'error'){
                    responseSeverity = 'error';
                    responseMessage = 'Something Went Wrong';
                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                }
            } catch (error) {
                responseSeverity = 'error';
                responseMessage = 'Something Went Wrong';
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
            }finally{
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
            }
        }
    };

     /* on click edit icon */
     const handleEdit = (row) => {
        setProcessing(true);
        
        try {
          setFormData((prevData) => {
            
            const updatedData = {
              ...prevData,
              _id: { ...prevData._id, value: row.original._id },
              departmentName: { ...prevData.departmentName, value: row.original.departmentName }
            };
    
            return updatedData;
          });
    
          setIsSubmitBtn(false);
        } catch (error) {
          responseSeverity = 'error';
          responseMessage = 'Error while click on edit icon, please contact admin' ;
    
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setProcessing(false);
          setOpen(true);
        }finally{
          setProcessing(false);
        }
      }

    /* on click toggle icon */
    const toggleIsActive = async(row) => {
        let responseSeverity = '';
        let responseMessage = '';
        let msg = '';
        setProcessing(true);
        let _id = row.original._id;
        const departmentName = row.original.departmentName;
        let loginId = userDtls.user_ID;
        const currentIsActive = row.original.isActive;

        try {
            if(currentIsActive === 'Y'){
                row.original.isActive = 'N';
                msg = `Selected Department ${departmentName} is In-Active Now`;
            }else {
                row.original.isActive = 'Y';
                msg = `Selected Department ${departmentName} is Active Now`;
            }

            const response = await axios.put(`${BASE_API_URL}/api/department/updateflag/${_id}`, {isActive : row.original.isActive, loginId});

            switch (response.status) {
                case 200:
                    responseSeverity = 'success';
                    responseMessage = `${msg}`;

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
            
                    setOpen(true);
                    handleReload();
                    break;
                case 400:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Invalid data!';

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 404:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Fee Category not found!';

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 500:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Internal server error!';
                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                default:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Unknown error!';
        
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
            }

        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Error while calling toggleIsActive';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
        }finally{
            setProcessing(false);
        }
    }

    const handleFilterSearch = () => {
      fetchAllDepartments();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    /* reload the screen */
    const handleReload = () => {
      setFormData({
        _id:{ value: null },
        departmentName: { value: '', error: false, errorMessage: '' },
        isActive: { value: 'Y' },
        createdBy: { value: null, error: false, errorMessage: '' },
        createdDate: { value: null, error: false, errorMessage: '' },
        lastModifiedBy: { value: null, error: false, errorMessage: '' },
        lastModifiedDate: { value: null, error: false, errorMessage: '' }
      });

      setIsSubmitBtn(true);

      fetchAllDepartments();
    }


    /* column to be export */
    const customColumns = {
        departmentName: 'Department Name'
    };


    /* CSV export all  data */
    const handleExportAllData = async() => {
        setProcessing(true);
        try {
            let data = tableData;

            exportToExcel(data, 'Department.xlsx', customColumns, 'DEPT');

            setProcessing(false);
        } catch (error) {
            console.error('Error while calling handleExportAllData', error);
        }finally{
            setProcessing(false);
        }
    }

    /* CSV export page data */
    const handleExportPageData = (rows) => {
        const rowData = rows.map((row) => row.original);
        exportToExcel(rowData, 'Department.xlsx', customColumns, 'DEPT');
        setProcessing(false);
    }

    return (
        <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
                <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'white'}}>
                    <CardContent>
                        <form onSubmit={handleSubmit} autoComplete='off'>
                            <Grid container spacing={3}>
                                <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <StepriseTextField
                                        label="Department Name"
                                        name="departmentName"
                                        size='small'
                                        value={formData.departmentName.value}
                                        onChange={handleChange}
                                        required
                                        error={formData.departmentName.error}
                                        helperText={formData.departmentName.error ? formData.departmentName.errorMessage : ''}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ style: { color: 'black' } }}
                                        sx={{
                                            mt: 0,
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                borderColor: 'black',
                                                },
                                                '&:hover fieldset': {
                                                borderColor: 'black',
                                                },
                                                '&.Mui-focused fieldset': {
                                                borderColor: 'black',
                                                },
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    {isSubmitBtn ? (
                                        <Button type="submit" fullWidth variant="contained" color="secondary" startIcon={<SaveIcon />}
                                            sx={{
                                                backgroundColor: 'black',
                                                '&:hover': {
                                                backgroundColor: 'black',
                                                },
                                            }}
                                        >
                                            Submit
                                        </Button>
                                        ) : (
                                        <Button  type="submit" variant="contained" fullWidth color="secondary"  startIcon={<EditNoteIcon />}
                                            sx={{
                                                backgroundColor: 'black',
                                                '&:hover': {
                                                backgroundColor: 'black',
                                                },
                                            }}
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Button onClick={handleReload} variant="contained" fullWidth color="secondary" type="button" startIcon={<RefreshIcon />}
                                        sx={{
                                            backgroundColor: 'black',
                                            '&:hover': {
                                              backgroundColor: 'black',
                                            },
                                        }}
                                    >
                                        Reload
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <ProcessingDialog open={processing} />
                    </CardContent>
                </Card>

                <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={11} sm={11} md={11} lg={11}>
                                <StepriseTextField
                                    label="Type department name for like search"
                                    name="departmentName"
                                    size='small'
                                    value={filterData.departmentName.value}
                                    onChange={handleFilterChange}
                                    required
                                    error={filterData.departmentName.error}
                                    helperText={filterData.departmentName.error ? filterData.departmentName.errorMessage : ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ style: { color: 'black' } }}
                                    sx={{
                                        mt: 0,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                            borderColor: 'black',
                                            },
                                            '&:hover fieldset': {
                                            borderColor: 'black',
                                            },
                                            '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                            },
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                <Button variant="contained" size='large' onClick={handleFilterSearch} fullWidth type="button"
                                  startIcon={<SearchIcon />}
                                  sx={{
                                    backgroundColor: 'black',
                                    '&:hover': {
                                    backgroundColor: 'black',
                                    },
                                  }}
                                >
                                
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Grid container spacing={3} sx={{mb:4}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                            exportAllData={handleExportAllData}
                            handleExportSelectedRows={handleExportPageData}
                            handleExportRows={handleExportPageData}
                            data={tableData}
                            headerNames={deptHeaders}
                            editAction={handleEdit}
                            deleteAction={false}
                            editTooltipTitle="Edit"
                            deleteTooltipTitle="Delete"
                            editIcon={EditNoteIcon}
                            deleteIcon={EditIcon}
                            isActive={toggleIsActive}
                            isActiveTooltipTitle="Toggle Active"
                            showCopyIcon={false}
                            showEditIcon={true}
                            showToggleIcon={true}
                            showDeleteIcon={false} 
                            showActionsColumn={true} 
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
