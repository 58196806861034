import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import bgimg from "./bg/login_logo.png";
import loginTopIcon from "./bg/login_top_icon.png";
import { useState } from 'react';
import StepriseTextField from '../Components/StepriseTextField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import CustomSnackbar from '../Components/CustomSnackbar';
import clientLogo from './bg/login_client_logo.png'

const defaultTheme = createTheme();

export default function EmpLogin() {
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  
  const [loginData, setLoginData] = useState({
    empId: {
      value: '',
      error: false,
      errorMessage: 'You must enter EMP Code',
      required: true,
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'You must enter Password',
      required: true,
    },
  });

  /* handle change function */
  const handleChange = (e) => {
    const { name, value } = e.target;
    const { required } = loginData[name];
  
    let error = false;
    let errorMessage = '';
    
    if (required && /^\s*$/.test(value)) {
      error = true;
      errorMessage = 'This field is required';
    }
    
    setLoginData(prevData => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value,
        error,
        errorMessage,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let responseMessage = '';
    let responseSeverity = '';

    if (!loginData.empId.value.trim() || !loginData.password.value.trim()) {
      setIsValid(false);
      return;
    }

    const { value: empIdValue } = loginData.empId;
    const { value: passwordValue } = loginData.password;
    
    try {
      const response = await axios.post(
        `${BASE_API_URL}/api/emp/login`, 
        { empId: empIdValue, password: passwordValue },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      );

      const { status, message, token } = response.data;

      if (status === 'success') {
        localStorage.setItem('token', token);
        const userInfo = jwtDecode(token);
        navigate('/home');
      } else {
        responseSeverity = 'error';
        responseMessage = `${message}`;

        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        responseSeverity = 'error';
        responseMessage = 'Too many requests. Please try again later.';
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
      } else {
        responseSeverity = 'error';
        responseMessage = 'An unexpected error occurred. Please try again.';
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
      }
    }
  };

  /** for msg cancel */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />

        <Grid item xs={false} sm={9} md={9} lg={9} xl={9}
          sx={{
            backgroundColor: 'white !important',
            backgroundImage: `url(${bgimg})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
        </Grid>
        
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3} elevation={6} square="true" sx={{borderLeft: '1.5px solid black'}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={loginTopIcon}
              alt="Logo"
              sx={{
                width: 80,
              }}
            />

            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              LEGAL SYSTEM
            </Typography>
            
            <Typography variant="body2" gutterBottom sx={{color: 'gray'}}>
              A unit of Steprise Technology
            </Typography>

            <Typography component="h1" variant="h4" sx={{mt: 5, fontWeight: 'bold'}}>
              LOGIN
            </Typography>
            <Typography variant="body2" gutterBottom sx={{color: 'gray'}}>
              Sign in to continue
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 5 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <StepriseTextField
                    size='small'
                    label="Employee ID"
                    name="empId"
                    fullWidth
                    required
                    value={loginData.empId.value}
                    onChange={handleChange}
                    error={loginData.empId.error}
                    helperText={loginData.empId.error && loginData.empId.errorMessage}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{mt:2}}>
                  <StepriseTextField
                    type="password"
                    size='small'
                    label="Password"
                    name="password"
                    fullWidth
                    required
                    value={loginData.password.value}
                    onChange={handleChange}
                    error={loginData.password.error}
                    helperText={loginData.password.error && loginData.password.errorMessage}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{mt:2}}>
                  <Button size='large' type="submit" fullWidth variant="contained" 
                    sx={{ 
                      mt: 3, 
                      mb: 2,
                      backgroundColor: 'black', 
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }} 
                    >
                    Log in
                  </Button>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
                  <Typography component="h1" variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Version 24.08.11
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center'}}>
                  <img src={clientLogo} alt="RSA" style={{ width: '5vw', height: 'auto'}} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
