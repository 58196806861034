import React from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import SideBar from './Components/SideBar';
import { Box } from '@mui/material';
import EmpLogin from './Pages/EmpLogin'
import Dashboard from './Pages/Dashboard';
import ClientManagment from './Pages/ClientManagment';
import FileManagment from './Pages/FileManagment';
import TaskManagment from './Pages/TaskManagment';
import WorkManagment from './Pages/WorkManagment';

import Role from './Pages/Role';
import RoleMenuMap from './Pages/RoleMenuMap';
import Employee from './Pages/Employee';
import Section from './Pages/Section';
import Dwr from './Pages/Dwr';
import Profile from './Pages/Profile'
import Rack from './Pages/Rack';
import FeeCategory from './Pages/FeeCategory';
import Court from './Pages/Court';
import Department from './Pages/Department';

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  return token ? true : false;
};

const AppContent = () => {
  const location = useLocation();

  const pathsWithoutSidebar = ['/', '/register', '/reset-password'];

  const shouldShowSidebar = !pathsWithoutSidebar.includes(location.pathname);

  return (
    <Box sx={{ display: 'flex' }}>
      {shouldShowSidebar && isAuthenticated() ? <SideBar /> : null}

      <Routes>
        {/* Dashboard activity */}
        <Route path="/" element={<EmpLogin />} />
        <Route path="/home" element={<Dashboard />} />

        {/* legal activity */}
        <Route path="/client" element={<ClientManagment />} />
        <Route path="/filemanagment" element={<FileManagment />} />
        <Route path="/taskmanagment" element={<TaskManagment />} />
        <Route path="/workmanagment" element={<WorkManagment />} />
        <Route path="/dwr" element={<Dwr />} />
        
        {/* HR activity */}
        <Route path="/role" element={<Role />} />
        <Route path="/rolemenumap" element={<RoleMenuMap />} />
        <Route path="/section" element={<Section />} />
        <Route path="/employee" element={<Employee />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/rack" element={<Rack />} />
        <Route path="/feecat" element={<FeeCategory />} />
        <Route path="/court" element={<Court />} />
        <Route path="/department" element={<Department />} />
      </Routes>
    </Box>
  );
};

export default function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}
