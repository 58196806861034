import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import CustomSnackbar from '../Components/CustomSnackbar';
import EditIcon from '@mui/icons-material/Edit';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import PasswordIcon from '@mui/icons-material/Password';
import StepriseTextField from '../Components/StepriseTextField';

export default function Profile() {
  const [userDtls, setUserDtls] = useState([]);
  const [open, setOpen] = useState(false);
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  const [profile, setProfile] = useState({
    _id: { value: ''},
    fullName: { value: '', error: false, errorMessage: '' },
    email: { value: '', error: false, errorMessage: '' },
    password: { value: '', error: false, errorMessage: '' },
    newPassword: { value: '', error: false, errorMessage: '' },
    confirmPassword: { value: '', error: false, errorMessage: '' }
  });

  /* call on page load */
  useEffect(() => {
    const token = localStorage.getItem('token');
    const dcrptData = jwtDecode(token);
    setUserDtls(dcrptData);
  }, []);

  useEffect(() => {
    if(userDtls.user_ID){
      getEmpById();
    }

  }, [userDtls]);

  /* input handle change */
  const handleChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setProfile((prevData) => ({
        ...prevData,
        [name]: { ...prevData[name], value, error, errorMessage}
    }));
  };

  /* get emp by id */
  const getEmpById = async () => {
    let loginId = userDtls.user_ID;

    try {
      const response = await axios.get(`${BASE_API_URL}/api/emp/getempbyid/${loginId}`);

      const empData = response.data;

      setProfile({
        _id: { value: empData._id || ''},
        fullName: { value: empData.fullName || '', error: false, errorMessage: '' },
        email: { value: empData.email || '', error: false, errorMessage: '' },
        password: { value: '', error: false, errorMessage: '' },
        newPassword: { value: '', error: false, errorMessage: '' },
        confirmPassword: { value: '', error: false, errorMessage: '' }
      });
    } catch (error) {
      console.error("Error while calling getEmpById ", error);
    }
  }

  /* on click submit button */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    let responseSeverity = '';
    let responseMessage = '';

    const newPassword = profile.newPassword.value;
    const confirmPassword = profile.confirmPassword.value;

    let finalDataToSend = {
      _id: profile._id.value,
      newPassword: profile.newPassword.value,
      confirmPassword: profile.confirmPassword.value
    }

    try {

      if(newPassword && confirmPassword){
        if(newPassword === confirmPassword){
          const response = await axios.put(`${BASE_API_URL}/api/emp/changepassword`, {finalDataToSend});

          let data = response.data;
          let msg = data.message;

          if(data.status == 'success'){
            setProcessing(false);
            responseSeverity = 'success';
            responseMessage = `${msg}`;

            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            handleReload();
          }
        }else{
          setProcessing(false);
          responseSeverity = 'error';
          responseMessage = 'Password not matched';

          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          return;
        }
      }else{
        setProcessing(false);
        responseSeverity = 'error';
        responseMessage = 'Fields can not be empty';

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
        return;
      }
    } catch (error) {
      setProcessing(false);
      console.error("Error while calling handleSubmit ", error);
    }finally{
      setProcessing(false);
    }
  }

  /* refresh the page */
  const handleReload = () => {
    setProfile({
      _id: { value: ''},
      fullName: { value: '', error: false, errorMessage: '' },
      email: { value: '', error: false, errorMessage: '' },
      password: { value: '', error: false, errorMessage: '' },
      newPassword: { value: '', error: false, errorMessage: '' },
      confirmPassword: { value: '', error: false, errorMessage: '' }
    });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
          <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'white'}}>
            <CardContent>
              <form onSubmit={handleSubmit} autoComplete='off'>
                <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StepriseTextField
                      disabled
                      label="User Name"
                      name="fullName"
                      size='small'
                      value={profile.fullName.value}
                      onChange={handleChange}
                      required
                      error={profile.fullName.error}
                      helperText={profile.fullName.error ? profile.fullName.errorMessage : ''}
                      fullWidth
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StepriseTextField
                      disabled
                      label="Email"
                      name="email"
                      size='small'
                      value={profile.email.value}
                      onChange={handleChange}
                      required
                      error={profile.email.error}
                      helperText={profile.email.error ? profile.email.errorMessage : ''}
                      fullWidth
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StepriseTextField
                      type="password"
                      label="New Password"
                      name="newPassword"
                      size='small'
                      value={profile.newPassword.value}
                      onChange={handleChange}
                      required
                      error={profile.newPassword.error}
                      helperText={profile.newPassword.error ? profile.newPassword.errorMessage : ''}
                      fullWidth
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StepriseTextField
                      type="password"
                      label="Confirm Password"
                      name="confirmPassword"
                      size='small'
                      value={profile.confirmPassword.value}
                      onChange={handleChange}
                      required
                      error={profile.confirmPassword.error}
                      helperText={profile.confirmPassword.error ? profile.confirmPassword.errorMessage : ''}
                      fullWidth
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button  type="submit" fullWidth variant="contained" startIcon={<PasswordIcon />}
                      sx={{
                          backgroundColor: 'black',
                          '&:hover': {
                          backgroundColor: 'black',
                          },
                      }}
                    >
                      Change Password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
      </Box>
    </Container>
  )
}
