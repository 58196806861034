import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import CustomSnackbar from '../Components/CustomSnackbar';
import { Autocomplete, Button, Card, CardContent, CardHeader, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import ProcessingDialog from '../Components/ProcessingDialog';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ResponsiveTable from '../Components/ResponsiveTable';

export default function RoleMenuMap() {
    const [tableData, setTableData] = useState([]);
    const [isSubmitBtn, setIsSubmitBtn] = useState(true);
    const [responseSeverity, setResponseSeverity] = React.useState('');
    const [responseMessage, setResponseMessage] = React.useState('');
    const [processing, setProcessing] = useState(false);
    const [open, setOpen] = useState(false);
    const [roleInputValue, setRoleInputValue] = useState('');
    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

    /* initialize the form variable  */
    const [roleMenuMap, setRoleMenuMap] = useState({
        roleMenuMapCode: { value: '',  error: false,   errorMessage: '' },
        role: {  value: '',  error: false,   errorMessage: 'You must select role'  },
        menus: { value: [],  error: false,   errorMessage: 'You must select menu'  },
        isActive: { value: 'Y', error: false },
        createdBy: {  value: '', error: false  },
        createdDate: {  value: null, error: false  },
        lastModifiedBy: { value: '', error: false },
        lastModifiedDate: { value: null, error: false  }
    });

    const [roles, setRoles] = useState([]);
    const [selectedMenus, setSelectedMenus] = useState([]);
    const [menus, setMenus] = useState([]);

    /* table header */
    const headerNames = [
        { accessorKey: 'role.roleCode', name: 'Role Code' },
        { accessorKey: 'role.roleName', name: 'Role Name' },
    ];

    /* when page loading this block gets executed */
    useEffect(() => {
        fetchAllMenu();
        fetchRoleMenuMapDat();
    }, []);

    /* fetch all menu */
    const fetchAllMenu = async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}/api/menu/getallmenu`);
            setMenus(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchRoleMenuMapDat = () => {
        axios.get(`${BASE_API_URL}/api/rolemenumap/getallrolemenumap`)
      .then(response => {
        const { data } = response.data;
        setTableData(response.data);
      })
      .catch(error => {
        console.error('Error fetching table data:', error);
      });
    }

    const handleSelection = (event, newValue) => {
        setSelectedMenus(newValue);
    };
    
    /** for msg cancel */
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    /* Requied for role input change */
    const handleRoleInputChange = (event, value) => {
        setRoleInputValue(value);
        setRoleMenuMap(prevRoleMenuMap => ({
            ...prevRoleMenuMap,
            role: {
                ...prevRoleMenuMap.role,
                value: value,
                error: !value,
                errorMessage: !value ? 'You must enter a role' : ''
            }
        }));
        if (value) {
            fetchRoles(value);
        } else {
            setRoles([]);
        }
    };

    /* Requied for role autocomplete */
    const handleRoleAutocompleteChange = (event, selectedRole) => {
        const selectedData = roles.find(role => role.roleName === selectedRole);
        
        if (selectedData) {
            setRoleMenuMap(prevRoleMenuMap => ({
                ...prevRoleMenuMap,
                role: {
                    ...prevRoleMenuMap.role,
                    value: selectedData._id,
                    error: false
                }
            }));
        } else {
            console.error('Selected Role data not found in Roles');
        }
    };

    /* fetchRoles call when type Role */
    const fetchRoles = async (value) => {
        try {
            const roleResponse = await axios.get(`${BASE_API_URL}/api/role/getrolesbyprifix/${value}`);
            setRoles(roleResponse.data);
        } catch (error) {
            console.error('Error fetching Roles data:', error);
        }
    };

    /* active inactive menu */
    const isMenuActive = async (row) => {
        setProcessing(true);
        let responseSeverity = '';
        let responseMessage = '';
        let msg = '';
        const _id = row.original._id;
        const currentIsActive = row.original.isActive;

        try {
            if(currentIsActive === 'Y'){
                row.original.isActive = 'N';
                msg = 'Selected Unit is In-Active Now';
            }else {
                row.original.isActive = 'Y';
                msg = 'Selected Unit is Active Now';
            }

            if(_id){
                const response = await axios.put(`${BASE_API_URL}/api/rolemenumap/updateIsActive/${_id}`, {isActive : row.original.isActive});

                switch (response.status) {
                case 200:
                    responseSeverity = 'success';
                    responseMessage = `${msg}`;

                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);

                    setOpen(true);
                    handleReload();
                    break;
                case 400:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Invalid data!';

                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 404:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Vendor not found!';

                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 500:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Internal server error!';

                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                default:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Unknown error!';

                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                }
            }else{
                responseSeverity = 'error';
                responseMessage = 'Update failed: Something went wrong while calling update function!';

                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
            }
        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Update failed: Something Went Wrong !';

            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            console.error("Error:", error);
        }
    }

    /* onclick edit icon */
    const handleEdit = (row) => {
        setRoleMenuMap(prevRoleMenuMap => ({
            ...prevRoleMenuMap,
            roleMenuMapCode: {
                value: row.original.roleMenuMapCode, // Assuming row._id is the unique identifier
                error: false,
                errorMessage: ''
            },
            role: {
                value: row.original.role._id, // Assuming row.role._id is the role ID
                error: false,
                errorMessage: 'You must select role'
            },
            isActive: {
                value: row.original.isActive, // Assuming row.role._id is the role ID
                error: false,
                errorMessage: ''
            }
        }));
        setRoleInputValue(row.original.role.roleName);
        setSelectedMenus(row.original.menus);
        setIsSubmitBtn(false);

    }
    

    /* onclick delete icon */
    const handleDelete = (row) => {
        try {
            
        } catch (error) {
            
        }
    }

    /* reload the form data */
    const handleReload = () => {
        setProcessing(true);
        try {
            setRoleMenuMap({
                roleMenuMapCode: {
                    value: '', 
                    error: false, 
                    errorMessage: ''
                },
                role: {
                    value: '', 
                    error: false, 
                    errorMessage: 'You must select role'
                },
                menus: {
                    value: [], 
                    error: false, 
                    errorMessage: 'You must select menu'
                },
                isActive:{
                    value:'Y',
                    error:false
                },
                createdBy:{
                    value:'',
                    error:false
                },
                createdDate:{
                    value: null,
                    error:false
                },
                lastModifiedBy:{
                    value:'',
                    error:false
                },
                lastModifiedDate:{
                    value:null,
                    error:false
                }
            });
            
            setRoleInputValue('');
            setRoles([]);
            setSelectedMenus([])
            fetchAllMenu();
            fetchRoleMenuMapDat();
            setIsSubmitBtn(true);
            setProcessing(false);
        } catch (error) {
            console.error(error);
        }
    }

    /* on click submit button */
    const handleSubmit = async (e) => {
        e.preventDefault();

        let responseSeverity = '';
        let responseMessage = '';
        let isReady = true;

        setProcessing(true);

        if(roleMenuMap.role.value == null || roleMenuMap.role.value === undefined || roleMenuMap.role.value === '' ){
            responseSeverity = 'error';
            responseMessage = 'Please select Role';
            isReady = false;

            if(!isReady) {
                setProcessing(false)
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
                return;
            }
        }
        if(selectedMenus.length === 0){
            setProcessing(false)
            responseSeverity = 'error';
            responseMessage = 'Please select Menu';
            isReady = false;

            if(!isReady) {
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
                return;
            }
        }

        if(isReady){
            try {
                const roleMenuMapData = {
                    role: roleMenuMap.role.value,
                    menus: selectedMenus,
                    isActive: roleMenuMap.isActive
                };
    
                const response = await axios.post(`${BASE_API_URL}/api/rolemenumap/register`, roleMenuMapData);
    
                switch (response.status) {
                    case 201:
                      responseSeverity = 'success';
                      responseMessage = 'New Role Menu Mapped Successfully';

                      handleReload();
                      break;
                    case 400:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Invalid data!';
                      setOpen(false);
                      break;
                    case 404:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Vendor not found!';
                      setOpen(false);
                      break;
                    case 500:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Internal server error!';
                      setOpen(false);
                      break;
                    default:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Unknown error!';
                      setOpen(false);
                    break;
                }
    
            } catch (error) {
                responseSeverity = 'error';
                responseMessage = 'Something Went Wrong';
    
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(false);
                console.error("Error:", error);
            }

            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            setProcessing(false);
        }
    }

    /* on click update button */
    const handleUpdate = async (e) => {
        e.preventDefault();
        setProcessing(true);

        let responseSeverity = '';
        let responseMessage = '';

        const dataToSend = {
            roleMenuMapCode: roleMenuMap.roleMenuMapCode.value,
            role: roleMenuMap.role.value,
            menus: selectedMenus,
            isActive: roleMenuMap.isActive.value
        };

        try {
            const roleMenuMapCode = dataToSend.roleMenuMapCode;

            if(roleMenuMapCode){
                const response = await axios.put(`${BASE_API_URL}/api/rolemenumap/updatebyrolemenumapcode/${roleMenuMapCode}`, dataToSend);

                switch (response.status) {
                    case 200:
                      responseSeverity = 'success';
                      responseMessage = 'Role menu map Updated Successfully';
          
                      setResponseSeverity(responseSeverity);
                      setResponseMessage(responseMessage);
          
                      setOpen(true);
                      handleReload();
                      break;
                    case 400:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Invalid data!';
          
                      setResponseSeverity(responseSeverity);
                      setResponseMessage(responseMessage);
                      setOpen(true);
                      break;
                    case 404:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Unit not found!';
          
                      setResponseSeverity(responseSeverity);
                      setResponseMessage(responseMessage);
                      setOpen(true);
                      break;
                    case 500:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Internal server error!';
          
                      setResponseSeverity(responseSeverity);
                      setResponseMessage(responseMessage);
                      setOpen(true);
                      break;
                    default:
                      responseSeverity = 'error';
                      responseMessage = 'Update failed: Unknown error!';
          
                      setResponseSeverity(responseSeverity);
                      setResponseMessage(responseMessage);
                      setOpen(true);
                      break;
                }
            }else{
                setOpen(true);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    return (
        <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
                <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'whites'}}>
                    <CardContent>
                        <form onSubmit={isSubmitBtn ? handleSubmit : handleUpdate}>
                            <Grid container spacing={3}>
                                <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage}/>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Autocomplete
                                        size='small'
                                        freeSolo
                                        inputValue={roleInputValue}
                                        onInputChange={handleRoleInputChange}
                                        onChange={(event, newValue) => handleRoleAutocompleteChange(event, newValue)}
                                        options={roles.map((role) => role.roleName)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Role" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Autocomplete
                                        multiple
                                        size='small'
                                        id="multi-selection-autocomplete"
                                        required
                                        options={menus}
                                        value={selectedMenus}
                                        onChange={handleSelection}
                                        getOptionLabel={(option) => option.menuName}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select Multiple Menu"
                                            />
                                        )}
                                    />

                                    <ProcessingDialog open={processing} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    {isSubmitBtn ? (
                                        <Button  type="submit" fullWidth variant="contained" color="secondary" startIcon={<SaveIcon />}
                                            sx={{
                                                backgroundColor: 'black',
                                                '&:hover': {
                                                backgroundColor: 'black',
                                                },
                                            }}
                                        >
                                            Submit
                                        </Button>
                                        ) : (
                                        <Button  type="submit" variant="contained" fullWidth color="secondary"  startIcon={<EditNoteIcon />}
                                            sx={{
                                                backgroundColor: 'black',
                                                '&:hover': {
                                                backgroundColor: 'black',
                                                },
                                            }}
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Button type="button" onClick={handleReload} variant="contained" fullWidth color="secondary" startIcon={<RefreshIcon />}
                                        sx={{
                                            backgroundColor: 'black',
                                            '&:hover': {
                                              backgroundColor: 'black',
                                            },
                                        }}
                                    >
                                        Reload
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                
                <Grid container spacing={3} sx={{mb:4}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {/* Table content */}
                        <ResponsiveTable
                            data={tableData}
                            headerNames={headerNames}
                            editAction={handleEdit}
                            deleteAction={handleDelete}
                            editTooltipTitle="EDIT"
                            deleteTooltipTitle="DELETE"
                            editIcon={EditNoteIcon}
                            isActive={isMenuActive}
                            showCopyIcon={false}
                            showEditIcon={true}
                            showToggleIcon={true}
                            showDeleteIcon={false} 
                            showActionsColumn={true} 
                            sx={{
                            '& .export-selected-rows-button': {
                                color: '#9c27b0'
                            },
                            
                            }}
                        />
                        {/* End of table content */} 
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
