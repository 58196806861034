import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import StepriseTextField from '../Components/StepriseTextField';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import CustomSnackbar from '../Components/CustomSnackbar';
import ResponsiveTable from '../Components/ResponsiveTable';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ProcessingDialog from '../Components/ProcessingDialog';



export default function Section() {
    const [validToken, setValidToken] = useState('')
    const [userDtls, setUserDtls] = useState([]);
    const [isSubmitBtn, setIsSubmitBtn] = useState(true);
    const [open, setOpen] = useState(false);
    const [responseSeverity, setResponseSeverity] = React.useState('');
    const [responseMessage, setResponseMessage] = React.useState('');
    const [tableData, setTableData] = useState([]);
    const [processing, setProcessing] = useState(false);
    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

    const [sectionData, setSectionData] = useState({
        _id:{ value: null },
        sectionCode: { value: '', error: false, errorMessage: '' },
        sectionName: { value: '', error: false, errorMessage: '' },
        isActive: { value: 'Y' },
        createdBy: { value: null, error: false, errorMessage: '' },
        createdDate: { value: null, error: false, errorMessage: '' },
        lastModifiedBy: { value: null, error: false, errorMessage: '' },
        lastModifiedDate: { value: null, error: false, errorMessage: '' }
    });

    /* header names for the table columns */ 
    const headerNames = [
        { accessorKey: 'sectionCode', name: 'Section Code', size: 550},
        { accessorKey: 'sectionName', name: 'Section Name', size: 550}
    ];

    /* call on page load */
    useEffect(() => {
        const token = localStorage.getItem('token');
        setValidToken(token);
        const dcrptData = jwtDecode(token);
        setUserDtls(dcrptData);
        fetchAllSections();
    }, []);

    /* fetch all roles */
    const fetchAllSections = async () => {
        let responseSeverity = '';
        let responseMessage = '';
        const tokenToSend = localStorage.getItem('token');
        
        try {
            const response = await axios.get(`${BASE_API_URL}/api/section/getallsections`, {
                headers: {
                    Authorization: `${tokenToSend}`
                }
            });

            setTableData(response.data);
        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Something Went Wrong while calling fetchAllSections';

            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
        }
    }

    /* input handle change */
    const handleChange = (event) => {
        const { name, value } = event.target;
        let error = false;
        let errorMessage = '';
    
        setSectionData((prevData) => ({
            ...prevData,
            [name]: { ...prevData[name], value, error, errorMessage}
        }));
    };

    /* on click submit button */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        let responseSeverity = '';
        let responseMessage = '';

        let valid = true;

        if (!sectionData.sectionName.value) {
            setSectionData((prevSection) => ({
                ...prevSection,
                sectionName: { ...prevSection.sectionName, error: true },
            }));
            valid = false;
            } else {
            setSectionData((prevSection) => ({
                ...prevSection,
                sectionName: { ...prevSection.sectionName, error: false },
            }));
        }

        if (!valid) {
            responseSeverity = 'error';
            responseMessage = 'Please select at least one Branch';

            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            return;
        } else {
            const finalSectionData = {
                sectionName: sectionData.sectionName.value,
                sectionCode: sectionData.sectionCode.value ? sectionData.sectionCode.value : '',
                isActive: sectionData.isActive,
                createdBy: userDtls.user_ID,
                lastModifiedBy: userDtls.user_ID
            };
            
            try {
                const response = await axios.post(`${BASE_API_URL}/api/section/create`, {finalSectionData, loginId: userDtls.user_ID}, {
                    headers: {
                        Authorization: `${validToken}`
                    }
                });

                switch (response.status) {  
                    case 201:
                        responseSeverity = 'success';
                        responseMessage = 'New Section Created Successfully';

                        handleReload();
                        break;
                    case 400:
                        responseSeverity = 'error';
                        responseMessage = 'Create failed: Invalid data!';
                        break;
                    case 404:
                        responseSeverity = 'error';
                        responseMessage = 'Create failed: Section not found!';
                        break;
                    case 500:
                        responseSeverity = 'error';
                        responseMessage = 'Update failed: Internal server error!';
                        break;
                    case 200:
                        responseSeverity = 'error';
                        responseMessage = 'Update failed: Creation failed: Section name already exist!!';
                        break;
                    default:
                        responseSeverity = 'error';
                        responseMessage = 'Update failed: Unknown error!';
                        break;
                }
            } catch (error) {
                responseSeverity = 'error';
                responseMessage = 'Something Went Wrong';

                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
            }finally{
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
            }
        }
    };

    /* on click edit icon */
    const handleEdit = (row) => {
        setProcessing(true);
        let responseSeverity = '';
        let responseMessage = '';
        let data = row.original;

        try {
            if(data){
                const updatedData = { ...sectionData };

                Object.keys(sectionData).forEach((key) => {
                    let value = row.original[key];
                    
                    if (row.original[key] !== undefined) {
                        updatedData[key] = {
                          ...sectionData[key],
                          value: value
                        };
                    }
                });

                setSectionData(updatedData);
                setIsSubmitBtn(false);
                setProcessing(false);
            }
        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Error while calling handleEdit';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
        }finally{
            setProcessing(false);
        }
    }

    /* on click edit icon */
    const handleUpdate = async (e) => {
        e.preventDefault();
        setProcessing(true);
        let responseSeverity = '';
        let responseMessage = '';

        const finalSectionData = {
            _id: sectionData._id ?.value ?? '',
            sectionName: sectionData.sectionName?.value ?? '',
            sectionCode: sectionData.sectionCode?.value ?? '',
            isActive: sectionData.isActive?.value ?? 'N',
            createdBy: sectionData.createdBy?.value ?? null,
            createdDate: sectionData.createdDate?.value ?? null,
            lastModifiedBy: sectionData.lastModifiedBy?.value ?? null,
            lastModifiedDate: sectionData.lastModifiedDate?.value ?? null
        };

        let _id = finalSectionData._id;
        try {
            const tokenToSend = localStorage.getItem('token');
            const response = await axios.put(`${BASE_API_URL}/api/section/update/${_id}`, finalSectionData, {
                headers: {
                    Authorization: `${tokenToSend}`
                }
            });

            switch (response.status) {
                case 200:
                responseSeverity = 'success';
                responseMessage = 'Section Updated Successfully';

                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                
                setOpen(true);
                handleReload();
                break;
            case 400:
                responseSeverity = 'error';
                responseMessage = 'Update failed: Invalid data!';
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
                break;
            case 404:
                responseSeverity = 'error';
                responseMessage = 'Update failed: Section not found!';
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
                break;
            case 500:
                responseSeverity = 'error';
                responseMessage = 'Update failed: Internal server error!';
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
                break;
            default:
                responseSeverity = 'error';
                responseMessage = 'Update failed: Unknown error!';
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
                break;
            }

        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Error while calling handleUpdate';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
        }finally{
            setProcessing(false);
        }
    }
    
    /* on click toggle icon */
    const toggleIsActive = async(row) => {
        let responseSeverity = '';
        let responseMessage = '';
        let msg = '';
        setProcessing(true);
        let _id = row.original._id;
        const currentIsActive = row.original.isActive;
        const tokenToSend = localStorage.getItem('token');

        try {
            if(currentIsActive === 'Y'){
                row.original.isActive = 'N';
                msg = 'Selected Role is In-Active Now';
            }else {
                row.original.isActive = 'Y';
                msg = 'Selected Role is Active Now';
            }

            const response = await axios.put(`${BASE_API_URL}/api/section/updateflag/${_id}`, {isActive : row.original.isActive}, {
                headers: {
                    Authorization: `${tokenToSend}`
                }
            });

            switch (response.status) {
                case 200:
                    responseSeverity = 'success';
                    responseMessage = `${msg}`;

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
            
                    setOpen(true);
                    handleReload();
                    break;
                case 400:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Invalid data!';

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 404:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Section not found!';

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 500:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Internal server error!';
                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                default:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Unknown error!';
        
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
            }

        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Error while calling toggleIsActive';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
        }finally{
            setProcessing(false);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    /* reset the form */
    const handleReload = () => {
        try {
            setSectionData({
                _id:{ value: null },
                sectionCode: { value: '', error: false, errorMessage: '' },
                sectionName: { value: '', error: false, errorMessage: '' },
                isActive: { value: 'Y' },
                createdBy: { value: null, error: false, errorMessage: '' },
                createdDate: { value: null, error: false, errorMessage: '' },
                lastModifiedBy: { value: null, error: false, errorMessage: '' },
                lastModifiedDate: { value: null, error: false, errorMessage: '' }
            });
            setIsSubmitBtn(true);
            fetchAllSections();
        } catch (error) {
            console.error("Error while calling handleReload", error);
        }
    }


    /* on click delte icon */
    const deleteAction = () => {
        try {
            
        } catch (error) {
            
        }
    }


    return (
        <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
                <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'white'}}>
                    <CardContent>
                        <form onSubmit={isSubmitBtn ? handleSubmit : handleUpdate} autoComplete='off'>
                            <Grid container spacing={3}>
                                <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <StepriseTextField
                                        label="Section Name"
                                        name="sectionName"
                                        size='small'
                                        value={sectionData.sectionName.value}
                                        onChange={handleChange}
                                        required
                                        error={sectionData.sectionName.error}
                                        helperText={sectionData.sectionName.error ? sectionData.sectionName.errorMessage : ''}
                                        fullWidth
                                        margin="normal"
                                        sx={{mt:0}}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    {isSubmitBtn ? (
                                        <Button  type="submit" fullWidth variant="contained" startIcon={<SaveIcon />}
                                            sx={{
                                                backgroundColor: 'black',
                                                '&:hover': {
                                                backgroundColor: 'black',
                                                },
                                            }}
                                        >
                                            Submit
                                        </Button>
                                        ) : (
                                        <Button  type="submit" variant="contained" fullWidth startIcon={<EditNoteIcon />}
                                            sx={{
                                                backgroundColor: 'black',
                                                '&:hover': {
                                                backgroundColor: 'black',
                                                },
                                            }}
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Button variant="contained" fullWidth type="submit" startIcon={<RefreshIcon />}
                                        sx={{
                                            backgroundColor: 'black',
                                            '&:hover': {
                                              backgroundColor: 'black',
                                            },
                                        }}
                                    >
                                        Reload
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <ProcessingDialog open={processing} />
                    </CardContent>
                </Card>

                <Grid container spacing={3} sx={{mb:4}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                            data={tableData}
                            headerNames={headerNames}
                            editAction={handleEdit}
                            deleteAction={deleteAction}
                            editTooltipTitle="Edit"
                            deleteTooltipTitle="Delete"
                            editIcon={EditNoteIcon}
                            deleteIcon={EditIcon}
                            isActive={toggleIsActive}
                            isActiveTooltipTitle="Toggle Active"
                            showCopyIcon={false}
                            showEditIcon={true}
                            showToggleIcon={true}
                            showDeleteIcon={false} 
                            showActionsColumn={true} 
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
