import React from 'react';
import TextField from '@mui/material/TextField';

function StepriseTextField({ 
  color,
  label, 
  value, 
  size,
  onChange, 
  fullWidth = true, 
  variant = "outlined", 
  placeholder = "", 
  helperText = "", 
  error = false,
  disabled = false,
  required = false,
  multiline = false,
  rows = 1,
  inputProps = {},
  InputProps = {},
  InputLabelProps = {},
  ...rest 
}) {
  return (
    <TextField
      color={color}
      label={label}
      value={value}
      size={size} // Default size is "medium"
      onChange={onChange}
      fullWidth={fullWidth}
      variant={variant}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
      disabled={disabled}
      required={required}
      multiline={multiline}
      rows={rows}
      inputProps={inputProps}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      {...rest}
    />
  )
}

export default StepriseTextField;
