import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {jwtDecode} from 'jwt-decode';
import { Button, Card, CardContent, Container, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import axios from 'axios';
import ResponsiveTable from '../Components/ResponsiveTable';
import StepriseAutocompleteBox from '../Components/StepriseAutocompleteBox';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import StepriseTextField from '../Components/StepriseTextField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { exportToCsv, mkConfig } from '../utils/csvUtils';
import exportToExcel from "../utils/exportToExcel";


const initialColumnVisibility = {
  'taskMst.taskName': true,
  'fileNo': true,
  'fileName': true,
  'isBillable': false,
  'court.courtShortName': true,
  'courtNo': true,
  'itemNo': false,
  'startDate': false,
  'startTime': false,
  'endDate': false,
  'endTime': false,
  'advocate.fullName': true,
  'intern.fullName': false,
  'clerk.fullName': false,
  'counsel': false,
  'otherAdvocate.fullName': false,
  'status': true
};

export default function Dashboard() {
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const [statusCount, setStatusCount] = useState([]);
  const [userDtls, setUserDtls] = useState([]);
  const [taskTbl, setTaskTbl] = useState([]);
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  const [fileInputValue, setFileInputValue] = useState('');
  const [files, setFiles] = useState([]);
  const [filterAdvocInputValue, setFilterAdvocInputValue] = useState('');
  const [filterAdvocs, setFilterAdvocs] = useState([]);
  const [taskMstInputValue, setTaskMstInputValue] = useState('');
  const [taskmsts, setTaskmsts] = useState([]);

  const [filterData, setFilterData] = useState({
    status: { value:'Draft', error:false, errorMessage: '' },
    task: { value:null, error:false, errorMessage: '' },
    file: { value:null, error:false, errorMessage: '' },
    advocate: { value:null, error:false, errorMessage: '' }
  });

  const [taskData, setTaskData] = useState({
    _id: { value: ''},
    taskMst: {value:null, error:false, errorMessage: '' },
    advocate: { value: null, error:false, errorMessage: ''},
    clerk: { value: null, error:false, errorMessage: ''},
    counsel: { value:'', error:false, errorMessage: '' },
    otherAdvocate:{ value: null, error:false, errorMessage: '' },
    file: { value: null, error:false, errorMessage: ''},
    fileNo: { value:'', error:false, errorMessage: '' },
    fileName: { value:'', error:false, errorMessage: '' },
    isBillable: {value:'', error:false, errorMessage: ''},
    court:{ value: null, error:false, errorMessage: '' },
    courtNo:{ value: '', error:false, errorMessage: '' },
    itemNo:{ value:'', error:false, errorMessage: '' },
    startDate: { value:null, error:false, errorMessage: '' },
    startTime: { value:null, error:false, errorMessage: '' },
    endDate: { value:null, error:false, errorMessage: '' },
    endTime: { value:null, error:false, errorMessage: '' },
    status: { value:'', error:false, errorMessage: '' },
    isActive: {value:'Y', error:false, errorMessage: ''},
    createdBy:{ value: null, error:false },
    createdDate:{ value: null, error:false },
    lastModifiedBy:{ value: null, error:false },
    lastModifiedDate:{ value:null, error:false },
  });

  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  /* header names for the table columns */ 
  const taskTblHeader = [
    { accessorKey: 'taskMst.taskName', name: 'Task Name'},
    { accessorKey: 'fileNo', name: 'File No'},
    { accessorKey: 'fileName', name: 'File Name'},
    { accessorKey: 'isBillable', name: 'isBillable'},
    { accessorKey: 'court.courtShortName', name: 'Court'},
    { accessorKey: 'courtNo', name: 'Court No'},
    { accessorKey: 'itemNo', name: 'Item No'},
    { accessorKey: 'startDate', name: 'Start Date'},
    { accessorKey: 'startTime', name: 'Start Time'},
    { accessorKey: 'endDate', name: 'End Date'},
    { accessorKey: 'endTime', name: 'End Time'},
    { accessorKey: 'advocate.fullName', name: 'Advocate'},
    { accessorKey: 'intern.fullName', name: 'Intern'},
    { accessorKey: 'clerk.fullName', name: 'Clerk'},
    { accessorKey: 'counsel', name: 'Counsel'},
    { accessorKey: 'otherAdvocate.fullName', name: 'Other Advocate'},
    { accessorKey: 'status', name: 'Status'},
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const dcrptData = jwtDecode(token);
      setUserDtls(dcrptData);
    }
  }, []);

  useEffect(() => {
    if (userDtls.user_ID) {
      let roleName = userDtls.roleName;
      if(roleName === 'Advocate'){
        filterData.status.value = 'Inprogress';
        fetchTaskStatusCount();
        fetchInprogressTask('Inprogress');
      }else{
        fetchTaskStatusCount();
        fetchInprogressTask('Inprogress');
      }
    }
  }, [userDtls]);
 

  /* filter input change */
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setFilterData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error, errorMessage}
    }))
  }

  /* filetr search criteria */
  const handleFilterSearch = (type) => {
    fetchInprogressTask(type);
  }

  /* fetch court call when type court name */
  const fetchFiles= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/file/getfileskbyprifix/${value}`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching  file data:', error);
    }
  };

  /* fetch all task mst*/
  const fetchTaskMstByPrefix = async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/task/gettasksbyprifix/${value}`);
      setTaskmsts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchInprogressTask = async (type) => {
    if(type === 'Draft'){
      filterData.status.value = 'Draft'
    }else if(type === 'Inprogress'){
      filterData.status.value = 'Inprogress'
    }else if(type === 'Elapsed'){
      filterData.status.value = 'Elapsed'
    }else if(type === 'Extended'){
      filterData.status.value = 'Extended'
    }else if(type === 'Completed'){
      filterData.status.value = 'Completed'
    }else if(type === 'Incomplete'){
      filterData.status.value = 'Incomplete'
    }

    setProcessing(true);
    try {
      let status = filterData.status.value || '';
      let task = filterData.task.value || null;
      let file = filterData.file.value || null;
      let advocate = filterData.advocate.value || null;
      let loginId = userDtls.user_ID;
      let roleName = userDtls.roleName;

      const response = await axios.get(`${BASE_API_URL}/api/taskmanagment/getalltask/${status}/${task}/${file}/${advocate}/${roleName}/${loginId}`);

      setTaskTbl(response.data);
    } catch (error) {
      setProcessing(false)
      console.error('Error fetching data:', error);
    }finally{
      setProcessing(false);
    }
  }

  /* get status count of task */
  const fetchTaskStatusCount = async () => {
    let roleName = userDtls.roleName;
    let loginId = userDtls.user_ID;
    
    try {
      const response = await axios.get(`${BASE_API_URL}/api/taskmanagment/getstatuscount/${roleName}/${loginId}`);
      setStatusCount(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  /* fetch employee call when type product */
  const fetchEmp = async (value, roleType) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/emp/getempbyroleandprefix/${value}/${roleType}`);

    if(roleType === 'FADV'){
        setFilterAdvocs(response.data);
      }
    } catch (error) {
      console.error('Error fetching  Emp data:', error);
    }
  };

  const handleTaskMstInputChange = (event, value) => {
    setTaskMstInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      task: {
        ...prevData.task,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 2) {
      fetchTaskMstByPrefix(value);
    } else {
      setTaskmsts([]);
    }
  };

  /* Requied for Master Task autocomplete */
  const handleTaskMstAutocompleteChange = (event, selectedItem) => {
    const selectedData = taskmsts.find(item => item.taskName === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          task: {
            ...prevData.task,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected task data not found');
    }
  };


  /* Requied for file autocomplete */
  const handleFileInputChange = (event, value) => {
    setFileInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      file: {
        ...prevData.file,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 3) {
      fetchFiles(value);
    } else {
      setFiles([]);
    }
  };

  /* Requied for file autocomplete */
  const handleFileAutocompleteChange = (event, selectedItem) => {
    const selectedData = files.find(item => item.fileNo === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          file: {
            ...prevData.file,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected file data not found');
    }
  };

  /* Requied for filter advocate autocomplete */
  const handleFilterAdvocInputChange = (event, value) => {
    setFilterAdvocInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      advocate: {
        ...prevData.advocate,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'FADV');
    } else {
      setFilterAdvocs([]);
    }
  };

  /* Requied for filter advocate autocomplete */
  const handleFilterAdvocAutocompleteChange = (event, selectedItem) => {
    const selectedData = filterAdvocs.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          advocate: {
            ...prevData.advocate,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected filter advocate data not found');
    }
  };

  /* column to be export */
  const customColumns = {
    taskMst: 'Task Name',
    fileNo: 'File No',
    fileName: 'File Name',
    advocate: 'Advocate',
    intern: 'Intern',
    clerk: 'Clerk',
    counsel: 'Counsel',
    otherAdvocate: 'Other Advocate',
    court: 'Court',
    courtNo: 'Court No',
    itemNo: 'Item No',
    isBillable: 'isBillable',
    startDate: 'Start Date',
    startTime: 'Start Time',
    endDate: 'End Date',
    endTime: 'End Time',
    status: 'Status'
  };

  /* export all data */
  const handleExportAllData = () => {
    setProcessing(true);
    try {
      let data = taskTbl;
      exportToExcel(data, 'TASK.xlsx', customColumns, 'TASK');
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      console.error(error);
    }finally{
      setProcessing(false);
    }
  };

  /* export paage data only */
  const handleExportPageData = (rows) => {
    setProcessing(true);
    try {
      const data = rows.map((row) => row.original);

      exportToExcel(data, 'dashbord_status.xlsx', customColumns, 'TASK');

      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      console.error(error);
    }finally{
      setProcessing(false);
    }
  }

  const handleEdit = (row) => {

  }

  return (
    <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
        
        <Grid container spacing={2} sx={{mb:2}}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Button variant='outlined' fullWidth onClick={() => handleFilterSearch('Inprogress')}
              sx={{
                color: 'black',
                borderColor: 'black',
                fontWeight: 'bolder',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)', // Optional: add hover effect
                }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                Today
              </Box>
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Button variant='outlined' fullWidth onClick={() => handleFilterSearch('Inprogress')}
              sx={{
                color: 'black',
                borderColor: 'black',
                fontWeight: 'bolder',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)', // Optional: add hover effect
                }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                Weekly
              </Box>
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Button variant='outlined' fullWidth onClick={() => handleFilterSearch('Inprogress')}
              sx={{
                color: 'black',
                borderColor: 'black',
                fontWeight: 'bolder',
                '&:hover': {
                  borderColor: 'black',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)', // Optional: add hover effect
                }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                Monthly
              </Box>
            </Button>
          </Grid>

          {(userDtls.roleName === 'Super Admin' || userDtls.roleName === 'Admin') ? (
            <>
              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Draft')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Draft</Typography>
                    <Typography>{statusCount.Draft}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Inprogress')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Inprogress</Typography>
                    <Typography>{statusCount.Inprogress}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Elapsed')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Elapsed</Typography>
                    <Typography>{statusCount.Elapsed}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Extended')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Extended</Typography>
                    <Typography>{statusCount.Extended}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Completed')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Completed</Typography>
                    <Typography>{statusCount.Completed}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Incomplete')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Incomplete</Typography>
                    <Typography>{statusCount.Incomplete}</Typography>
                  </Box>
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Draft</Typography>
                    <Typography>0</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Inprogress')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Inprogress</Typography>
                    <Typography>{statusCount.Inprogress}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Elapsed')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Elapsed</Typography>
                    <Typography>{statusCount.Elapsed}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Extended')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Extended</Typography>
                    <Typography>{statusCount.Extended}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Completed')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Completed</Typography>
                    <Typography>{statusCount.Completed}</Typography>
                  </Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2}>
                <Button variant='contained' fullWidth onClick={() => handleFilterSearch('Incomplete')}
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Incomplete</Typography>
                    <Typography>{statusCount.Incomplete}</Typography>
                  </Box>
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <FormControl fullWidth 
                  sx={{
                    mt: 0,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}>
                  <InputLabel>Task Status</InputLabel>
                    <Select size='small' label="Task Status" name='status'
                      value={filterData.status.value}
                      onChange={handleFilterChange}
                      
                    >
                      <MenuItem value='Draft'>Draft</MenuItem>
                      <MenuItem value='Inprogress'>Inprogress</MenuItem>
                      <MenuItem value='Elapsed'>Elapsed</MenuItem>
                      <MenuItem value='Extended'>Extended</MenuItem>
                      <MenuItem value='Completed'>Completed</MenuItem>
                      <MenuItem value='Incomplete'>Incomplete</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={2}>
                <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="task"
                  inputValue={taskMstInputValue}
                  onInputChange={handleTaskMstInputChange}
                  onChange={handleTaskMstAutocompleteChange}
                  options={taskmsts.map((item) => item.taskName)}
                  label="Task Name"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={2}>
                <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="file"
                  inputValue={fileInputValue}
                  onInputChange={handleFileInputChange}
                  onChange={handleFileAutocompleteChange}
                  options={files.map((item) => item.fileNo)}
                  label="File No"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={5}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="advocate"
                    inputValue={filterAdvocInputValue}
                    onInputChange={handleFilterAdvocInputChange}
                    onChange={handleFilterAdvocAutocompleteChange}
                    options={filterAdvocs.map((item) => item.fullName)}
                    label="Assign Advocate"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={1}>
                  <Button variant="contained" size='large' onClick={() => handleFilterSearch('NO')} fullWidth type="button"
                    startIcon={<SearchIcon />}
                    sx={{
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    
                  </Button>
                </Grid>

            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={3} sx={{mb:2}}>
          {(userDtls.roleName === 'Admin' || userDtls.roleName === 'Super Admin') ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                exportAllData={handleExportAllData}
                handleExportSelectedRows={handleExportPageData}
                handleExportRows={handleExportPageData}
                data={taskTbl}
                headerNames={taskTblHeader}
                editAction={false}
                copyAction={false}
                deleteAction={false}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={false}
                copyIcon={false}
                deleteIcon={false}
                isActive={false}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={false}
                showEditIcon={false}
                showToggleIcon={false}
                showDeleteIcon={false} 
                showActionsColumn={false} 
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/*<ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                exportAllData={handleExportAllData}
                exportPageRows={handleExportPageData}
                exportSelectedRows={handleExportSelectedRowsData}
                data={taskTbl}
                headerNames={taskTblHeader}
                editAction={handleEdit}
                copyAction={false}
                deleteAction={false}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={true}
                copyIcon={false}
                deleteIcon={false}
                isActive={false}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={false}
                showEditIcon = {false}
                showToggleIcon={false}
                showDeleteIcon={false} 
                showActionsColumn={true} 
              />*/}

              <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                exportAllData={handleExportAllData}
                handleExportSelectedRows={handleExportPageData}
                handleExportRows={handleExportPageData}
                data={taskTbl}
                headerNames={taskTblHeader}
                editAction={handleEdit}
                copyAction={false}
                deleteAction={false}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={EditNoteIcon}
                copyIcon={false}
                deleteIcon={false}
                isActive={false}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={false}
                showEditIcon={false}
                showToggleIcon={false}
                showDeleteIcon={false}
                showActionsColumn={false} 
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  )
}
