import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const StepriseAutocompleteBox = ({
  size = 'small',
  required = false,
  freeSolo = false,
  inputValue,
  onInputChange,
  onChange,
  options,
  label = 'Select',
  borderColor = 'black',
  inputColor = 'black',
}) => {
  return (
    <>
    <Autocomplete
      size={size}
      required={required}
      freeSolo={freeSolo}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: borderColor,
              },
              '&:hover fieldset': {
                borderColor: borderColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: borderColor,
              },
            },
            '& .MuiInputLabel-root': {
              color: borderColor,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: borderColor,
            },
            '& .MuiInputLabel-root.Mui-focused ~ .MuiOutlinedInput-root fieldset': {
              borderColor: borderColor,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
              color: inputColor,
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-input': {
              color: inputColor,
            },
            '& .MuiOutlinedInput-root:hover .MuiInputLabel-root': {
              color: borderColor,
            },
            color: '#9c27b0',
          }}
        />
      )}
    />

    {/* Table to display autocomplete options */}

    </>
  );
};

export default StepriseAutocompleteBox;
