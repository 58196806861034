import React, { useRef } from 'react';
import { Dialog, DialogContent, CircularProgress} from '@mui/material';
import processing from '../images/processing.gif';

const ProcessingDialog = ({ open }) => {
  return (
    <Dialog open={open} disableEscapeKeyDown disableBackdropClick>
      <DialogContent>
        <img src={processing} alt="Processing" />
      </DialogContent>
    </Dialog>
  );
};

export default ProcessingDialog;

