import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import {jwtDecode} from 'jwt-decode';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomSnackbar from '../Components/CustomSnackbar';
import StepriseTextField from '../Components/StepriseTextField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import ProcessingDialog from '../Components/ProcessingDialog';
import ResponsiveTable from '../Components/ResponsiveTable';
import EditIcon from '@mui/icons-material/Edit';
import StepriseAutocompleteBox from '../Components/StepriseAutocompleteBox';
import axios from 'axios';
import dayjs from 'dayjs'; 
import { formatDate } from '../utils/utils.js';
import { exportToCsv, mkConfig } from '../utils/csvUtils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Papa from 'papaparse';
import {isValidEmail} from '../utils/Validation';
import exportToExcel from "../utils/exportToExcel";


const initialColumnVisibility = {
  'fileDate': false,
  'fileNo': true,
  'fileName': true,
  'caseNo': true,
  'client.clientName': true,
  'client.address': false,
  'client.state': false,
  'client.city': false,
  'client.pinCode': false,
  'contactPersonName': false,
  'contactPersonMobile': false,
  'contactPersonEmail': false,
  'court.courtShortName': true,
  'department.departmentName': false,
  'feeCategory.feeCatName': false,
  'ldh': false,
  'ndh': false,
  'forCourt': false,
  'assignedAdvocate.fullName': true,
  'aor.fullName': true,
  'counsel': false, 
  'rack.rackNo': false,
  'remarks': false,
  'status': false
};

export default function FileManagment() {
  const [touched, setTouched] = useState({
    contactPersonMobile: false,
    contactPersonEmail: false
  });
  const [csvFileList, setCsvFileList] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [copyData, setCopyData] = useState([]);
  const [userDtls, setUserDtls] = useState([]);
  const [fileTbl, setFileTbl] = useState([]);
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const [rackInputValue, setRackInputValue] = useState('');
  const [racks, setRacks] = useState([]);
  const [clientInputValue, setClientInputValue] = useState('');
  const [clients, setClients] = useState([]);

  const [courtInputValue, setCourtInputValue] = useState('');
  const [courts, setCourts] = useState([]);
  const [departmentInputValue, setDepartmentInputValue] = useState('');
  const [departments, setDepartments] = useState([]);
  const [feeCategoryInputValue, setFeeCategoryInputValue] = useState('');
  const [feeCategorys, setFeeCategorys] = useState([]);
  const [assignAdvcInputValue, setAssignAdvcInputValue] = useState('');
  const [assignedAdvcs, setAssignedAdvcs] = useState([]);
  const [aorInputValue, setAorInputValue] = useState('');
  const [aros, setAros] = useState([]);

  const [fileInputValue, setFileInputValue] = useState('');
  const [files, setFiles] = useState([]);
  const [fileNameInputValue, setFileNameInputValue] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [filterClientInputValue, setFilterClientInputValue] = useState('');
  const [filterClients, setFilterClients] = useState([]);


  const [isCsvReady, setIsCsvReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [initialState, setInitialState] = useState({
    columnVisibility: {
      
    }
  });

  const [fileData, setFileData] = useState({
    _id:{ value: ''},
    fileId:{ value:'', error:false, errorMessage: ''},
    fileNo:{ value:'', error:false, errorMessage: '' },
    fileDate:{ value: null, error:false, errorMessage: '' },
    fileName:{ value:'', error:false, errorMessage: '' },
    rack: { value:null, error:false, errorMessage: '' },
    caseNo:{ value:'', error:false, errorMessage: '' },
    client:{ value: null, error:false, errorMessage: '' },
    pinCode:{ value: '', error:false, errorMessage: '' },
    city:{ value: '', error:false, errorMessage: '' },
    state:{ value: '', error:false, errorMessage: '' },
    address:{ value: '', error:false, errorMessage: '' },
    contactPersonName: {value:'', error:false, errorMessage: ''},
    contactPersonMobile: {value:'', error:false, errorMessage: ''},
    contactPersonEmail: {value:'', error:false, errorMessage: ''},
    court:{ value: null, error:false, errorMessage: '' },
    department: { value: null, error:false, errorMessage: '' },
    feeCategory:{ value: null, error:false, errorMessage: '' },
    caseDetails: {value:'', error:false, errorMessage: ''},
    ldh: {value: null, error:false, errorMessage: ''},
    ndh: {value: null, error:false, errorMessage: ''},
    forCourt: {value:'', error:false, errorMessage: ''}, 
    assignedAdvocate: { value: null, error:false, errorMessage: '' },
    aor: { value: null, error:false, errorMessage: '' },
    counsel: {value:'', error:false, errorMessage: ''},
    remarks: {value:'', error:false, errorMessage: ''},
    status: { value:'', error:false, errorMessage: '' },
    isActive: {value:'Y', error:false, errorMessage: ''},
    createdBy:{ value: null, error:false },
    createdDate:{ value: null, error:false },
    lastModifiedBy:{ value: null, error:false },
    lastModifiedDate:{ value:null, error:false },
  });
  

  /* header names for the table columns */ 
  const fileTblHeader = [
    { accessorKey: 'fileDate', name: 'File Date', renderCell: (fileDate) => formatDate(fileDate.value) },
    { accessorKey: 'fileNo', name: 'File No'},
    { accessorKey: 'fileName', name: 'File Name'},
    { accessorKey: 'caseNo', name: 'Case No'},
    { accessorKey: 'client.clientName', name: 'Client Name'},
    { accessorKey: 'client.address', name: 'Client Address'},
    { accessorKey: 'client.city', name: 'City'},
    { accessorKey: 'client.state', name: 'State'},
    { accessorKey: 'client.pinCode', name: 'Pincode'},
    { accessorKey: 'contactPersonName', name: 'Contact Person'},
    { accessorKey: 'contactPersonMobile', name: 'Mobile'},
    { accessorKey: 'contactPersonEmail', name: 'Email'},
    { accessorKey: 'court.courtShortName', name: 'Court'},
    { accessorKey: 'department.departmentName', name: 'Dept.'},
    { accessorKey: 'feeCategory.feeCatName', name: 'Cat.'},
    { accessorKey: 'ldh', name: 'LDH'},
    { accessorKey: 'ndh', name: 'NDH.'},
    { accessorKey: 'forCourt', name: 'For'},
    { accessorKey: 'assignedAdvocate.fullName', name: 'Assign'},
    { accessorKey: 'aor.fullName', name: 'AOR'},
    { accessorKey: 'counsel', name: 'Counsel'},
    { accessorKey: 'rack.rackNo', name: 'Rack No'},
    { accessorKey: 'remarks', name: 'Remarks'},
    { accessorKey: 'status', name: 'File Status'}
  ];

  const [filterData, setFilterData] = useState({
    fileNo: { value: null, error:false, errorMessage: '' },
    fileName: { value: '', error:false, errorMessage: '' },
    client: { value: null, error:false, errorMessage: '' },
  })

  /* page load */
  useEffect(() => {
    const token = localStorage.getItem('token');
    const dcrptData = jwtDecode(token);
    setUserDtls(dcrptData);

    const currentDate = dayjs();

    setFileData(prevState => ({
      ...prevState,
      fileDate: { value: currentDate },
    }));

    fetchFiles();
  }, []);

  /* fetch Files */
  const fetchFiles = async (value) => {
    setProcessing(true);
    let fileNo = filterData.fileNo.value || null;
    let fileName = filterData.fileName.value || null;
    let client = filterData.client.value || null;
    
    try {
      const validToken = localStorage.getItem('token');
      setFileTbl([]);
      const response = await axios.get(`${BASE_API_URL}/api/file/getallfiles/${fileNo}/${fileName}/${client}`, {
        headers: {
          Authorization: `${validToken}`
        }
      });
      setFileTbl(response.data);
      setProcessing(false);
    } catch (error) {
      console.error('Error fetching  fetchFiles:', error);
      setProcessing(false);
    }
  };

  /* input handle change */
  const handleChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setFileData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error, errorMessage}
    }));
  };

  /* handle ReactQuill change */
  const handleQuillChange = (value) => {
    setFileData((prevData) => ({
      ...prevData,
      caseDetails: { ...prevData.caseDetails, value, error: false, errorMessage: '' },
    }));
  };

  /* Requied for rack autocomplete */
  const handleRackInputChange = (event, value) => {
    setRackInputValue(value);
    setFileData(prevData => ({
      ...prevData,
      rack: {
        ...prevData.rack,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a rack no' : ''
      }
    }));
    if (value) {
      fetchRack(value);
    } else {
      setRacks([]);
    }
  };

  /* fetch Racks call when type product */
  const fetchRack = async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/rack/getracskbyprifix/${value}`);
      setRacks(response.data);
    } catch (error) {
      console.error('Error fetching  Rack data:', error);
    }
  };

  /* Requied for rack autocomplete */
  const handleRackAutocompleteChange = (event, selectedItem) => {
    const selectedData = racks.find(item => item.rackNo === selectedItem);
    
    if (selectedData) {
      setFileData(prevData => ({
          ...prevData,
          rack: {
          ...prevData.rack,
          value: selectedData._id,
          error: false
          }
      }));
    } else {
      console.error('Selected Rack data not found in racks');
    }
  };

  /* fetch client call when type client name */
  const fetchClient= async (value, type) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/client/getclientkbyprifix/${value}`);
      if(type === 'IC'){
        setClients(response.data);
      }else if(type === 'FC'){
        setFilterClients(response.data);
      }
    } catch (error) {
      console.error('Error fetching  Rack data:', error);
    }
  };

  /* Requied for client autocomplete */
  const handleClientInputChange = (event, value) => {
    setClientInputValue(value);
    setFileData(prevData => ({
      ...prevData,
      client: {
        ...prevData.client,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a client' : ''
      }
    }));
    if (value.length >= 3) {
      fetchClient(value, "IC");
    } else {
      setClients([]);
    }
  };

  /* Requied for client autocomplete */
  const handleClientAutocompleteChange = (event, selectedItem) => {
    const selectedData = clients.find(item => item.clientName === selectedItem);
    
    if (selectedData) {
      setFileData(prevData => ({
        ...prevData,
        client: {
          ...prevData.client,
          value: selectedData._id,
          error: false
        },
        pinCode: {
          ...prevData.pinCode,
          value: selectedData.pinCode
        },
        city: {
          ...prevData.city,
          value: selectedData.city
        },
        state: {
          ...prevData.state,
          value: selectedData.state
        },
        address: {
          ...prevData.address,
          value: selectedData.address
        },
        contactPersonName: {
          ...prevData.contactPersonName,
          value: selectedData.contactPersonName
        },
        contactPersonMobile: {
          ...prevData.contactPersonMobile,
          value: selectedData.contactPersonMobile
        },
        contactPersonEmail: {
          ...prevData.contactPersonEmail,
          value: selectedData.contactPersonEmail
        },
      }));
    } else {
      console.error('Selected Client data not found in clients array');
    }
  };

  /* Requied for court autocomplete */
  const handleCourtInputChange = (event, value) => {
    setCourtInputValue(value);
    setFileData(prevData => ({
      ...prevData,
      court: {
        ...prevData.court,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a court' : ''
      }
    }));
    if (value.length >= 2) {
      fetchCourt(value);
    } else {
      setCourts([]);
    }
  };

  /* fetch court call when type court name */
  const fetchCourt= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/court/getcourtsbyfullnameprifix/${value}`);
      setCourts(response.data);
    } catch (error) {
      console.error('Error fetching  Court data:', error);
    }
  };

  /* Requied for court autocomplete */
  const handleCourtAutocompleteChange = (event, selectedItem) => {
    const selectedData = courts.find(item => item.courtShortName === selectedItem);
    
    if (selectedData) {
      setFileData(prevData => ({
        ...prevData,
        court: {
          ...prevData.court,
          value: selectedData._id,
          error: false
        },
      }));
    } else {
      console.error('Selected Court data not found in courts array');
    }
  };

  /* Requied for court autocomplete */
  const handleDepartmentInputChange = (event, value) => {
    setDepartmentInputValue(value);
    setFileData(prevData => ({
      ...prevData,
      department: {
        ...prevData.department,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a department' : ''
      }
    }));
    if (value) {
      fetchDepartment(value);
    } else {
      setDepartments([]);
    }
  };

  /* fetch department call when type department name */
  const fetchDepartment= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/department/getdepartmentsbyprifix/${value}`);
      setDepartments(response.data);
    } catch (error) {
      console.error('Error fetching  Department data:', error);
    }
  };

  /* Requied for department autocomplete */
  const handleDepartmentAutocompleteChange = (event, selectedItem) => {
    const selectedData = departments.find(item => item.departmentName === selectedItem);
    
    if (selectedData) {
      setFileData(prevData => ({
        ...prevData,
        department: {
          ...prevData.department,
          value: selectedData._id,
          error: false
        },
      }));
    } else {
      console.error('Selected Deprtment data not found in department array');
    }
  };

  /* Requied for court autocomplete */
  const handleFeeCategoryInputChange = (event, value) => {
    setFeeCategoryInputValue(value);
    setFileData(prevData => ({
      ...prevData,
      feeCategory: {
        ...prevData.feeCategory,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a fee Category' : ''
      }
    }));
    if (value) {
      fetchFeeCategory(value);
    } else {
      setFeeCategorys([]);
    }
  };

  /* fetch fee category call when type fee category name */
  const fetchFeeCategory= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/feecategory/getfeecategorybyprifix/${value}`);
      setFeeCategorys(response.data);
    } catch (error) {
      console.error('Error fetching  Fee category data:', error);
    }
  };

  /* Requied for department autocomplete */
  const handleFeeCategoryAutocompleteChange = (event, selectedItem) => {
    const selectedData = feeCategorys.find(item => item.feeCatName === selectedItem);
    
    if (selectedData) {
      setFileData(prevData => ({
        ...prevData,
        feeCategory: {
          ...prevData.feeCategory,
          value: selectedData._id,
          error: false
        },
      }));
    } else {
      console.error('Selected Fee Category data not found in fee category array');
    }
  };

  /* fetch employee call when type product */
  const fetchEmp = async (value, type) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/emp/getempsbyprifix/${value}`);
      if(type === 'AOR'){
        setAros(response.data);
      }else{
        setAssignedAdvcs(response.data);
      }
    } catch (error) {
      console.error('Error fetching  Emp data:', error);
    }
  };

  /* Requied for employee autocomplete */
  const handleAssignAdvcInputChange = (event, value) => {
    setAssignAdvcInputValue(value);
    setFileData(prevData => ({
      ...prevData,
      assignedAdvocate: {
        ...prevData.assignedAdvocate,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'ADV');
    } else {
      setAssignedAdvcs([]);
    }
  };

  /* Requied for employee autocomplete */
  const handleAssignAdvcAutocompleteChange = (event, selectedItem) => {
    const selectedData = assignedAdvcs.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
        setFileData(prevData => ({
            ...prevData,
            assignedAdvocate: {
              ...prevData.assignedAdvocate,
              value: selectedData._id,
              error: false
            }
        }));
    } else {
      console.error('Selected Assigned advocate data not found');
    }
  };

  /* Requied for employee autocomplete */
  const handleAorInputChange = (event, value) => {
    setAorInputValue(value);
    setFileData(prevData => ({
      ...prevData,
      aor: {
        ...prevData.aor,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'AOR');
    } else {
      setAros([]);
    }
  };

  /* Requied for aor autocomplete */
  const handleAorAutocompleteChange = (event, selectedItem) => {
    const selectedData = aros.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
      setFileData(prevData => ({
        ...prevData,
        aor: {
          ...prevData.aor,
          value: selectedData._id,
          error: false
        }
      }));
    } else {
      console.error('Selected Assigned advocate data not found');
    }
  };


  /** date change handler */
  const handleDateChange = (name, value) => {
    let error = false;
    let errorMessage = '';

    if (name === 'fileDate' || name === 'ldh' || name === 'ndh') {
      if (!value) {
        error = true;
        errorMessage = 'You must enter a valid file date';
      } else if (isFutureDate(value)) {
        error = true;
        errorMessage = 'file Date cannot be a future date';
      }
    }
  
    setFileData((prevData) => ({
      ...prevData,
      [name]: {
        value,
        error,
        errorMessage,
      },
    }));
  };

  /* validating future date */
  const isFutureDate = (date) => {
    return new Date(date) > new Date();
  };

  /* on click submit button */
  const handleSubmit = async(e) => {
    e.preventDefault();
    let responseSeverity = '';
    let responseMessage = '';
    let loginId = userDtls.user_ID;
    setProcessing(true);

    try {
      const validToken = localStorage.getItem('token');

      const finalDataToSend = {
        _id: fileData._id.value,
        fileNo: fileData.fileNo.value,
        fileDate: fileData.fileDate.value,
        fileName:fileData.fileName.value,
        rack: fileData.rack.value,
        caseNo: fileData.caseNo.value,
        client: fileData.client.value,
        pinCode: fileData.pinCode.value,
        city: fileData.city.value,
        state: fileData.state.value,
        address: fileData.address.value,
        contactPersonName: fileData.contactPersonName.value,
        contactPersonMobile: fileData.contactPersonMobile.value,
        contactPersonEmail: fileData.contactPersonEmail.value,
        court: fileData.court.value,
        department: fileData.department.value,
        feeCategory:fileData.feeCategory.value,
        caseDetails: fileData.caseDetails.value,
        ldh: fileData.ldh.value,
        ndh: fileData.ndh.value,
        forCourt: fileData.forCourt.value, 
        assignedAdvocate: fileData.assignedAdvocate.value,
        aor: fileData.aor.value,
        counsel: fileData.counsel.value,
        remarks: fileData.remarks.value,
        status: "Waiting For Assignee",
        isActive: fileData.isActive.value,
        createdBy: loginId,
        createdDate: null,
        lastModifiedBy: loginId,
        lastModifiedDate: null
      }

      const response = await axios.post(`${BASE_API_URL}/api/file/create`, finalDataToSend, {
        headers: {
          Authorization: `${validToken}`
        }
      });

      let data = response.data;
      let msg = data.message;

      if(data.status === 'success'){
        responseSeverity = 'success';
        responseMessage = `${msg}` ;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);

        handleReload();
      }else if(data.status === 'duplicate'){
        responseSeverity = 'error';
        responseMessage = `${msg}` ;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);
        return;
      }else{
        responseSeverity = 'error';
        responseMessage = 'Error while creating file' ;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);
        return;
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while creating file, please contact admin' ;

      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setProcessing(false);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }


  /* on click edit icon */
  const handleEdit = (row) => {
    setProcessing(true);
    
    try {
      setFileData((prevData) => {
        let fileDate = null;
        let ldh = null;
        let ndh = null;

        if (row.original.fileDate) {
          fileDate = dayjs(row.original.fileDate).isValid() ? dayjs(row.original.fileDate) : null;
        }
        if (row.original.ldh) {
          ldh = dayjs(row.original.ldh).isValid() ? dayjs(row.original.ldh) : null;
        }
        if (row.original.ndh) {
          ndh = dayjs(row.original.ndh).isValid() ? dayjs(row.original.ndh) : null;
        }
        const updatedData = {
          ...prevData,
          _id: { ...prevData._id, value: row.original._id },
          fileId: { ...prevData.fileId, value: row.original.fileId },
          fileNo: { ...prevData.fileNo, value: row.original.fileNo },
          fileDate: { ...prevData.fileDate, value: fileDate },
          fileName: { ...prevData.fileName, value: row.original.fileName },
          rack: { ...prevData.rack, value: row.original.rack ? row.original.rack._id : null },
          caseNo: { ...prevData.caseNo, value: row.original.caseNo ? row.original.caseNo : '' },
          client: { ...prevData.client, value: row.original.client._id },
          pinCode: { ...prevData.pinCode, value: row.original.pinCode },
          city: { ...prevData.city, value: row.original.city },
          state: { ...prevData.state, value: row.original.state },
          address: { ...prevData.address, value: row.original.address },
          contactPersonName: { ...prevData.contactPersonName, value: row.original.contactPersonName },
          contactPersonMobile: { ...prevData.contactPersonMobile, value: row.original.contactPersonMobile },
          contactPersonEmail: { ...prevData.contactPersonEmail, value: row.original.contactPersonEmail },
          court: { ...prevData.court, value: row.original.court ? row.original.court._id : null},
          department: { ...prevData.department, value: row.original.department ? row.original.department._id : null},
          feeCategory: { ...prevData.feeCategory, value: row.original.feeCategory ? row.original.feeCategory._id : null},
          caseDetails: { ...prevData.caseDetails, value: row.original.caseDetails },
          ldh: { ...prevData.ldh, value: ldh ? ldh : null },
          ndh: { ...prevData.ndh, value: ndh ? ndh : null },
          forCourt: { ...prevData.forCourt, value: row.original.forCourt },
          assignedAdvocate: { ...prevData.assignedAdvocate, value: row.original.assignedAdvocate ? row.original.assignedAdvocate._id : null },
          aor: { ...prevData.aor, value: row.original.aor ? row.original.aor._id : null },
          counsel: { ...prevData.counsel, value: row.original.counsel ? row.original.counsel : ''},
          remarks: { ...prevData.remarks, value: row.original.remarks ? row.original.remarks : '' },
          status: { ...prevData.status, value: row.original.status },
          isActive: { ...prevData.isActive, value: row.original.isActive },
          createdBy: { ...prevData.createdBy, value: row.original.createdBy },
          createdDate: { ...prevData.createdDate, value: row.original.createdDate },
          lastModifiedBy: { ...prevData.lastModifiedBy, value: row.original.lastModifiedBy },
          lastModifiedDate: { ...prevData.lastModifiedDate, value: row.original.lastModifiedDate },
        };

        return updatedData;
      });

      setRackInputValue(row.original.rack ? row.original.rack.rackNo : '');
      setClientInputValue(row.original.client ? row.original.client.clientName : '');
      setCourtInputValue(row.original.court ? row.original.court.courtShortName: '');
      setDepartmentInputValue(row.original.department ? row.original.department.departmentName : '');
      setFeeCategoryInputValue(row.original.feeCategory ? row.original.feeCategory.feeCatName : '');
      setAssignAdvcInputValue(row.original.assignedAdvocate ? row.original.assignedAdvocate.fullName : '');
      setAorInputValue(row.original.aor ? row.original.aor.fullName : '');

      setIsSubmitBtn(false);
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while click on edit icon, please contact admin' ;

      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setProcessing(false);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }

  /* on click delete icon */
  const deleteAction = (row) => {

  }

  /* on click switch icon */
  const toggleIsActive = async(row) => {
    let responseSeverity = '';
    let responseMessage = '';
    let msg = '';
    setProcessing(true);
    let _id = row.original._id;
    const currentIsActive = row.original.isActive;

    try {
      const validToken = localStorage.getItem('token');

      if(currentIsActive === 'Y'){
        row.original.isActive = 'N';
        msg = 'Selected File is In-Active Now';
      }else {
        row.original.isActive = 'Y';
        msg = 'Selected File is Active Now';
      }

      const response = await axios.put(`${BASE_API_URL}/api/file/updateflag/${_id}`, {isActive : row.original.isActive},
        {
          headers: {
            Authorization: `${validToken}`
          }
        }
      );

      switch (response.status) {
        case 200:
          responseSeverity = 'success';
          responseMessage = `${msg}`;

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
  
          setOpen(true);
          handleReload();
          break;
        case 400:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Invalid data!';

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        case 404:
          responseSeverity = 'error';
          responseMessage = 'Update failed: File not found!';

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        case 500:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Internal server error!';
          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        default:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Unknown error!';

          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while calling toggleIsActive';
      setProcessing(false);
      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }
  
  const handleReload = () => {
    setFileData({
      _id:{ value: ''},
      fileId:{ value:'', error:false, errorMessage: ''},
      fileNo:{ value:'', error:false, errorMessage: '' },
      fileDate:{ value: null, error:false, errorMessage: '' },
      fileName:{ value:'', error:false, errorMessage: '' },
      rack: { value:null, error:false, errorMessage: '' },
      caseNo:{ value:'', error:false, errorMessage: '' },
      client:{ value: null, error:false, errorMessage: '' },
      pinCode:{ value: '', error:false, errorMessage: '' },
      city:{ value: '', error:false, errorMessage: '' },
      state:{ value: '', error:false, errorMessage: '' },
      address:{ value: '', error:false, errorMessage: '' },
      contactPersonName: {value:'', error:false, errorMessage: ''},
      contactPersonMobile: {value:'', error:false, errorMessage: ''},
      contactPersonEmail: {value:'', error:false, errorMessage: ''},
      court:{ value: null, error:false, errorMessage: '' },
      department: { value: null, error:false, errorMessage: '' },
      feeCategory:{ value: null, error:false, errorMessage: '' },
      caseDetails: {value:'', error:false, errorMessage: ''},
      ldh: {value: null, error:false, errorMessage: ''},
      ndh: {value: null, error:false, errorMessage: ''},
      forCourt: {value:'', error:false, errorMessage: ''}, 
      assignedAdvocate: { value: null, error:false, errorMessage: '' },
      aor: { value: null, error:false, errorMessage: '' },
      counsel: {value:'', error:false, errorMessage: ''},
      remarks: {value:'', error:false, errorMessage: ''},
      status: { value:'', error:false, errorMessage: '' },
      isActive: {value:'Y', error:false, errorMessage: ''},
      createdBy:{ value: null, error:false },
      createdDate:{ value: null, error:false },
      lastModifiedBy:{ value: null, error:false },
      lastModifiedDate:{ value:null, error:false },
    });

    const currentDate = dayjs();
    setFileData(prevState => ({
      ...prevState,
      fileDate: { value: currentDate },
    }));

    setFilterData({
      fileNo: { value: null, error:false, errorMessage: '' },
      fileName: { value: '', error:false, errorMessage: '' },
      client: { value: null, error:false, errorMessage: '' },
    })

    setAorInputValue('');
    setAros([]);
    setAssignAdvcInputValue('');
    setAssignedAdvcs([]);
    setRackInputValue('');
    setRacks([]);
    setClientInputValue('');
    setClients([]);
    setCourtInputValue('');
    setCourts([]);
    setDepartmentInputValue('');
    setDepartments([]);
    setFeeCategoryInputValue('');
    setFeeCategorys([]);
    setIsSubmitBtn(true);
    setIsCsvReady(false);
    setCsvFileList([]);

    fetchFiles();
  }

  /* on click close msg */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /* column to be export */
  const customColumns = {
    fileDate: 'Date',
    fileNo: 'File No',
    fileName: 'File Name',
    caseNo: 'Case No',
    client: 'Client Name',
    address: 'Client Address',
    city: 'City',
    state: 'State',
    pinCode: 'Pincode',
    contactPersonName: 'Contact Person',
    contactPersonMobile: 'Mobile',
    contactPersonEmail: 'Email',
    court: 'Court',
    department: 'Dept.',
    feeCategory: 'Cat.',
    caseDetails: 'Case Details',
    ldh: 'LDH',
    ndh: 'NDH',
    forCourt: 'For',
    assignedAdvocate: 'Assign',
    aor: 'AOR',
    rack: 'Rack No',
    counsel: 'Counsel',
    remarks: 'Remarks'
  };
  
  /* CSV export all  data */
  const handleExportAllData = async() => {
    setProcessing(true);
    try {
      const validToken = localStorage.getItem('token');
      const response = await axios.get(`${BASE_API_URL}/api/file/exportallfiles`, {
        headers: {
          Authorization: `${validToken}`
        }
      });
      let data = response.data;

      exportToExcel(data, 'File.xlsx', customColumns, 'FILE');

      setProcessing(false);
    } catch (error) {
      console.error('Error while calling handleExportAllData', error);
    }finally{
      setProcessing(false);
    }
  }

  /* CSV export page data */
  const handleExportPageData = (rows) => {
    const rowData = rows.map((row) => row.original);
    exportToExcel(rowData, 'File.xlsx', customColumns, 'FILE');
    setProcessing(false);
  }

  /* copy existing file */
  const handleCopyTask = (row) => {
    let _id = row.original._id;
    setCopyData(_id);
    setDialogMsg(
      <>
        Do you want to copy file no <b>{row.original.fileNo}</b> ?
      </>
    );
    setDialogConfirmOpen(true);
  }

  /* close confirmation dialog */
  const handleCloseDialogBox = () => {
    setDialogConfirmOpen(false)
  }

  /* close final action */
  const handleApproverAction = async (val) => {
    setProcessing(true);
    let responseSeverity = '';
    let responseMessage = '';
    let _id = copyData;
    let loginId = userDtls.user_ID;
    let currentDate = dayjs();
    let nextDay = currentDate.add(1, 'day');
    let endOfDay = nextDay.set('hour', 23).set('minute', 59);
    try {
      setDialogConfirmOpen(false);

      if(val === 'COPY'){
        const response = await axios.post(`${BASE_API_URL}/api/file/copyfile`, { _id, loginId, currentDate, nextDay, endOfDay});

        let data = response.data;
        let msg = data.message;

        if(data.status === 'success'){
          responseSeverity = 'success';
          responseMessage = `${msg}`;
  
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setProcessing(false);
          setOpen(true);
  
          handleReload();
        }else{
          responseSeverity = 'error';
          responseMessage = 'Error while creating file' ;
  
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setProcessing(false);
          setOpen(true);
          return;
        }
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while task copy, please contact admin' ;

      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setProcessing(false);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }

  /* fetch court call when type court name */
  const getFilesForAutoComplete = async (value, type) => {
    try {
      if(type === 'FILE_NO'){
        const response = await axios.get(`${BASE_API_URL}/api/file/getfileskbyprifix/${value}`);
        setFiles(response.data);
      }else if(type === 'FILE_NAME'){
        const response = await axios.get(`${BASE_API_URL}/api/file/getfilenamekbyprifix/${value}`);
        setFileNames(response.data);
      }
    } catch (error) {
      console.error('Error fetching  file data:', error);
    }
  };

  /* Requied for file autocomplete */
  const handleFileInputChange = (event, value) => {
    setFileInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      fileNo: {
        ...prevData.fileNo,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 3) {
      getFilesForAutoComplete(value, 'FILE_NO');
    } else {
      setFiles([]);
    }
  };

  /* Requied for file autocomplete */
  const handleFileAutocompleteChange = (event, selectedItem) => {
    const selectedData = files.find(item => item.fileNo === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          fileNo: {
            ...prevData.fileNo,
            value: selectedData.fileNo,
            error: false
          }
        }));
    } else {
      console.error('Selected file data not found');
    }
  };


  /* Requied for file autocomplete */
  const handleFileNameInputChange = (event, value) => {
    setFileNameInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      fileName: {
        ...prevData.fileName,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 3) {
      getFilesForAutoComplete(value, 'FILE_NAME');
    } else {
      setFileNames([]);
    }
  };

  /* Requied for file autocomplete */
  const handleFileNameAutocompleteChange = (event, selectedItem) => {
    const selectedData = fileNames.find(item => item.fileName === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          fileName: {
            ...prevData.fileName,
            value: selectedData.fileName,
            error: false
          }
        }));
    } else {
      console.error('Selected file data not found');
    }
  };

  /* Requied for filter client autocomplete */
  const handleFilterClientInputChange = (event, value) => {
    setFilterClientInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      client: {
        ...prevData.client,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a client' : ''
      }
    }));
    if (value.length >= 3) {
      fetchClient(value, "FC");
    } else {
      setFilterClients([]);
    }
  };

  /* Requied for filter client autocomplete */
  const handleFilterClientAutocompleteChange = (event, selectedItem) => {
    const selectedData = filterClients.find(item => item.clientName === selectedItem);
    
    if (selectedData) {
      setFilterData(prevData => ({
        ...prevData,
        client: {
          ...prevData.client,
          value: selectedData._id,
          error: false
        }
      }));
    } else {
      console.error('Selected Client data not found in clients array');
    }
  };

  const handleFilterSearch = () => {
    fetchFiles();
  }

  /* file selection */
  const handleSelectCSVFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProcessing(true);
      Papa.parse(file, {
        header: false, // Use indices instead of headers
        skipEmptyLines: true,
        complete: (result) => {
          const processedData = result.data.slice(1).map(row => ({
            fileDate: row[1],
            fileNo: row[2],
            fileName: row[3],
            caseNo: row[4],
            clientName: row[5],
            clientAdress: row[6],
            city: row[7],
            state: row[8],
            pinCode: row[9],
            contactPersonName: row[10],
            contactPersonMobile: row[11],
            contactPersonEmail: row[12],
            court: row[13],
            department: row[14],
            feeCategory: row[15],
            caseDetails: row[16],
            ldh: row[17],
            ndh: row[18],
            forCourt: row[19],
            assignedAdvocate: row[20],
            aor: row[21],
            counsel: row[22],
            rack: row[23],
            remarks: row[24]
          }));
         
          setCsvFileList(processedData);
          setIsCsvReady(true);
          setProcessing(false);
          setIsModalOpen(true);
        },
      });
    }
  }

  /* upload csv file to database */
  const handleFileUpload = async () => {
    setIsModalOpen(false);
    setProcessing(true);
    let responseSeverity = '';
    let responseMessage = '';

    try {
      if(csvFileList.length > 0){
        let userId = userDtls.user_ID;

        const response = await axios.post(`${BASE_API_URL}/api/file/bulkfileupload`, { userId, csvFileList });
        let msg = response.data.message;

        switch (response.status) {
          case 201:
            responseSeverity = 'success';
            responseMessage = `${msg}`;
            handleReload();
            break;
          case 400:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Invalid data!';
            break;
          case 404:
            responseSeverity = 'error';
            responseMessage = 'Update failed: file not found!';
            break;
          case 500:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Internal server error!';
            break;
          default:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Unknown error!';
            break;
        }

        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
      }else{
        responseSeverity = "error";
        responseMessage = "Selected Csv File is empty";
        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
      }
    } catch (error) {
      responseSeverity = "error";
      responseMessage = "Error while calling handleFileUpload, please contact with admin";
      setProcessing(false);
      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setOpen(true);
      console.error('Error while calling handleFileUpload',error);
    }
  }

  /* cancel dialog */
  const handleCancel = () => {
    setIsModalOpen(false);
  }

  /* close confirmation dialog */
  const handleCloseCsvConfirmation = () => {
    setIsModalOpen(false);
  }

  return (
    <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
        <Card sx={{borderTop: '5px solid #353839 ', width: '100%', mb:3, backgroundColor: 'white'}}>
          <CardContent>
            <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839 !important'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839 !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839', 
                          '&.Mui-focused': {
                            color: '#353839',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      label="File Date"
                      name="fileDate"
                      fullWidth
                      required
                      value={fileData.fileDate.value}
                      onChange={(newValue) => handleDateChange('fileDate', newValue)}
                      error={fileData.fileDate.error}
                      helperText={fileData.fileDate.error && fileData.fileDate.errorMessage}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>
                
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StepriseTextField
                    label="File No"
                    name="fileNo"
                    size='small'
                    value={fileData.fileNo.value}
                    onChange={handleChange}
                    required
                    error={fileData.fileNo.error}
                    helperText={fileData.fileNo.error && fileData.fileNo.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <StepriseTextField
                    label="File Name"
                    name="fileName"
                    size='small'
                    value={fileData.fileName.value}
                    onChange={handleChange}
                    required
                    error={fileData.fileName.error}
                    helperText={fileData.fileName.error && fileData.fileName.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={6} lg={1}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="rack"
                    inputValue={rackInputValue}
                    onInputChange={handleRackInputChange}
                    onChange={handleRackAutocompleteChange}
                    options={racks.map((item) => item.rackNo)}
                    label="Rack"
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={6} lg={2}>
                  <StepriseTextField
                    label="Case No"
                    name="caseNo"
                    size='small'
                    value={fileData.caseNo.value}
                    onChange={handleChange}
                    error={fileData.caseNo.error}
                    helperText={fileData.caseNo.error && fileData.caseNo.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="client"
                    inputValue={clientInputValue}
                    onInputChange={handleClientInputChange}
                    onChange={handleClientAutocompleteChange}
                    options={clients.map((item) => item.clientName)}
                    label="Client Name"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <StepriseTextField
                    disabled
                    label="Pin Code"
                    name="pinCode"
                    size='small'
                    value={fileData.pinCode.value}
                    onChange={handleChange}
                    required
                    error={fileData.pinCode.error}
                    helperText={fileData.pinCode.error && fileData.pinCode.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <StepriseTextField
                    disabled
                    label="City"
                    name="city"
                    size='small'
                    value={fileData.city.value}
                    onChange={handleChange}
                    required
                    error={fileData.city.error}
                    helperText={fileData.city.error && fileData.city.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={2}>
                  <StepriseTextField
                    disabled
                    label="State Name"
                    name="state"
                    size='small'
                    value={fileData.address.value}
                    onChange={handleChange}
                    required
                    error={fileData.address.error}
                    helperText={fileData.address.error && fileData.address.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <StepriseTextField
                    disabled
                    label="Client Address"
                    name="address"
                    size='small'
                    value={fileData.address.value}
                    onChange={handleChange}
                    required
                    error={fileData.address.error}
                    helperText={fileData.address.error && fileData.address.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <StepriseTextField
                    label="Contact Person Name"
                    name="contactPersonName"
                    size='small'
                    value={fileData.contactPersonName.value}

                    onChange={(e) => {
                      const value = e.target.value;
                      const re = /^[A-Za-z\s]*$/;

                      if (re.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={fileData.contactPersonName.error}
                    helperText={fileData.contactPersonName.error && fileData.contactPersonName.errorMessage}
                    inputProps={{ inputMode: 'text' }}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <StepriseTextField
                    label="Contact Person Mobile"
                    name="contactPersonMobile"
                    size='small'
                    value={fileData.contactPersonMobile.value}
                    onChange={(e) => {
                    const value = e.target.value;
                    const re = /^[0-9\b]*$/;
                      if (re.test(value) && value.length <= 10) {
                        handleChange(e);
                      }
                    }}
                    error={
                      fileData.contactPersonMobile.value && 
                      (fileData.contactPersonMobile.error || fileData.contactPersonMobile.value.length < 10)
                    }
                    helperText={
                      fileData.contactPersonMobile.value &&
                      ((fileData.contactPersonMobile.error && fileData.contactPersonMobile.errorMessage) || 
                      (fileData.contactPersonMobile.value.length < 10 && "Minimum 10 digits long"))
                    }
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', minLength: 10, maxLength: 10 }}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <StepriseTextField
                    label="Contact Person Email"
                    name="contactPersonEmail"
                    size='small'
                    value={fileData.contactPersonEmail.value}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={() => setTouched(prev => ({ ...prev, contactPersonEmail: true }))}
                    error={!isValidEmail(fileData.contactPersonEmail.value) && touched.contactPersonEmail}
                    helperText={
                      !isValidEmail(fileData.contactPersonEmail.value) && touched.contactPersonEmail && "Invalid email address"
                    }
                    inputProps={{inputMode: 'contactPersonEmail', pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',}}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="feeCategory"
                    inputValue={feeCategoryInputValue}
                    onInputChange={handleFeeCategoryInputChange}
                    onChange={handleFeeCategoryAutocompleteChange}
                    options={feeCategorys.map((item) => item.feeCatName)}
                    label="Fee Cat"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="court"
                    inputValue={courtInputValue}
                    onInputChange={handleCourtInputChange}
                    onChange={handleCourtAutocompleteChange}
                    options={courts.map((item) => item.courtShortName)}
                    label="Court"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="department"
                    inputValue={departmentInputValue}
                    onInputChange={handleDepartmentInputChange}
                    onChange={handleDepartmentAutocompleteChange}
                    options={departments.map((item) => item.departmentName)}
                    label="Department"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ReactQuill
                    value={fileData.caseDetails.value}
                    onChange={handleQuillChange}
                    placeholder="Enter Case Details"
                    theme="snow"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839 !important'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839', 
                          '&.Mui-focused': {
                            color: '#353839',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      label="LDH"
                      name="ldh"
                      fullWidth
                      required
                      value={fileData.ldh.value}
                      onChange={(newValue) => handleDateChange('ldh', newValue)}
                      error={fileData.ldh.error}
                      helperText={fileData.ldh.error && fileData.ldh.errorMessage}
                      //shouldDisableDate={(date) => isFutureDate(date)}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        '& .MuiOutlinedInput-input': {
                          height: '0.5em !important', 
                          padding: '16.5px 10px !important'
                        }, 
                        '& .MuiInputLabel-root': {
                          lineHeight: '1.0 !important'
                        },
                        '& .MuiIconButton-root': {
                          color:'#353839'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },

                        '& .MuiInputLabel-root': {
                          color: '#353839', 
                          '&.Mui-focused': {
                            color: '#353839',
                          },
                          '&.Mui-error': {
                            color: 'red', 
                          },
                        },
                      }}
                      label="NDH"
                      name="ndh"
                      fullWidth
                      required
                      value={fileData.ndh.value}
                      onChange={(newValue) => handleDateChange('ndh', newValue)}
                      error={fileData.ndh.error}
                      helperText={fileData.ndh.error && fileData.ndh.errorMessage}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <StepriseTextField
                    label="For Court"
                    name="forCourt"
                    size='small'
                    value={fileData.forCourt.value}
                    onChange={handleChange}
                    error={fileData.forCourt.error}
                    helperText={fileData.forCourt.error && fileData.forCourt.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="assignedAdvocate"
                    inputValue={assignAdvcInputValue}
                    onInputChange={handleAssignAdvcInputChange}
                    onChange={handleAssignAdvcAutocompleteChange}
                    options={assignedAdvcs.map((item) => item.fullName)}
                    label="Assigned Advocate"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="aor"
                    inputValue={aorInputValue}
                    onInputChange={handleAorInputChange}
                    onChange={handleAorAutocompleteChange}
                    options={aros.map((item) => item.fullName)}
                    label="AOR"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <StepriseTextField
                    label="Counsel"
                    name="counsel"
                    size='small'
                    value={fileData.counsel.value}
                    onChange={handleChange}
                    error={fileData.counsel.error}
                    helperText={fileData.counsel.error && fileData.counsel.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <StepriseTextField
                    label="Remarks"
                    name="remarks"
                    size='small'
                    value={fileData.remarks.value}
                    onChange={handleChange}
                    error={fileData.remarks.error}
                    helperText={fileData.remarks.error && fileData.remarks.errorMessage}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}></Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  {isSubmitBtn ? (
                    <Button  type="submit" fullWidth variant="contained" color="secondary" startIcon={<SaveIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Submit
                    </Button>
                    ) : (
                    <Button  type="submit" variant="contained" fullWidth color="secondary"  startIcon={<EditNoteIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Update
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Button variant="contained" onClick={handleReload} fullWidth color="secondary" type="button" startIcon={<RefreshIcon />}
                    sx={{
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    Reload
                  </Button>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <input
                    style={{ display: 'none' }}
                    id="file-upload-images"
                    type="file"
                    name="prodImages"
                    onChange={handleSelectCSVFile}
                  />
                  <label htmlFor="file-upload-images">
                    <Button variant="outlined"  fullWidth component="span" startIcon={<CloudUploadIcon />} 
                      sx={{
                        color: 'white',
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Select CSV file
                    </Button>
                  </label>
                </Grid>
              </Grid>

              <Dialog disableBackdropClick={true} open={dialogConfirmOpen} onClose={handleCloseDialogBox} >
                <DialogTitle
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                >
                  Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      {dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogBox} color='error'>Cancel</Button>
                    <Button onClick={() => handleApproverAction('COPY')} color='success'>OK</Button>
                </DialogActions>
              </Dialog>

              {/* CSV FILE UPLOA CONFIRMATION */}
              <Dialog open={isModalOpen} onClose={handleCloseCsvConfirmation} >
                <DialogTitle sx={{textAlign: 'center',display: 'flex',flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                  Confirmation
                </DialogTitle>

                <DialogContent>
                  The CSV file has been processed. Do you want to proceed?
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleFileUpload} color="success">
                    Yes
                  </Button>
                  <Button onClick={handleCancel} color="error">
                    No
                  </Button>
                </DialogActions>
              </Dialog>

            </form>
            <ProcessingDialog open={processing}/>
          </CardContent>
        </Card>

        <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="file"
                  inputValue={fileInputValue}
                  onInputChange={handleFileInputChange}
                  onChange={handleFileAutocompleteChange}
                  options={files.map((item) => item.fileNo)}
                  label="File No"
                />
              </Grid>

              <Grid item xs={12} sm={3} md={6} lg={3}>
              <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="fileName"
                  inputValue={fileNameInputValue}
                  onInputChange={handleFileNameInputChange}
                  onChange={handleFileNameAutocompleteChange}
                  options={fileNames.map((item) => item.fileName)}
                  label="File Name"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={5}>
                <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="client"
                  inputValue={filterClientInputValue}
                  onInputChange={handleFilterClientInputChange}
                  onChange={handleFilterClientAutocompleteChange}
                  options={filterClients.map((item) => item.clientName)}
                  label="Select Client"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={1}>
                <Button variant="contained" size='large' onClick={handleFilterSearch} fullWidth type="button"
                  startIcon={<SearchIcon />}
                  sx={{
                    backgroundColor: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={3} sx={{mb:4}}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
              exportAllData={handleExportAllData}
              handleExportSelectedRows={handleExportPageData}
              handleExportRows={handleExportPageData}
              data={fileTbl}
              headerNames={fileTblHeader}
              editAction={handleEdit}
              copyAction={handleCopyTask}
              deleteAction={deleteAction}
              editTooltipTitle="Edit"
              copyTooltipTitle="Copy"
              deleteTooltipTitle="Delete"
              editIcon={EditNoteIcon}
              copyIcon={ContentCopyIcon}
              deleteIcon={EditIcon}
              isActive={toggleIsActive}
              isActiveTooltipTitle="Toggle Active"
              initialState={{ columnVisibility }}
              showCopyIcon={true}
              showEditIcon={true}
              showToggleIcon={true}
              showDeleteIcon={false} 
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
