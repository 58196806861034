import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Container, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import {jwtDecode} from 'jwt-decode';
import StepriseAutocompleteBox from '../Components/StepriseAutocompleteBox';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RefreshIcon from '@mui/icons-material/Refresh';
import ResponsiveTable from '../Components/ResponsiveTable';
import dayjs from 'dayjs'; 
import ProcessingDialog from '../Components/ProcessingDialog';
import exportToExcel from "../utils/exportToExcel";

const initialColumnVisibility = {
    'fileNo': true,
    'fileName': true,
    'taskMst.taskName': true,
    'advocate.fullName': true,
    'startDate': false,
    'startTime': false,
    'endDate': false,
    'endTime': false,
    'workStartDate': true,
    'workStartTime': false,
    'workEndDate': true,
    'workEndTime': false,
    'court.courtShortName': false,
    'prevHours': false,
    'workedHours': false,
    'billedHours': false,
    'totalHours': true,
    'ldh': false,
    'ndh': false,
    'workStatus': true,
    'remarks': false,
    'workDetails': false,
    'intern.fullName': false,
    'clerk.fullName': false,
    'counsel': false,
    'otherAdvocate.fullName': false,
    'status': false,
};

export default function Dwr() {
    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
    const [dwrReportTblData, setDwrReportTblData] = useState([]);
    const [userDtls, setUserDtls] = useState([]);
    const [responseSeverity, setResponseSeverity] = React.useState('');
    const [responseMessage, setResponseMessage] = React.useState('');
    const [processing, setProcessing] = useState(false);
    const [open, setOpen] = useState(false);
    const [isSubmitBtn, setIsSubmitBtn] = useState(true);
    const [fileInputValue, setFileInputValue] = useState('');
    const [files, setFiles] = useState([]);
    const [taskMstInputValue, setTaskMstInputValue] = useState('');
    const [taskmsts, setTaskmsts] = useState([]);
    const [filterAdvocInputValue, setFilterAdvocInputValue] = useState('');
    const [filterAdvocs, setFilterAdvocs] = useState([]);

    const [filterData, setFilterData] = useState({
        status: { value: 'Completed', error:false, errorMessage: '' },
        task: { value:null, error:false, errorMessage: '' },
        file: { value:null, error:false, errorMessage: '' },
        workStartDate: { value:null, error:false, errorMessage: '' },
        workEndDate: { value:null, error:false, errorMessage: '' },
        advocate: { value:null, error:false, errorMessage: '' }
    });

    /* header names for the table columns */ 
    const dwrTblHeader = [
        { accessorKey: 'fileNo', name: 'File No'},
        { accessorKey: 'fileName', name: 'File Name'},
        { accessorKey: 'taskMst.taskName', name: 'Task Name'},
        { accessorKey: 'advocate.fullName', name: 'Advocate'},
        { accessorKey: 'startDate', name: 'Start Date'},
        { accessorKey: 'startTime', name: 'Start Time'},
        { accessorKey: 'endDate', name: 'End Date'},
        { accessorKey: 'endTime', name: 'End Time'},
        { accessorKey: 'workStartDate', name: 'Work Start Date'},
        { accessorKey: 'workStartTime', name: 'Work Start Time'},
        { accessorKey: 'workEndDate', name: 'Work End Date'},
        { accessorKey: 'workEndTime', name: 'Work End Time'},
        { accessorKey: 'court.courtShortName', name: 'Court'},
        { accessorKey: 'prevHours', name: 'Prev Hrs'},
        { accessorKey: 'workedHours', name: 'WH'},
        { accessorKey: 'billedHours', name: 'BH'},
        { accessorKey: 'ldh', name: 'LDH'},
        { accessorKey: 'ndh', name: 'NDH'},
        { accessorKey: 'totalHours', name: 'Total Hours'},
        { accessorKey: 'workStatus', name: 'Work Status'},
        { accessorKey: 'remarks', name: 'Remarks'},
        { accessorKey: 'workDetails', name: 'Work Details'},
        { accessorKey: 'intern.fullName', name: 'Intern'},
        { accessorKey: 'clerk.fullName', name: 'Clerk'},
        { accessorKey: 'counsel', name: 'counsel'},
        { accessorKey: 'otherAdvocate.fullName', name: 'Other Advocates'},
        { accessorKey: 'status', name: 'Status'}
    ];

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
          const dcrptData = jwtDecode(token);
          setUserDtls(dcrptData);
        }
    }, []);

    useEffect(() => {
        if (userDtls.user_ID) {
            const currentDate = dayjs();
            setFilterData(prevState => ({
                ...prevState,
                workStartDate: { value: currentDate },
                workEndDate: { value: currentDate },
            }));

            dailyWorkReports();
        }
    }, [userDtls]);

    /* fetch all master task */
    const fetchTaskMstByPrefix = async (value) => {
        try {
            const response = await axios.get(`${BASE_API_URL}/api/task/gettasksbyprifix/${value}`);
            setTaskmsts(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    /* fetch court call when type court name */
    const fetchFiles= async (value) => {
        try {
            const response = await axios.get(`${BASE_API_URL}/api/file/getfileskbyprifix/${value}`);
            setFiles(response.data);
        } catch (error) {
            console.error('Error fetching  file data:', error);
        }
    };

    /* fetch employee call when type product */
    const fetchEmp = async (value, roleType) => {
        try {
            const response = await axios.get(`${BASE_API_URL}/api/emp/getempbyroleandprefix/${value}/${roleType}`);

            if(roleType === 'FADV'){
                setFilterAdvocs(response.data);
            }
        } catch (error) {
            console.error('Error fetching  Emp data:', error);
        }
    };

    /* fetch work detail reports  */
    const dailyWorkReports = async () => {
        setProcessing(true);
        let status = filterData.status.value;
        let task = filterData.task.value;
        let file = filterData.file.value;
        let advocate = filterData.advocate.value;
        let workStartDate = filterData.workStartDate.value;
        let workEndDate = filterData.workEndDate.value;
        let roleName = userDtls.roleName;
        let loginId = userDtls.user_ID;

        try {
            const response = await axios.get(`${BASE_API_URL}/api/taskmanagment/dwreports/${status}/${task}/${file}/${advocate}/${workStartDate}/${workEndDate}/${roleName}/${loginId}`);
            
            setDwrReportTblData(response.data);
            setProcessing(false);
        } catch (error) {
            console.error('Error fetching  Emp data:', error);
        }finally{
            setProcessing(false);
        }
    }

    /* filter input change */
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        let error = false;
        let errorMessage = '';

        setFilterData((prevData) => ({
        ...prevData,
        [name]: { ...prevData[name], value, error, errorMessage}
        }))
    }

    const handleTaskMstInputChange = (event, value) => {
        setTaskMstInputValue(value);
        setFilterData(prevData => ({
          ...prevData,
          task: {
            ...prevData.task,
            value: value,
            error: !value,
            errorMessage: !value ? '' : ''
          }
        }));
        if (value.length >= 2) {
          fetchTaskMstByPrefix(value);
        } else {
          setTaskmsts([]);
        }
      };
    
      /* Requied for Master Task autocomplete */
      const handleTaskMstAutocompleteChange = (event, selectedItem) => {
        const selectedData = taskmsts.find(item => item.taskName === selectedItem);
        
        if (selectedData) {
            setFilterData(prevData => ({
              ...prevData,
              task: {
                ...prevData.task,
                value: selectedData._id,
                error: false
              }
            }));
        } else {
          console.error('Selected task data not found');
        }
    };


    /* Requied for file autocomplete */
    const handleFileInputChange = (event, value) => {
        setFileInputValue(value);
        setFilterData(prevData => ({
            ...prevData,
            file: {
                ...prevData.file,
                value: value,
                error: !value,
                errorMessage: !value ? '' : ''
            }
        }));
        if (value.length >= 2) {
            fetchFiles(value);
        } else {
            setFiles([]);
        }
    };

    const handleFilterAdvocInputChange = (event, value) => {
        setFilterAdvocInputValue(value);
        setFilterData(prevData => ({
          ...prevData,
          advocate: {
            ...prevData.advocate,
            value: value,
            error: !value,
            errorMessage: !value ? '' : ''
          }
        }));
        if (value.length >= 0) {
          fetchEmp(value, 'FADV');
        } else {
          setFilterAdvocs([]);
        }
    };
    
      /* Requied for filter advocate autocomplete */
      const handleFilterAdvocAutocompleteChange = (event, selectedItem) => {
        const selectedData = filterAdvocs.find(item => item.fullName === selectedItem);
        
        if (selectedData) {
            setFilterData(prevData => ({
              ...prevData,
              advocate: {
                ...prevData.advocate,
                value: selectedData._id,
                error: false
              }
            }));
        } else {
          console.error('Selected filter advocate data not found');
        }
      };

    /* Requied for file autocomplete */
    const handleFileAutocompleteChange = (event, selectedItem) => {
        const selectedData = files.find(item => item.fileNo === selectedItem);
        
        if (selectedData) {
            setFilterData(prevData => ({
                ...prevData,
                file: {
                    ...prevData.file,
                    value: selectedData._id,
                    error: false
                }
            }));
        } else {
            console.error('Selected file data not found');
        }
    };


    /** date change handler */
    const handleDateChange = (name, value) => {
        let error = false;
        let errorMessage = '';
    
        if (name === 'workStartDate' || name === 'workEndDate') {
            if (!value) {
                error = true;
                errorMessage = 'You must enter a valid date';
            } else if (isFutureDate(value)) {
                error = true;
                errorMessage = 'Date cannot be a future date';
            }
        }
    
        setFilterData((prevData) => ({
            ...prevData,
            [name]: {
              value,
              error,
              errorMessage,
            },
        }));
    };

    /* validating future date */
    const isFutureDate = (date) => {
        return new Date(date) > new Date();
    };

    /* on click search icon btn */
    const handleFilterSearch = () => {
        try {
            dailyWorkReports();
        } catch (error) {
            console.error('Error while calling handleFilterSearch');
        }
    }

    /* column to be export */
    const exportedDWRColumns = {
        taskMst: 'Task Name',
        fileNo: 'File No',
        fileName: 'File Name',
        advocate: 'Advocate',
        intern: 'Intern',
        clerk: 'Clerk',
        counsel: 'Counsel',
        otherAdvocate: 'Other Advocate',
        court: 'Court',
        courtNo: 'Court No',
        itemNo: 'Item No',
        isBillable: 'isBillable',
        workStartDate: 'Work Start Date',
        workStartTime: 'Work Start Time',
        workEndDate: 'Work End Date',
        workEndTime: 'Work End Time',
        prevHours: 'Prev Hours',
        workedHours: 'WH',
        billedHours: 'BH',
        totalHours: 'TH',
        ldh: 'LDH',
        ndh: 'NDH',
        remarks: 'Remarks',
        workDetails: 'Work Details',
        workStatus: 'Status'
    };

    /* export all data */
    const handleExportAllData = () => {
        setProcessing(true);
        try {
            let data = dwrReportTblData;

            exportToExcel(data, 'DWR.xlsx', exportedDWRColumns, 'DWR');
            setProcessing(false);
        } catch (error) {
        setProcessing(false);
        console.error(error);
        }finally{
        setProcessing(false);
        }
    };

    /* export paage data only */
    const handleExportPageData = (rows) => {
        setProcessing(true);
        try {
            const rowData = rows.map((row) => row.original);

            exportToExcel(rowData, 'DWR.xlsx', exportedDWRColumns, 'DWR');
            setProcessing(false);
        } catch (error) {
        setProcessing(false);
        console.error(error);
        }finally{
        setProcessing(false);
        }
    }


    return (
        <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
                <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
                    <CardContent>
                        <Grid container spacing={3}>
                            {
                                userDtls.roleName === 'Admin' ||  userDtls.roleName === 'Super Admin' ? (
                                    <>
                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <StepriseAutocompleteBox
                                                size='small'
                                                required
                                                freeSolo
                                                name="file"
                                                inputValue={fileInputValue}
                                                onInputChange={handleFileInputChange}
                                                onChange={handleFileAutocompleteChange}
                                                options={files.map((item) => item.fileNo)}
                                                label="File No"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <StepriseAutocompleteBox
                                                size='small'
                                                required
                                                freeSolo
                                                name="task"
                                                inputValue={taskMstInputValue}
                                                onInputChange={handleTaskMstInputChange}
                                                onChange={handleTaskMstAutocompleteChange}
                                                options={taskmsts.map((item) => item.taskName)}
                                                label="Task Name"
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12} sm={6} md={6} lg={3}>
                                            <StepriseAutocompleteBox
                                                size='small'
                                                required
                                                freeSolo
                                                name="file"
                                                inputValue={fileInputValue}
                                                onInputChange={handleFileInputChange}
                                                onChange={handleFileAutocompleteChange}
                                                options={files.map((item) => item.fileNo)}
                                                label="File No"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={3}>
                                            <StepriseAutocompleteBox
                                                size='small'
                                                required
                                                freeSolo
                                                name="task"
                                                inputValue={taskMstInputValue}
                                                onInputChange={handleTaskMstInputChange}
                                                onChange={handleTaskMstAutocompleteChange}
                                                options={taskmsts.map((item) => item.taskName)}
                                                label="Task Name"
                                            />
                                        </Grid>
                                    </>
                                )
                            }
                            

                            <Grid item xs={12} sm={6} md={6} lg={2}>
                                <FormControl fullWidth 
                                    sx={{
                                    mt: 0,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&:hover fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                        },
                                    },
                                    }}>
                                    <InputLabel>Task Status</InputLabel>
                                    <Select size='small' label="Task Status" name='status'
                                        value={filterData.status.value}
                                        onChange={handleFilterChange}
                                        
                                    >
                                        <MenuItem value='Completed'>Completed</MenuItem>
                                        <MenuItem value='Incomplete'>Incomplete</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker sx={{
                                        '& .MuiOutlinedInput-input': {
                                            height: '0.5em !important', 
                                            padding: '16.5px 10px !important'
                                        }, 
                                        '& .MuiInputLabel-root': {
                                            lineHeight: '1.0 !important'
                                        },
                                        '& .MuiIconButton-root': {
                                            color:'#353839'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                            borderColor: '#353839',
                                            },
                                            '&:hover fieldset': {
                                            borderColor: '#353839',
                                            },
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#353839',
                                            },
                                            '&.Mui-error fieldset': {
                                            borderColor: 'red',
                                            },
                                        },

                                        '& .MuiInputLabel-root': {
                                            color: '#353839', 
                                            '&.Mui-focused': {
                                            color: '#353839',
                                            },
                                            '&.Mui-error': {
                                            color: 'red', 
                                            },
                                        },
                                        }}
                                        label="Start Date"
                                        name="workStartDate"
                                        fullWidth
                                        required
                                        value={filterData.workStartDate.value}
                                        onChange={(newValue) => handleDateChange('workStartDate', newValue)}
                                        error={filterData.workStartDate.error}
                                        helperText={filterData.workStartDate.error && filterData.workStartDate.errorMessage}
                                        //shouldDisableDate={(date) => isFutureDate(date)}
                                        format="DD/MM/YYYY"
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker sx={{
                                        '& .MuiOutlinedInput-input': {
                                            height: '0.5em !important', 
                                            padding: '16.5px 10px !important'
                                        }, 
                                        '& .MuiInputLabel-root': {
                                            lineHeight: '1.0 !important'
                                        },
                                        '& .MuiIconButton-root': {
                                            color:'#353839 !important'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                            borderColor: '#353839 !important',
                                            },
                                            '&:hover fieldset': {
                                            borderColor: '#353839',
                                            },
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#353839',
                                            },
                                            '&.Mui-error fieldset': {
                                            borderColor: 'red',
                                            },
                                        },

                                        '& .MuiInputLabel-root': {
                                            color: '#353839', 
                                            '&.Mui-focused': {
                                            color: '#353839',
                                            },
                                            '&.Mui-error': {
                                            color: 'red', 
                                            },
                                        },
                                        }}
                                        label="End Date"
                                        name="workEndDate"
                                        fullWidth
                                        required
                                        value={filterData.workEndDate.value}
                                        onChange={(newValue) => handleDateChange('workEndDate', newValue)}
                                        error={filterData.workEndDate.error}
                                        helperText={filterData.workEndDate.error && filterData.workEndDate.errorMessage}
                                        //shouldDisableDate={(date) => isFutureDate(date)}
                                        format="DD/MM/YYYY"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            
                            {
                               userDtls.roleName === 'Admin' ||  userDtls.roleName === 'Super Admin' && (
                                <Grid item xs={12} sm={6} md={6} lg={2}>
                                    <StepriseAutocompleteBox
                                        size='small'
                                        required
                                        freeSolo
                                        name="advocate"
                                        inputValue={filterAdvocInputValue}
                                        onInputChange={handleFilterAdvocInputChange}
                                        onChange={handleFilterAdvocAutocompleteChange}
                                        options={filterAdvocs.map((item) => item.fullName)}
                                        label="Assign Advocate"
                                    />
                                </Grid>
                               )
                            }

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Button variant="contained" size='medium' onClick={handleFilterSearch} fullWidth type="button"
                                    startIcon={<SearchIcon />}
                                    sx={{
                                        backgroundColor: 'black',
                                        '&:hover': {
                                            backgroundColor: 'black',
                                        },
                                    }}
                                > 
                                 View Result
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Button variant="contained" size='medium' onClick={handleFilterSearch} fullWidth type="button"
                                    startIcon={<RefreshIcon />}
                                    sx={{
                                        backgroundColor: 'black',
                                        '&:hover': {
                                            backgroundColor: 'black',
                                        },
                                    }}
                                > 
                                    Reload
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <ProcessingDialog open={processing}/>
                </Card>

                <Grid container spacing={3} sx={{mb:2}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                            exportAllData={handleExportAllData}
                            handleExportSelectedRows={handleExportPageData}
                            handleExportRows={handleExportPageData}
                            data={dwrReportTblData}
                            headerNames={dwrTblHeader}
                            editAction={false}
                            copyAction={false}
                            deleteAction={false}
                            editTooltipTitle="Edit"
                            copyTooltipTitle="Copy"
                            deleteTooltipTitle="Delete"
                            editIcon={false}
                            copyIcon={false}
                            deleteIcon={false}
                            isActive={false}
                            isActiveTooltipTitle="Toggle Active"
                            initialState={{ columnVisibility }}
                            showCopyIcon={false}
                            showEditIcon={false}
                            showToggleIcon={false}
                            showDeleteIcon={false}
                            showActionsColumn={false}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
