import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Autocomplete, Button, Card, CardContent, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import {jwtDecode} from 'jwt-decode';
import CustomSnackbar from '../Components/CustomSnackbar';
import StepriseTextField from '../Components/StepriseTextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import StepriseAutocompleteBox from '../Components/StepriseAutocompleteBox';
import axios from 'axios';
import dayjs from 'dayjs'; 
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ProcessingDialog from '../Components/ProcessingDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SearchIcon from '@mui/icons-material/Search';
import DraftsIcon from '@mui/icons-material/Drafts';
import { exportToCsv, mkConfig } from '../utils/csvUtils';
import StepriseExptTbl from '../Components/StepriseExptTbl';
import exportToExcel from "../utils/exportToExcel";

dayjs.extend(utc);
dayjs.extend(timezone);


const styles = {
  tableContainer: {
    padding: '1px'
  },
  row: {
    display: 'flex',
    padding: '8px 0',
    borderBottom: '1px solid #ccc',
  },
  cell: {
    flex: 1,
    padding: '8px',
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#f3e5f5',
    fontWeight: 'bold',
  },
  actionCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide 
  direction="left" 
  ref={ref}
  timeout={{
      enter: 12315000,  // Adjust the duration for entering transition
      exit: 12315000,   // Adjust the duration for exiting transition
    }}
    easing={{
      enter: 'ease-in-out',  // Easing function for entering transition
      exit: 'ease-in-out',   // Easing function for exiting transition
    }}
   {...props} />;
});

const initialColumnVisibility = {
  'taskMst.taskName': true,
  'fileNo': true,
  'fileName': true,
  'isBillable': false,
  'court.courtFullName': true,
  'courtNo': true,
  'itemNo': false,
  'startDate': false,
  'startTime': false,
  'endDate': false,
  'endTime': false,
  'advocate.fullName': true,
  'intern.fullName': false,
  'clerk.fullName': false,
  'counsel': false,
  'otherAdvocate.fullName': false,
  'status': true
};

export default function TaskManagment() {
  const [emailFlag, setEmailFlag] = useState('N');
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const [userDtls, setUserDtls] = useState([]);
  const [taskTbl, setTaskTbl] = useState([]);
  const [fileTbl, setFileTbl] = useState([]);
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);
  const [advocateInputValue, setAdvocateInputValue] = useState('');
  const [advocates, setAdvocates] = useState([]);
  const [internInputValue, setInternInputValue] = useState('');
  const [interns, setInterns] = useState([]);
  const [clerkInputValue, setClerkInputValue] = useState('');
  const [clerks, setClerks] = useState([]);
  const [otherAdvocateInputValue, setOtherAdvocateInputValue] = useState('');
  const [otherAdvocates, setOtherAdvocates] = useState([]);
  const [courtInputValue, setCourtInputValue] = useState('');
  const [courts, setCourts] = useState([]);
  const [fileInputValue, setFileInputValue] = useState('');
  const [files, setFiles] = useState([]);

  const [selectedTask, setSelectedTask] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [copyData, setCopyData] = useState([]);

  const [taskMstInputValue, setTaskMstInputValue] = useState('');
  const [taskmsts, setTaskmsts] = useState([]);


  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  const [value, setValue] = React.useState(null);

  const [taskData, setTaskData] = useState({
    _id: { value: ''},
    taskMst: {value:null, error:false, errorMessage: '' },
    advocate: { value: null, error:false, errorMessage: ''},
    clerk: { value: null, error:false, errorMessage: ''},
    counsel: { value:'', error:false, errorMessage: '' },
    otherAdvocate:{ value: null, error:false, errorMessage: '' },
    file: { value: null, error:false, errorMessage: ''},
    fileNo: { value:'', error:false, errorMessage: '' },
    fileName: { value:'', error:false, errorMessage: '' },
    isBillable: {value:'Billable', error:false, errorMessage: ''},
    court:{ value: null, error:false, errorMessage: '' },
    courtNo:{ value: '', error:false, errorMessage: '' },
    itemNo:{ value:'', error:false, errorMessage: '' },
    startDate: { value:null, error:false, errorMessage: '' },
    startTime: { value:null, error:false, errorMessage: '' },
    endDate: { value:null, error:false, errorMessage: '' },
    endTime: { value:null, error:false, errorMessage: '' },
    status: { value:'Draft', error:false, errorMessage: '' },
    isActive: {value:'Y', error:false, errorMessage: ''},
    createdBy:{ value: null, error:false },
    createdDate:{ value: null, error:false },
    lastModifiedBy:{ value: null, error:false },
    lastModifiedDate:{ value:null, error:false },

    /* work details here */
    workDoneTimeFrame: { value: null, error:false, errorMessage: ''},
    workStartDate: { value:null, error:false, errorMessage: '' },
    workStartTime:{ value: null, error:false, errorMessage: '' },
    workEndDate: { value: null, error:false, errorMessage: '' },
    workEndTime:{ value: null, error:false, errorMessage: '' },
    prevHours:{ value: null, error:false, errorMessage: '' },
    workedHours: { value:'', error:false, errorMessage: '' },
    billedHours: {value:'', error:false, errorMessage: ''},
    totalHours:{ value: '', error:false, errorMessage: '' },
    ldh: { value: null, error:false, errorMessage: '' },
    ndh: { value: null, error:false, errorMessage: '' },
    workStatus:{ value: 'Draft', error:false, errorMessage: '' },
    remarks: { value:null, error:false, errorMessage: '' },
    workDetails: { value:null, error:false, errorMessage: '' },
    workedBy:{ value: null, error:false },
    workedDate:{ value: null, error:false },
    lastWorkModifiedBy:{ value: null, error:false },
    lastWorkModifiedDate:{ value:null, error:false },
  });

  /* header names for the table columns */ 
  const taskTblHeader = [
    { accessorKey: 'taskMst.taskName', name: 'Task Name'},
    { accessorKey: 'fileNo', name: 'File No'},
    { accessorKey: 'fileName', name: 'File Name'},
    { accessorKey: 'isBillable', name: 'isBillable'},
    { accessorKey: 'court.courtFullName', name: 'Court'},
    { accessorKey: 'courtNo', name: 'Court No'},
    { accessorKey: 'itemNo', name: 'Item No'},
    { accessorKey: 'startDate', name: 'Start Date'},
    { accessorKey: 'startTime', name: 'Start Time'},
    { accessorKey: 'endDate', name: 'End Date'},
    { accessorKey: 'endTime', name: 'End Time'},
    { accessorKey: 'advocate.fullName', name: 'Advocate'},
    { accessorKey: 'intern.fullName', name: 'Intern'},
    { accessorKey: 'clerk.fullName', name: 'Clerk'},
    { accessorKey: 'counsel', name: 'Counsel'},
    { accessorKey: 'otherAdvocate.fullName', name: 'Other Advocate'},
    { accessorKey: 'status', name: 'Status'},
  ];

  const [filterData, setFilterData] = useState({
    status: { value:'Draft', error:false, errorMessage: '' },
    task: { value:null, error:false, errorMessage: '' },
    file: { value:null, error:false, errorMessage: '' },
    advocate: { value:null, error:false, errorMessage: '' }
  });

  const [filterAdvocInputValue, setFilterAdvocInputValue] = useState('');
  const [filterAdvocs, setFilterAdvocs] = useState([]);

  /* check box handle */
  const handleCheckboxChange = (event) => {
    setEmailFlag(event.target.checked ? 'Y' : 'N');
  };

  /* Requied for filter advocate autocomplete */
  const handleFilterAdvocInputChange = (event, value) => {
    setFilterAdvocInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      advocate: {
        ...prevData.advocate,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'FADV');
    } else {
      setFilterAdvocs([]);
    }
  };

  /* Requied for filter advocate autocomplete */
  const handleFilterAdvocAutocompleteChange = (event, selectedItem) => {
    const selectedData = filterAdvocs.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          advocate: {
            ...prevData.advocate,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected filter advocate data not found');
    }
  };

  /* page load */
  useEffect(() => {
    const token = localStorage.getItem('token');
    const dcrptData = jwtDecode(token);

    setUserDtls(dcrptData);
    //fetchAllTaskMst();

    //fetchAssignedTask();

    /*const currentDate = dayjs();
    const endDate = currentDate.add(1, 'day');
    const endTime = endDate.set('hour', 23).set('minute', 59);

    setTaskData(prevState => ({
      ...prevState,
      startDate: { value: currentDate.format('DD/MM/YYYY') },
      startTime: { value: currentDate.format('HH:mm') },
      endDate: { value: endDate.format('DD/MM/YYYY') },
      endTime: { value: endTime.format('HH:mm') },
    }));*/
  }, []);

  /*useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const dcrptData = jwtDecode(token);
      setUserDtls(dcrptData);
    }
  }, []);*/

  useEffect(() => {
    if (userDtls.user_ID) {
      fetchAllTaskMst();
      fetchAssignedTask();
    }

    const currentDate = dayjs();
    const endDate = currentDate.add(1, 'day');
    const endTime = endDate.set('hour', 23).set('minute', 59);

    setTaskData(prevState => ({
      ...prevState,
      startDate: { value: currentDate },
      startTime: { value: currentDate },
      endDate: { value: endDate },
      endTime: { value: endTime },
    }));
  }, [userDtls]);

  /* serach result of task managment */
  const handleFilterSearch = () => {
    fetchAssignedTask();
  }

  /* input handle change */
  const handleChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setTaskData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error, errorMessage}
    }));
  };

  /* filter input change */
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setFilterData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error, errorMessage}
    }))
  }

  /** date change handler */
  const handleDateChange = (name, value) => {
    let error = false;
    let errorMessage = '';
  
    if (name === 'startDate' || name === 'endDate') {
      if (!value) {
        error = true;
        errorMessage = 'You must enter a valid date';
      } else if (isFutureDate(value)) {
        error = true;
        errorMessage = 'Date cannot be a future date';
      }
    }
  
    setTaskData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: {
          value,
          error,
          errorMessage,
        },
      };
  
      if (name === 'startDate' && !error) {
        const currentTime = dayjs();
        const nextDay = dayjs(value).add(1, 'day');
        updatedData['startTime'] = {
          value: currentTime,
          error: false,
          errorMessage: '',
        };
        updatedData['endDate'] = {
          value: nextDay,
          error: false,
          errorMessage: '',
        };
        updatedData['endTime'] = {
          value: nextDay.set('hour', 23).set('minute', 59),
          error: false,
          errorMessage: '',
        };
      }
  
      return updatedData;
    });
  };

  /* validating future date */
  const isFutureDate = (date) => {
    return new Date(date) > new Date();
  };

  /* fetch all task mst*/
  const fetchAllTaskMst = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/task/getalltask`);
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  /* fetch all task mst*/
  const fetchTaskMstByPrefix = async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/task/gettasksbyprifix/${value}`);
      setTaskmsts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleTaskMstInputChange = (event, value) => {
    setTaskMstInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      task: {
        ...prevData.task,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 2) {
      fetchTaskMstByPrefix(value);
    } else {
      setTaskmsts([]);
    }
  };

  /* Requied for Master Task autocomplete */
  const handleTaskMstAutocompleteChange = (event, selectedItem) => {
    const selectedData = taskmsts.find(item => item.taskName === selectedItem);
    
    if (selectedData) {
        setFilterData(prevData => ({
          ...prevData,
          task: {
            ...prevData.task,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected task data not found');
    }
  };

  const fetchAssignedTask = async () => {
    setProcessing(true);
    let roleName = userDtls.roleName;
    let loginId = userDtls.user_ID;
    try {
      let status = filterData.status.value || '';
      let task = filterData.task.value || null;
      let file = filterData.file.value || null;
      let advocate = filterData.advocate.value || null;

      const response = await axios.get(`${BASE_API_URL}/api/taskmanagment/getalltask/${status}/${task}/${file}/${advocate}/${roleName}/${loginId}`);

      setTaskTbl(response.data);
    } catch (error) {
      setProcessing(false)
      console.error('Error fetching data:', error);
    }finally{
      setProcessing(false)
    }
  }

  const handleSelection = (event, newValue) => {
    setSelectedTask(newValue);
  };

  /* fetch court call when type court name */
  const fetchCourt= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/court/getcourtsbyprifix/${value}`);
      setCourts(response.data);
    } catch (error) {
      console.error('Error fetching  court data:', error);
    }
  };

  /* fetch court call when type court name */
  const fetchFiles= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/file/getfileskbyprifix/${value}`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching  file data:', error);
    }
  };

  /* fetch employee call when type product */
  const fetchEmp = async (value, roleType) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/emp/getempbyroleandprefix/${value}/${roleType}`);

      if(roleType === 'ADV'){
        setAdvocates(response.data);
      }else if(roleType === 'INT'){
        setInterns(response.data);
      }else if(roleType === 'CRK'){
        setClerks(response.data);
      }else if(roleType === 'OTHADV'){
        setOtherAdvocates(response.data);
      }else if(roleType === 'FADV'){
        setFilterAdvocs(response.data);
      }
    } catch (error) {
      console.error('Error fetching  Emp data:', error);
    }
  };

  /* Requied for advocate autocomplete */
  const handleAdvocateInputChange = (event, value) => {
    setAdvocateInputValue(value);
    setTaskData(prevData => ({
      ...prevData,
      advocate: {
        ...prevData.advocate,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'ADV');
    } else {
      setAdvocates([]);
    }
  };

  /* Requied for advocate autocomplete */
  const handleAdvocateAutocompleteChange = (event, selectedItem) => {
    const selectedData = advocates.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
        setTaskData(prevData => ({
          ...prevData,
          advocate: {
            ...prevData.advocate,
            value: selectedData._id,
            error: false
          }
        }));
    } else {
      console.error('Selected advocate data not found');
    }
  };

  /* Requied for intern autocomplete */
  const handleInternInputChange = (event, value) => {
    setInternInputValue(value);
    setTaskData(prevData => ({
      ...prevData,
      intern: {
        ...prevData.intern,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'INT');
    } else {
      setInterns([]);
    }
  };

  /* Requied for intern autocomplete */
  const handlInternAutocompleteChange = (event, selectedItem) => {
    const selectedData = interns.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
      setTaskData(prevData => ({
        ...prevData,
        intern: {
          ...prevData.intern,
          value: selectedData._id,
          error: false
        }
      }));
    } else {
      console.error('Selected intern data not found');
    }
  };

  /* Requied for clerk autocomplete */
  const handleClerkInputChange = (event, value) => {
    setClerkInputValue(value);
    setTaskData(prevData => ({
      ...prevData,
      clerk: {
        ...prevData.clerk,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'CRK');
    } else {
      setClerks([]);
    }
  };

  /* Requied for clerk autocomplete */
  const handlClerkAutocompleteChange = (event, selectedItem) => {
    const selectedData = clerks.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
      setTaskData(prevData => ({
        ...prevData,
        clerk: {
          ...prevData.clerk,
          value: selectedData._id,
          error: false
        }
      }));
    } else {
      console.error('Selected clerk data not found');
    }
  };

  /* Requied for other advocate autocomplete */
  const handleOtherAdvocateInputChange = (event, value) => {
    setOtherAdvocateInputValue(value);
    setTaskData(prevData => ({
      ...prevData,
      otherAdvocate: {
        ...prevData.otherAdvocate,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 0) {
      fetchEmp(value, 'OTHADV');
    } else {
      setOtherAdvocates([]);
    }
  };

  /* Requied for other advocate autocomplete */
  const handlOtherAdvocateAutocompleteChange = (event, selectedItem) => {
    const selectedData = otherAdvocates.find(item => item.fullName === selectedItem);
    
    if (selectedData) {
      setTaskData(prevData => ({
        ...prevData,
        otherAdvocate: {
          ...prevData.otherAdvocate,
          value: selectedData._id,
          error: false
        }
      }));
    } else {
      console.error('Selected other advocate data not found');
    }
  };


  /* Requied for court autocomplete */
  const handleCourtInputChange = (event, value) => {
    setCourtInputValue(value);
    setTaskData(prevData => ({
      ...prevData,
      court: {
        ...prevData.court,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a court' : ''
      }
    }));
    if (value.length >= 2) {
      fetchCourt(value);
    } else {
      setCourts([]);
    }
  };

  /* Requied for court autocomplete */
  const handleCourtAutocompleteChange = (event, selectedItem) => {
    const selectedData = courts.find(item => item.courtShortName === selectedItem);
    
    if (selectedData) {
      setTaskData(prevData => ({
        ...prevData,
        court: {
          ...prevData.court,
          value: selectedData._id,
          error: false
        },
      }));
    } else {
      console.error('Selected Court data not found in courts array');
    }
  };


  /* Requied for file autocomplete */
  const handleFileInputChange = (event, value) => {
    setFileInputValue(value);
    setTaskData(prevData => ({
      ...prevData,
      file: {
        ...prevData.file,
        value: value,
        error: !value,
        errorMessage: !value ? '' : ''
      }
    }));
    if (value.length >= 1) {
      fetchFiles(value);
    } else {
      setFiles([]);
    }
  };

  /* Requied for file autocomplete */
  const handleFileAutocompleteChange = (event, selectedItem) => {
    const selectedData = files.find(item => item.fileNo === selectedItem);
    
    if (selectedData) {
        setTaskData(prevData => ({
          ...prevData,
          file: {
            ...prevData.file,
            value: selectedData._id,
            error: false
          },
          fileNo: {
            ...prevData.fileNo,
            value: selectedData.fileNo
          },
          fileName: {
            ...prevData.fileName,
            value: selectedData.fileName
          },
          court: {
            ...prevData.court,
            value: selectedData.court
          }
        }));

        setCourtInputValue(selectedData.court.courtShortName)
    } else {
      console.error('Selected file data not found');
    }
  };

  /* on click submit button */
  const handleSubmit = async(e) => {
    e.preventDefault();
    let responseSeverity = '';
    let responseMessage = '';
    setProcessing(true);
    let status = '';

    const buttonName = e.target.getAttribute('data-button-name');

    if(buttonName === 'assign'){
      status = 'Inprogress';
    }else if(buttonName === 'update'){
      status = taskData.status.value;
    }else{
      status = taskData.status.value;
    }

    const loginId = userDtls.user_ID;

    let finalDataToSend = {
      _id: taskData._id.value,
      taskMst: taskData.taskMst.value,
      advocate: taskData.advocate.value,
      intern: taskData.intern ? taskData.intern.value : null,
      clerk: taskData.clerk ? taskData.clerk.value : null,
      counsel: taskData.counsel.value || '',
      otherAdvocate: taskData.otherAdvocate ? taskData.otherAdvocate.value : null,
      file: taskData.file.value,
      fileNo: taskData.fileNo.value,
      fileName: taskData.fileName.value,
      isBillable: taskData.isBillable.value,
      court: taskData.court.value,
      courtNo: taskData.courtNo.value,
      itemNo: taskData.itemNo.value,
      startDate: taskData.startDate.value,
      startTime: taskData.startTime.value,
      endDate: taskData.endDate.value,
      endTime: taskData.endTime.value,
      status: status,
      isActive: taskData.isActive.value,
      createdBy: loginId,
      lastModifiedBy: loginId,
      workDoneTimeFrame: null,
      workStartDate: null,
      workStartTime: null,
      workEndDate: null,
      workEndTime: null,
      prevHours: null,
      workedHours: null,
      billedHours: null,
      totalHours: null,
      ldh: null,
      ndh: null,
      workStatus: status,
      remarks: null,
      workDetails: null,
      workedBy: null,
      woredDate: null,
      lastWorkModifiedBy: null,
      lastWorkModifiedDate: null
    }
    
    try {
      const response = await axios.post(`${BASE_API_URL}/api/taskmanagment/create`, {finalDataToSend, selectedTask, emailFlag});
      let data = response.data;
      let msg = data.message;

      if(data.status === 'success'){
        responseSeverity = 'success';
        responseMessage = `${msg}` ;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);

        handleReload();
      }else if(data.status === 'notExist'){
        responseSeverity = 'error';
        responseMessage = `${msg}`;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);
        return;
      }else{
        responseSeverity = 'error';
        responseMessage = 'Error while creating file' ;

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setProcessing(false);
        setOpen(true);
        return;
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while creating file, please contact admin' ;

      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setProcessing(false);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }

  /* on click edit icon */
  const handleEdit = (row) => {
    try {
      setTaskData((prevData) => {
        let startDate = null;
        let startTime = null;
        let endDate = null;
        let endTime = null;
        if (row.original.startDate) {
          startDate = dayjs(row.original.startDate).isValid() ? dayjs(row.original.startDate) : null;
          
        }
        if (row.original.startTime) {
          let tempStartTime = dayjs.utc(row.original.startTime).tz('Asia/Kolkata');
          startTime = tempStartTime.subtract(5, 'hour').subtract(30, 'minute');
        }
        if (row.original.endDate) {
          endDate = dayjs(row.original.endDate).isValid() ? dayjs(row.original.endDate) : null;
        }
        if (row.original.endTime) {
          let tempEndTime = dayjs.utc(row.original.endTime).tz('Asia/Kolkata');
          endTime = tempEndTime.subtract(5, 'hour').subtract(30, 'minute');
        }

        const updatedData = {
          ...prevData,
          _id: {
            value: row.original._id,
            error: false,
            errorMessage: ''
          },
          advocate: {
            value: row.original.advocate._id,
            error: false,
            errorMessage: 'You must select advocate'
          },
          intern: {
            value: row.original.intern ? row.original.intern._id : null,
            error: false,
            errorMessage: ''
          },
          clerk: {
            value: row.original.clerk ? row.original.clerk._id : null,
            error: false,
            errorMessage: ''
          },
          counsel: {
            value: row.original.counsel ? row.original.counsel : '',
            error: false,
            errorMessage: ''
          },
          otherAdvocate: {
            value: row.original.otherAdvocate ? row.original.otherAdvocate._id : null,
            error: false,
            errorMessage: ''
          },
          fileNo: {
            value: row.original.fileNo,
            error: false,
            errorMessage: ''
          },
          fileName: {
            value: row.original.fileName,
            error: false,
            errorMessage: ''
          },
          isBillable: {
            value: row.original.isBillable,
            error: false,
            errorMessage: ''
          },
          court: {
            value: row.original.court._id,
            error: false,
            errorMessage: ''
          },
          courtNo: {
            value: row.original.courtNo,
            error: false,
            errorMessage: ''
          },
          itemNo: {
            value: row.original.itemNo,
            error: false,
            errorMessage: ''
          },
          startDate: {
            value: startDate,
            error: false,
            errorMessage: ''
          },
          startTime: {
            value: startTime,
            error: false,
            errorMessage: ''
          },
          endDate: {
            value: endDate,
            error: false,
            errorMessage: ''
          },
          endTime: {
            value: endTime,
            error: false,
            errorMessage: ''
          },
          file: {
            value: row.original.file._id,
            error: false,
            errorMessage: ''
          },
          taskMst: {
            value: row.original.taskMst._id,
            error: false,
            errorMessage: ''
          },
          status: {
            value: row.original.status,
            error: false,
            errorMessage: ''
          },
          isActive: {
            value: row.original.isActive,
            error: false,
            errorMessage: ''
          }
        };

        return updatedData;
      });

      setAdvocateInputValue(row.original.advocate ? row.original.advocate.fullName : '');
      setInternInputValue(row.original.intern ? row.original.intern.fullName : '');
      setClerkInputValue(row.original.clerk ? row.original.clerk.fullName : '');
      setOtherAdvocateInputValue(row.original.otherAdvocate ? row.original.otherAdvocate.fullName : '');
      setCourtInputValue(row.original.court ? row.original.court.courtFullName : '');
      setFileInputValue(row.original.file ? row.original.file.fileNo : '');
      setSelectedTask([row.original.taskMst]);
      setIsSubmitBtn(false);


    } catch (error) {
      
    }
  }
  

  /* on click delete icon */
  const deleteAction = (row) => {

  }

  /* on click delete icon */
  const toggleIsActive = async(row) => {
    let responseSeverity = '';
    let responseMessage = '';
    let msg = '';
    setProcessing(true);
    let _id = row.original._id;
    const currentIsActive = row.original.isActive;

    try {
      if(currentIsActive === 'Y'){
        row.original.isActive = 'N';
        msg = 'Selected assign task is In-Active Now';
      }else {
        row.original.isActive = 'Y';
        msg = 'Selected assign task is Active Now';
      }

      const response = await axios.put(`${BASE_API_URL}/api/taskmanagment/updateflag/${_id}`, {isActive : row.original.isActive});

      switch (response.status) {
        case 200:
          responseSeverity = 'success';
          responseMessage = `${msg}`;

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
  
          setOpen(true);
          handleReload();
          break;
        case 400:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Invalid data!';

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        case 404:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Task not found!';

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        case 500:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Internal server error!';
          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        default:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Unknown error!';

          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while calling toggleIsActive';
      setProcessing(false);
      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }

  /* on click update button */
  const handleUpdate = async(e) => {

  }

  const addHandleReload = () => {
    setTaskData({
      _id: { value: ''},
      taskMst: {value:null, error:false, errorMessage: '' },
      file: { value: null, error:false, errorMessage: ''},
      fileNo: { value:'', error:false, errorMessage: '' },
      fileName: { value:'', error:false, errorMessage: '' },
      isBillable: {value:'Billable', error:false, errorMessage: ''},
      court:{ value: null, error:false, errorMessage: '' },
      courtNo:{ value: '', error:false, errorMessage: '' },
      itemNo:{ value:'', error:false, errorMessage: '' },
      startDate: { value:null, error:false, errorMessage: '' },
      startTime: { value:null, error:false, errorMessage: '' },
      endDate: { value:null, error:false, errorMessage: '' },
      endTime: { value:null, error:false, errorMessage: '' },
      status: { value:'', error:false, errorMessage: '' },
      isActive: {value:'Y', error:false, errorMessage: ''},
      createdBy:{ value: null, error:false },
      createdDate:{ value: null, error:false },
      lastModifiedBy:{ value: null, error:false },
      lastModifiedDate:{ value:null, error:false },
    });
  }

  /* clear the page  */
  const handleReload = () => {
    fetchAssignedTask();
    setTaskData({
      _id: { value: ''},
      taskMst: {value:null, error:false, errorMessage: '' },
      advocate: { value: null, error:false, errorMessage: ''},
      clerk: { value: null, error:false, errorMessage: ''},
      counsel: { value:'', error:false, errorMessage: '' },
      otherAdvocate:{ value: null, error:false, errorMessage: '' },
      file: { value: null, error:false, errorMessage: ''},
      fileNo: { value:'', error:false, errorMessage: '' },
      fileName: { value:'', error:false, errorMessage: '' },
      isBillable: {value:'Billable', error:false, errorMessage: ''},
      court:{ value: null, error:false, errorMessage: '' },
      courtNo:{ value: '', error:false, errorMessage: '' },
      itemNo:{ value:'', error:false, errorMessage: '' },
      startDate: { value:null, error:false, errorMessage: '' },
      startTime: { value:null, error:false, errorMessage: '' },
      endDate: { value:null, error:false, errorMessage: '' },
      endTime: { value:null, error:false, errorMessage: '' },
      status: { value:'Draft', error:false, errorMessage: '' },
      isActive: {value:'Y', error:false, errorMessage: ''},
      createdBy:{ value: null, error:false },
      createdDate:{ value: null, error:false },
      lastModifiedBy:{ value: null, error:false },
      lastModifiedDate:{ value:null, error:false },

      /* work details here */
      workDoneTimeFrame: { value: null, error:false, errorMessage: ''},
      workStartDate: { value:null, error:false, errorMessage: '' },
      workStartTime:{ value: null, error:false, errorMessage: '' },
      workEndDate: { value: null, error:false, errorMessage: '' },
      workEndTime:{ value: null, error:false, errorMessage: '' },
      prevHours:{ value: null, error:false, errorMessage: '' },
      workedHours: { value:'', error:false, errorMessage: '' },
      billedHours: {value:'', error:false, errorMessage: ''},
      totalHours:{ value: '', error:false, errorMessage: '' },
      ldh: { value: null, error:false, errorMessage: '' },
      ndh: { value: null, error:false, errorMessage: '' },
      workStatus:{ value: 'Draft', error:false, errorMessage: '' },
      remarks: { value:null, error:false, errorMessage: '' },
      workDetails: { value:null, error:false, errorMessage: '' },
      workedBy:{ value: null, error:false },
      workedDate:{ value: null, error:false },
      lastWorkModifiedBy:{ value: null, error:false },
      lastWorkModifiedDate:{ value:null, error:false },
    });

    setFilterData({
      status: { value:'Draft', error:false, errorMessage: '' },
      task: { value:null, error:false, errorMessage: '' },
      file: { value:null, error:false, errorMessage: '' },
      advocate: { value:null, error:false, errorMessage: '' }
    })

    setCourtInputValue('');
    setCourts([]);
    setAdvocateInputValue('');
    setAdvocates([]);
    setInternInputValue('');
    setInterns([]);
    setClerkInputValue('')
    setClerks([]);
    setOtherAdvocateInputValue('')
    setOtherAdvocates([]);
    setFileInputValue('');
    setFiles([]);
    setSelectedTask([]);
    fetchAllTaskMst();

    setEmailFlag('N');
    const currentDate = dayjs();
    const endDate = currentDate.add(1, 'day');
    const endTime = endDate.set('hour', 23).set('minute', 59);

    setTaskData(prevState => ({
      ...prevState,
      startDate: { value: currentDate },
      startTime: { value: currentDate },
      endDate: { value: endDate },
      endTime: { value: endTime },
    }));
    setIsSubmitBtn(true);
  }

  /* copy the task */
  const handleCopyTask = (row) => {
    let _id = row.original._id;
    setCopyData(_id);
    setDialogMsg(
      <>
        Do you want to copy task <b>{row.original.taskMst.taskName}</b> for file no <b>{row.original.fileNo}</b> ?
      </>
    );
    setDialogConfirmOpen(true);
  }

  const handleApproverAction = async (val) => {
    setProcessing(true);
    let responseSeverity = '';
    let responseMessage = ``;
    let _id = copyData;
    let loginId = userDtls.user_ID;
    let currentDate = dayjs();
    let nextDay = currentDate.add(1, 'day');
    let endOfDay = nextDay.set('hour', 23).set('minute', 59);

    try {
      setDialogConfirmOpen(false);

      if(val === 'COPY'){
        const response = await axios.post(`${BASE_API_URL}/api/taskmanagment/copytask`, { _id, loginId, currentDate, nextDay, endOfDay});

        let data = response.data;
        let msg = data.message;

        if(data.status === 'success'){
          responseSeverity = 'success';
          responseMessage = `${msg}`;
  
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setProcessing(false);
          setOpen(true);
  
          handleReload();
        }else{
          responseSeverity = 'error';
          responseMessage = 'Error while creating file' ;
  
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setProcessing(false);
          setOpen(true);
          return;
        }
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while task copy, please contact admin' ;

      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setProcessing(false);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  }

  const handleCloseDialogBox = () => {
    setDialogConfirmOpen(false)
  }

  /* on click close msg */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  
  /* column to be export */
  const exportedTaskColumns = {
    taskMst: 'Task Name',
    fileNo: 'File No',
    fileName: 'File Name',
    advocate: 'Advocate',
    intern: 'Intern',
    clerk: 'Clerk',
    counsel: 'Counsel',
    otherAdvocate: 'Other Advocate',
    court: 'Court',
    courtNo: 'Court No',
    itemNo: 'Item No',
    isBillable: 'isBillable',
    startDate: 'Start Date',
    startTime: 'Start Time',
    endDate: 'End Date',
    endTime: 'End Time',
    status: 'Status'
  };

  /* export all data */
  const handleExportAllData = () => {
    setProcessing(true);
    try {
      let data = taskTbl;
      exportToExcel(data, 'Task.xlsx', exportedTaskColumns, 'TASK');

      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      console.error(error);
    }finally{
      setProcessing(false);
    }
  };

  /* export paage data only */
  const handleExportPageData = (rows) => {
    setProcessing(true);
    try {
      const data = rows.map((row) => row.original);

      exportToExcel(data, 'Task.xlsx', exportedTaskColumns, 'TASK');
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      console.error(error);
    }finally{
      setProcessing(false);
    }
  }

  const shouldShowEditIcon = (row) => {
    return row.original.status === 'Draft';
  };

  return (
    <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
          <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'white'}}>
            <CardContent>
            <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
              <form onSubmit={handleSubmit} autoComplete='off'>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                    <StepriseAutocompleteBox
                      size='small'
                      required
                      freeSolo
                      name="file"
                      inputValue={fileInputValue}
                      onInputChange={handleFileInputChange}
                      onChange={handleFileAutocompleteChange}
                      options={files.map((item) => item.fileNo)}
                      label="File No"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <StepriseTextField
                      disabled
                      label="File Name"
                      name="fileName"
                      size='small'
                      value={taskData.fileName.value}
                      onChange={handleChange}
                      required
                      error={taskData.fileName.error}
                      helperText={taskData.fileName.error && taskData.fileName.errorMessage}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <StepriseAutocompleteBox
                      size='small'
                      required
                      freeSolo
                      name="court"
                      inputValue={courtInputValue}
                      onInputChange={handleCourtInputChange}
                      onChange={handleCourtAutocompleteChange}
                      options={courts.map((item) => item.courtShortName)}
                      label="Court"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={1}>
                    <StepriseTextField
                      label="Court No"
                      name="courtNo"
                      size='small'
                      value={taskData.courtNo.value}
                      onChange={handleChange}
                      required
                      error={taskData.courtNo.error}
                      helperText={taskData.courtNo.error && taskData.courtNo.errorMessage}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={1}>
                    <StepriseTextField
                      label="Item No"
                      name="itemNo"
                      size='small'
                      value={taskData.itemNo.value}
                      onChange={handleChange}
                      required
                      error={taskData.itemNo.error}
                      helperText={taskData.itemNo.error && taskData.itemNo.errorMessage}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={1}>
                    <FormControl fullWidth 
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}>
                      <InputLabel>isBillable</InputLabel>
                      <Select size='small' label="isBillable" name='isBillable'
                        value={taskData.isBillable.value}
                        onChange={handleChange}
                        
                      >
                        <MenuItem value='Billable'>Yes</MenuItem>
                        <MenuItem value='Non Billable'>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker sx={{
                          '& .MuiOutlinedInput-input': {
                            height: '0.5em !important', 
                            padding: '16.5px 10px !important'
                          }, 
                          '& .MuiInputLabel-root': {
                            lineHeight: '1.0 !important'
                          },
                          '& .MuiIconButton-root': {
                            color:'#353839 !important'
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&:hover fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },

                          '& .MuiInputLabel-root': {
                            color: '#353839', 
                            '&.Mui-focused': {
                              color: '#353839',
                            },
                            '&.Mui-error': {
                              color: 'red', 
                            },
                          },
                        }}
                        label="Start Date"
                        name="startDate"
                        fullWidth
                        required
                        value={taskData.startDate.value}
                        onChange={(newValue) => handleDateChange('startDate', newValue)}
                        error={taskData.startDate.error}
                        helperText={taskData.startDate.error && taskData.startDate.errorMessage}
                        //shouldDisableDate={(date) => isFutureDate(date)}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Start Time"
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        sx={{
                          '& .MuiOutlinedInput-input':{
                            height: '0.5375em !important'
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#353839',
                            },
                            '&:hover fieldset': {
                              borderColor: '#353839',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#353839',
                            },
                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },
                        }}
                        value={taskData.startTime.value}
                        onChange={(newValue) => handleDateChange('startTime', newValue)}
                        error={taskData.startTime.error}
                        helperText={taskData.startTime.error && taskData.startTime.errorMessage}
                      />
                    </LocalizationProvider>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <StepriseAutocompleteBox
                      size='small'
                      required
                      freeSolo
                      name="advocate"
                      inputValue={advocateInputValue}
                      onInputChange={handleAdvocateInputChange}
                      onChange={handleAdvocateAutocompleteChange}
                      options={advocates.map((item) => item.fullName)}
                      label="Advocate"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <StepriseAutocompleteBox
                      size='small'
                      freeSolo
                      name="intern"
                      inputValue={internInputValue}
                      onInputChange={handleInternInputChange}
                      onChange={handlInternAutocompleteChange}
                      options={interns.map((item) => item.fullName)}
                      label="Intern"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <StepriseAutocompleteBox
                      size='small'
                      freeSolo
                      name="clerk"
                      inputValue={clerkInputValue}
                      onInputChange={handleClerkInputChange}
                      onChange={handlClerkAutocompleteChange}
                      options={clerks.map((item) => item.fullName)}
                      label="Clerk"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2} lg={1}>
                    <FormControlLabel control={<Checkbox size="medium"  onChange={handleCheckboxChange}/>} label="Email" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <StepriseTextField
                      label="Counsel"
                      name="counsel"
                      size='small'
                      value={taskData.counsel.value}
                      onChange={handleChange}
                      error={taskData.counsel.error}
                      helperText={taskData.counsel.error && taskData.counsel.errorMessage}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <StepriseAutocompleteBox
                      size='small'
                      freeSolo
                      name="otherAdvocate"
                      inputValue={otherAdvocateInputValue}
                      onInputChange={handleOtherAdvocateInputChange}
                      onChange={handlOtherAdvocateAutocompleteChange}
                      options={otherAdvocates.map((item) => item.fullName)}
                      label="Other Advocate"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={12}>
                    <Autocomplete
                      multiple
                      size='small'
                      id="multi-selection-autocomplete"
                      required
                      options={tasks}
                      value={selectedTask}
                      onChange={handleSelection}
                      getOptionLabel={(option) => option.taskName}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Task"
                          />
                      )}

                      sx={{
                        '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{
                          height: '0.5375em'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#353839',
                          },
                          '&:hover fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#353839',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>

                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    {isSubmitBtn ? (
                      <Button type="submit" data-button-name="add" fullWidth variant="contained" startIcon={<DraftsIcon />}
                        sx={{
                          backgroundColor: 'black',
                          '&:hover': {
                            backgroundColor: 'black',
                          },
                        }}
                      >
                        Draft
                      </Button>
                      ) : (
                      <Button onClick={handleSubmit} data-button-name="update" variant="contained" fullWidth startIcon={<EditNoteIcon />}
                        sx={{
                          backgroundColor: 'black',
                          '&:hover': {
                            backgroundColor: 'black',
                          },
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Button variant="contained" onClick={handleSubmit} data-button-name="assign" fullWidth  startIcon={<AssignmentTurnedInIcon />}
                      sx={{
                        backgroundColor: 'black', 
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Assign
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Button variant="contained" onClick={handleReload} fullWidth type="button" startIcon={<RefreshIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Reload
                    </Button>
                  </Grid>
                </Grid>

                <Dialog disableBackdropClick={true} open={dialogConfirmOpen} onClose={handleCloseDialogBox} >
                  <DialogTitle 
                    sx={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%'
                    }}
                  >
                    Confirmation
                  </DialogTitle>
                  <DialogContent>
                      <DialogContentText>
                        {dialogMsg}
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                      <Button onClick={handleCloseDialogBox} color='error'>Cancel</Button>
                      <Button onClick={() => handleApproverAction('COPY')} color='success'>OK</Button>
                  </DialogActions>
                </Dialog>
              </form>
              <ProcessingDialog open={processing}/>
            </CardContent>
          </Card>

          <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <FormControl fullWidth 
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}>
                    <InputLabel>Task Status</InputLabel>
                      <Select size='small' label="Task Status" name='status'
                        value={filterData.status.value}
                        onChange={handleFilterChange}
                        
                      >
                        <MenuItem value='Draft'>Draft</MenuItem>
                        <MenuItem value='Inprogress'>Inprogress</MenuItem>
                        <MenuItem value='Extended'>Extended</MenuItem>
                        <MenuItem value='Elapsed'>Elapsed</MenuItem>
                        <MenuItem value='Incomplete'>Incomplete</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="task"
                    inputValue={taskMstInputValue}
                    onInputChange={handleTaskMstInputChange}
                    onChange={handleTaskMstAutocompleteChange}
                    options={taskmsts.map((item) => item.taskName)}
                    label="Task Name"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="file"
                    inputValue={fileInputValue}
                    onInputChange={handleFileInputChange}
                    onChange={handleFileAutocompleteChange}
                    options={files.map((item) => item.fileNo)}
                    label="File No"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={5}>
                  <StepriseAutocompleteBox
                    size='small'
                    required
                    freeSolo
                    name="advocate"
                    inputValue={filterAdvocInputValue}
                    onInputChange={handleFilterAdvocInputChange}
                    onChange={handleFilterAdvocAutocompleteChange}
                    options={filterAdvocs.map((item) => item.fullName)}
                    label="Assign Advocate"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={1}>
                  <Button variant="contained" size='large' onClick={handleFilterSearch} fullWidth type="button"
                    startIcon={<SearchIcon />}
                    sx={{
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Grid container spacing={3} sx={{mb:2}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StepriseExptTbl sx={{borderTop: '5px solid #9c27b0'}}
                exportAllData={handleExportAllData}
                handleExportSelectedRows={handleExportPageData}
                handleExportRows={handleExportPageData}
                data={taskTbl}
                headerNames={taskTblHeader}
                editAction={handleEdit}
                copyAction={handleCopyTask}
                deleteAction={deleteAction}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={EditNoteIcon}
                copyIcon={ContentCopyIcon}
                deleteIcon={EditIcon}
                isActive={toggleIsActive}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={true}
                showEditIcon={true}
                showToggleIcon={true}
                showDeleteIcon={false} 
                shouldShowEditIcon={shouldShowEditIcon}
              />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
